import { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';

const useCurrentUser = () => {
  const [user, setUser] = useState(null);
  const localStorageToken = secureLocalStorage.getItem('userDetails');

  const parsedUserData = function () {
    let parsedJson = null;

    if (localStorageToken) {
      try {
        parsedJson = JSON.parse(localStorageToken);
      } catch {}
    }

    return parsedJson;
  };

  useEffect(() => {
    setUser(parsedUserData());
  }, []);

  return user;
};

export default useCurrentUser;
