// Frontend
import '../stylesheets/components/ProgressBar.css';

const ProgressBar = ({ loading }) => {
  return (
    <>
      {loading && (
        <div className="util--progress-bar">
          <div className="util--progress-bar__inner"></div>
        </div>
      )}
    </>
  );
};

export default ProgressBar;
