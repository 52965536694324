// Backend
import React from 'react';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality
import { useLocation, Link } from 'react-router-dom';

// Components
import { Header, FlashMessage } from '../../constants/SharedComponents';
import useCurrentUser from '../../hooks/useCurrentUser';

export function ResourcesManagerStressSource() {
  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();

  // Objects
  const [flashMessageObject, setFlashMessageObject] = React.useState({});

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  return (
    <>
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />

      <div className="grid-container">
        <Grid className="grid-padding-x align-justify">
          <Cell small={12}>
            {
              // subnav here
            }
            <div className="page-content">
              <div className="page-content__header">
                <Link to="/resources/manager-training" className="page-content__button">
                  Back
                </Link>
              </div>
              <h1 className="page-content__title">
                Common work characteristics that can cause work-related stress.
              </h1>
              <div className="page-content__styles">
                <p>
                  Below is a list of the main <strong>work characteristics</strong> that are related
                  to employee health and wellbeing in the workplace. These areas are the most common
                  work characteristics that can cause work-related stress. Click on each work
                  characteristic to read more about it.
                </p>
                <p>
                  The way work characteristics may affect your team members can be very different
                  and unique.
                </p>
                <p>
                  Because each situation is unique, there is{' '}
                  <strong>no one size fits all solution:</strong> what may work for one team member
                  may not work for another one.{' '}
                  <strong>
                    Solutions have to be tailored to the specific needs of your team member and to
                    the specificities of their situation.
                  </strong>
                </p>
                <ul>
                  <li>
                    <a href="/resources/4af58c57-cbe4-499e-99ec-fb33c45c1b98">Change</a>
                  </li>
                  <li>
                    <a href="/resources/7185abba-d609-4071-b6b9-6e199c475e38">Relationships</a>
                  </li>
                  <li>
                    <a href="/resources/8d0b0089-2cd2-4c4f-97ff-588710cba91d">Demands</a>
                  </li>
                  <li>
                    <a href="/resources/9608c2cc-6822-4a22-9ebf-4e5d7cab50f3">Role</a>
                  </li>
                  <li>
                    <a href="/resources/bae25492-5633-48ec-b04f-69f2bc9bc43b">Peer Support</a>
                  </li>
                  <li>
                    <a href="/resources/bb844672-2318-4a9f-b9ac-354f535c7dea">Management Support</a>
                  </li>
                  <li>
                    <a href="/resources/ee048b5c-97b2-4f1e-bf90-0da6169729f8">Control</a>
                  </li>
                </ul>
                <p>
                  Your team member may also propose topics that are classified as 'other'. However,
                  it is recommended to classify what is discussed in this 'other' topic within the
                  framework above when appropriate. Here are some examples:{' '}
                </p>
                <ul>
                  <li>
                    Your team member reports a <strong>lack of participation</strong> in decisions
                    -&gt; this may be a Control issue: your team member may not satisfied with the
                    level of influence they have on decisions that can be linked to them.
                  </li>
                  <li>
                    Your team member perceives a <strong>poor performance appraisal</strong> or a
                    <strong> lack of useful feedback</strong> -&gt; this may be a Role issue: your
                    team member may lack clear guidance for helping them to know whether they are
                    doing good.
                  </li>
                  <li>
                    Your team member complains about the <strong>lack of career development</strong>{' '}
                    opportunities -&gt; the feeling of being unable to progress in one's career has
                    been reported as a source of stress. Moreover, it may impede worker motivation
                    as people do not feel that they can achieve their ambitions. This is an HR
                    provision but it may fall under Role.
                  </li>
                  <li>
                    Your team member reports having a <strong>poor work-life balance</strong>{' '}
                    because feeling pressured to be constantly connected -&gt; you can either
                    classify it under Demands if your focus is on the demand of being constantly
                    'on' or under Role if your focus is on the conflict between the work and
                    personal spheres.
                  </li>
                  <li>
                    Your team member perceives <strong>not being trusted</strong> when working
                    remotely -&gt; this may be a Control issue: your team member may feel pressure
                    from being constantly monitored when working remotely
                  </li>
                  <li>
                    Your team member reports more <strong>intensive workload</strong> with hybrid
                    working (more Team meetings, more emails) -&gt; this is a Demands issue: your
                    team member seems to have difficulties balancing or managing their workload
                  </li>
                </ul>
              </div>
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  );
}
