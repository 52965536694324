// Backend
import React from 'react';
import axios from 'axios';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality
import { useParams, useLocation, Link } from 'react-router-dom';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  ProgressBar,
  ErrorRender,
  setToken,
  filteredCategories,
} from '../../../constants/SharedComponents';
import useCurrentUser from '../../../hooks/useCurrentUser';
import QuestionCategory from '../../../components/QuestionCategory';

export function ConversationsManagersShow() {
  // Params
  const { conversationId } = useParams();

  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();
  const token = setToken(currentUser);
  const headerParams = { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 };

  // Objects
  const [flashMessageObject, setFlashMessageObject] = React.useState({});
  const [data, setData] = React.useState(null);
  const [questionResultData, setQuestionResultData] = React.useState(null);
  const [canShareWithManager, setCanShareWithManager] = React.useState(false);
  const [inputs, setInputs] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [questionScores, setQuestionScores] = React.useState(null);
  const [selectedCategories, setSelectedCategories] = React.useState([]);

  // Functions
  function retryFetch() {
    setLoading(true);
    setError(false);
    setData(null);
    getData();
  }

  const getData = async () => {
    try {
      let Api = new DluchApi('ConversationsShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.conversation;

      setData(responseData);
      setError(false);

      getQuestionCategoriesData();
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const getQuestionResultsData = async (questionCategories) => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionResultsShow', conversationId);
      let defaultInputs = {};
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);

      let responseData = sortedQuestionResultData(response.data.content.results);

      let chosenCategories = responseData.filter((obj) => obj.isConversationTopic === true);

      chosenCategories = chosenCategories.map((obj) => obj.categoryId);
      chosenCategories = questionCategories.filter((obj) => chosenCategories.includes(obj.id));

      setSelectedCategories(chosenCategories);
      setInputs(defaultInputs);
      setQuestionResultData(responseData);
      setError(false);

      const canShareWithManager = responseData[0].canShareWithManager === true;
      setCanShareWithManager(canShareWithManager);
      getQuestionScores();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const questionResultsContent = (questionCategoryId, field) => {
    if (inputs[field + '--' + questionCategoryId]) {
      return inputs[field + '--' + questionCategoryId];
    }

    if (!questionResultData) {
      return;
    }

    const category = questionResultData.find((obj) => {
      return obj.categoryId === questionCategoryId;
    });

    if (category) {
      return category[field];
    }
  };

  const sortedQuestionResultData = function (categoryData) {
    let responseData = categoryData.sort(function (a, b) {
      let weight = -1;

      if (a.priorityRank > b.priorityRank) {
        weight = 1;
      } else if (a.priorityRank === b.priorityRank) {
        weight = a.categoryName > b.categoryName ? 1 : -1;
      }

      return weight;
    });

    responseData = responseData.reverse();

    responseData = responseData.sort(function (a, b) {
      return a.categoryType - b.categoryType;
    });

    return responseData;
  };

  const getQuestionCategoriesData = async () => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionsCategoriesIndex', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      //let responseData = sortedQuestionResultData(response.data.content.categories);
      let responseData = response.data.content.categories;
      let allCategories = filteredCategories(responseData, 3, false);
      setError(false);
      getQuestionResultsData(allCategories);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const getQuestionScores = async () => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionScoresShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.sortedScores;
      setError(false);
      getTemperatureQuestionCategory(responseData[0]);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const getTemperatureQuestionCategory = async (questionScores) => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionsCategoriesIndex', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.categories;
      let result = filteredCategories(responseData, 3);
      getQuestionOptions(questionScores, result[0]);
      setError(false);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const getQuestionOptions = async (questionScores, temperatureQuestionCategory) => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionsIndex', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content;
      let result = responseData.sortedQuestions.find(
        (obj) => obj.categoryId === temperatureQuestionCategory.id,
      );
      questionScores.optionText = JSON.parse(result.questionOptions).find(
        (obj) => obj.OptionScore === questionScores.score,
      ).OptionText;
      setQuestionScores(questionScores);
      setError(false);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (currentUser && currentUser.token) {
      retryFetch();
    }

    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  return (
    <>
      <ProgressBar loading={loading} />
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />
      <ErrorRender object={error} />

      {data && questionResultData && !canShareWithManager && (
        <>
          <div className="grid-container">
            <Grid className="grid-padding-x align-justify">
              <Cell small={12}>
                <div className="page-content">
                  <h1 className="page-content__title">Manager Prep</h1>

                  <div className="page-content__styles">
                    <p>
                      The Team Member has completed their prep for this conversation but has chosen
                      not to share it with you.
                    </p>

                    <Link to="/resources" className="page-content__button">
                      View Manager Prep Support Information
                    </Link>
                  </div>
                </div>
              </Cell>
            </Grid>
          </div>
        </>
      )}

      {data && questionResultData && canShareWithManager && (
        <>
          <div className="grid-container">
            <Grid className="grid-padding-x align-justify">
              <Cell small={12}>
                <div className="page-content">
                  <h1 className="page-content__title">Manager Prep</h1>

                  <div className="page-content__styles">
                    <p>
                      The Team Member has completed their prep for this conversation and has chosen
                      to share it with you
                    </p>

                    <Link to="/resources" className="page-content__button">
                      View Manager Prep Support Information
                    </Link>

                    <hr />

                    {questionScores && (
                      <>
                        <h3 className="page-content__subtitle">{questionScores.questionText}</h3>

                        <div className="page-content__styles">
                          <p>
                            <b>{questionScores.optionText}</b>
                          </p>

                          {(questionScores.optionText === 'Extremely Stressful' ||
                            questionScores.optionText === 'Very Stressful') && (
                            <>
                              <p>
                                <strong>
                                  **IMPORTANT NOTE:** Your team member reports feeling very or
                                  extremely stressed in their job.
                                </strong>
                              </p>
                              <p>
                                This indicates their current situation requires urgent consideration
                                and we recommend that you expedite their conversation to address
                                their current challenges.
                              </p>
                              <p>
                                We also recommend you go through your organisational policies to
                                guide you and consider if further action is needed. In the meantime,
                                you can also inform your team member about the other organisational
                                provisions offered (e.g., employee assistance program, counselling
                                sessions if available).
                              </p>

                              <p>
                                This response from your team member highlights greater urgency of
                                taking actions to mitigate the levels of stress. During the
                                conversation and the co-creation of actions, you can ask your team
                                member: what would be an easy/quick step to take for helping you
                                feel better?
                              </p>
                            </>
                          )}
                        </div>
                      </>
                    )}
                    <h3 className="page-content__subtitle">Conversation Topics</h3>
                    <Grid className="grid-padding-x">
                      {selectedCategories &&
                        selectedCategories.map((questionCategory) => (
                          <Cell
                            small={12}
                            large={4}
                            key={'selected-category-' + questionCategory.id}
                          >
                            <QuestionCategory
                              afterOpenModal={null}
                              categoryFormButtons={null}
                              closeModal={null}
                              currentModal={null}
                              deselectCategory={null}
                              handleChange={null}
                              openModal={null}
                              questionCategory={questionCategory}
                              questionResultsContent={questionResultsContent}
                              selectCategory={null}
                              selectedCategories={null}
                              submissionUnlocked={false}
                              showSummary={false}
                              showNotes={true}
                            />
                          </Cell>
                        ))}
                    </Grid>
                  </div>
                </div>
              </Cell>
            </Grid>
          </div>
        </>
      )}
    </>
  );
}
