// Backend
import React from 'react';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Components
import { Header } from '../../constants/SharedComponents';

export function PasswordResetsShow() {
  // Content
  return (
    <>
      <Header />

      <div className="grid-container">
        <Grid className="grid-padding-x align-justify">
          <Cell small={12} large={6}>
            <div className="page-content">
              <h1 className="page-content__title">
                Welcome to FWD Together. Ready for your conversation?
              </h1>

              <div className="page-content__styles">
                <p>
                  If you haven't already, you can sign-up to the service with your work email using
                  the signup form. If you aren't sure, speak to your line manager who may be able to
                  assist with this.
                </p>

                <p>Funded by DLUHC Local Digital Fund</p>
              </div>
            </div>
          </Cell>

          <Cell small={12} large={5}>
            <div className="basic-form basic-form--background">
              <h2 className="basic-form__title">Password Reset Request Completed</h2>

              <div className="basic-form__styles">
                <p>
                  We have received your request to reset your password. You should receive an email
                  confirming the reset with instructions on how to set a new password.
                </p>
              </div>
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  );
}
