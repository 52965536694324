// Backend
import React from 'react';

// Frontend
import { Grid, Cell } from 'react-foundation';
import userGuideImage from '../../images/content/pages/16-sm.png';
import flowChartImage from '../../images/content/pages/FWD - FlowChart-sm.png';
import '../../stylesheets/components/Pages.css';

// Functionality
import { useLocation, Link } from 'react-router-dom';

// Components
import { Header, FlashMessage } from '../../constants/SharedComponents';
import useCurrentUser from '../../hooks/useCurrentUser';

export function PagesHowToUse() {
  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();

  // Objects
  const [flashMessageObject, setFlashMessageObject] = React.useState({});

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  return (
    <>
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />

      <div className="grid-container">
        <Grid className="grid-padding-x align-justify">
          <Cell small={12}>
            <div className="page-content">
              <h1 className="page-content__title">
                How to use this tool The Conversation Canvas &trade;
              </h1>
            </div>
          </Cell>
          <Cell small={12} large={6}>
            <div className="page-content">
              <div className="page-content__styles">
                <p>
                  The conversation canvas guides you through a process of exploring any challenges
                  your team member is facing. You can explore together the root causes of each
                  topic, by delving deeper into ‘why’ this topic is challenging. You can then
                  discuss the ‘impact’ this is having on both the individual and their work. The
                  tool allows you to capture these ‘whys’ and ‘impacts’ in your conversation canvas.
                  Following this, you will be prompted to generate and discuss possible ‘controls’
                  to the risks that have emerged. Finally, you should agree together how these
                  controls can be addressed by identifying a series of co-developed ‘actions’.
                </p>
                <p>
                  The team member can choose what they share and a record is then created that can
                  be viewed and discussed again at various stages.
                </p>
                <p>
				  <Link
                      to={process.env.PUBLIC_URL + '/FWD - How to guide.pdf'}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="page-content__button"
                    >Download User Guide
                    </Link>
                </p>
                <p>
                  <Link to="/conversations" className="page-content__button">
                    Go to your Dashboard
                  </Link>
                </p>
              </div>
            </div>
          </Cell>
          <Cell small={12} large={6}>
            <div className="page-content">
			      <Link
                      to={process.env.PUBLIC_URL + '/FWD - How to guide.pdf'}
                      target="_blank"
                      rel="noopener noreferrer"
                   >
			  <div className="image-container">
                <img src={userGuideImage} loading="lazy" alt="" />
			    <div className="image-overlay">
					<h3>User Guide</h3>
				  	<span >Download User Guide</span>
                    
				</div>
			  </div>
			  </Link>
            </div>
          </Cell>
		   <Cell small={12} large={12}>
            <div className="page-content">
			      <Link
                      to={process.env.PUBLIC_URL + '/FWD - FlowChart.pdf'}
                      target="_blank"
                      rel="noopener noreferrer"
                   >
                <img src={flowChartImage} loading="lazy" alt="FWD Flowchart" />
			  </Link>
            </div>
          </Cell>
          </Grid>
      </div>
    </>
  );
}
