// Backend
import { React, useEffect, useState, useRef } from 'react';
import axios from 'axios';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality
import { Link, useNavigate, useLocation } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  Flashable,
  ProgressBar,
  disableFormButton,
  enableFormButton,
  googleReCaptchaSiteKey
} from '../../constants/SharedComponents';
import { SignInButton } from '../../components/SignInButton';
import ReCAPTCHA from 'react-google-recaptcha';

export function SessionsNew() {
  // API
  const navigate = useNavigate();
  const { state } = useLocation();

  // Objects
  const [loading, setLoading] = useState(false);
  const [flashMessageObject, setFlashMessageObject] = useState({});
  const [inputs, setInputs] = useState({});
  const [capVal, setCapVal] = useState(null);
  const basicFormButtons = useRef([]);
  const googleSiteKey = googleReCaptchaSiteKey();

  useEffect(() => {
    if (state?.flash) {
      setFlashMessageObject(Flashable(state?.flash, 'alert', false));
    }
    else if (state?.flashMessage && state?.flashAlert) {
      setFlashMessageObject(Flashable(state?.flashMessage, state?.flashAlert, false));
    }
  }, [state]);

  // Functions
  function createSubmission(formInputs) {
    const Api = new DluchApi('SessionsNew');
    let requestPayload = { email: formInputs.email_address, password: formInputs.password };

    axios
      .post(Api.ApiRequestUrl(), requestPayload, { timeout: 30000 })
      .then((response) => {
        let responseData = response.data.content;

        if (responseData.twoFactorEnabled) {
          const redirectTo = '/authentications/new';
          secureLocalStorage.setItem('userAuthentications', JSON.stringify(responseData));
          navigate(redirectTo);
        } else {
          const redirectTo = '/conversations';
          secureLocalStorage.setItem('userDetails', JSON.stringify(responseData));
          navigate(redirectTo);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          setFlashMessageObject(Flashable(error.response.data.errorDesc, 'alert', false));
        } else {
          setFlashMessageObject(Flashable(error.message, 'alert', false));
        }

        enableFormButton(basicFormButtons);
        setLoading(false);
      });
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    disableFormButton(basicFormButtons);
    createSubmission(inputs);
  };

  // Content
  return (
    <>
      <ProgressBar loading={loading} />

      <Header />

      <div className="grid-container">
        <Grid className="grid-padding-x align-justify">
          <Cell small={12} large={6}>
            <div className="page-content">
              <h1 className="page-content__title page-content__title--with-subtext">
                Welcome to FWD Together
                <span className="page-content__title-subtext">
                  , a ground breaking tool that can improve workplace well being one conversation at
                  a time.
                </span>
              </h1>

              <div className="page-content__styles">
                <p>After sign up you’ll be guided through the platform to help get you started.</p>
                <p>
                  If you haven't already, you can sign-up to the service with your work email using
                  the signup form. If you aren't sure, speak to your line manager who may be able to
                  assist with this.
                </p>

                <p>Funded by DLUHC Local Digital Fund.</p>
              </div>
            </div>
          </Cell>

          <Cell small={12} large={5}>
            <div className="basic-form basic-form--background">
              <h2 className="basic-form__title">Login to your account</h2>

              <div className="basic-form__styles">
                <p>We just need a few details from you to get going...</p>
              </div>

              <FlashMessage object={flashMessageObject} columnWrap={false} />

              <form className="basic-form" onSubmit={handleSubmit}>
                <div className="basic-form__field">
                  <label
                    htmlFor="email_address"
                    className="basic-form__label  basic-form__label--sr-only"
                  >
                    Email address:
                  </label>

                  <input
                    className="basic-form__input"
                    type="email"
                    name="email_address"
                    id="email_address"
                    value={inputs.email_address || ''}
                    onChange={handleChange}
                    required={true}
                    placeholder="Email address (example@hullcitycouncil.gov.uk)"
                  />
                </div>

                <div className="basic-form__field">
                  <label
                    htmlFor="password"
                    className="basic-form__label  basic-form__label--sr-only"
                  >
                    Password:
                  </label>

                  <input
                    className="basic-form__input"
                    type="password"
                    name="password"
                    id="password"
                    value={inputs.password || ''}
                    onChange={handleChange}
                    required={true}
                    placeholder="Password"
                  />
                </div>

                <ReCAPTCHA
                  sitekey={googleSiteKey}
                  onChange={(val) => setCapVal(val)}
                  style={{ paddingBottom: 6}}
                />

                <div className="basic-form__actions">
                  <input
                    disabled={!capVal}
                    value="Sign-In"
                    type="submit"
                    className="basic-form__button"
                    id="page-form-button--submit"
                    ref={(ref) => (basicFormButtons.current[0] = ref)}
                  />
                </div>
                
                <div className="basic-form__footer">
                  <p>
                    <Link
                      to="/password-resets/new"
                      className="basic-form__link"
                      id="page-navigation-button--password-reset"
                    >
                      Forgotten password
                    </Link>
                  </p>

                  <p>
                    Don't have an account yet? You can{' '}
                    <Link to="/" className="basic-form__link" id="page-navigation-button--create">
                      sign up here
                    </Link>
                    .
                  </p>
                </div>
              </form>
              <div className='separator'> OR </div>
              <p></p>
              <SignInButton />
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  );
}
