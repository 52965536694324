// Backend
import React from 'react';
import axios from 'axios';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality
import { useParams, useLocation, Link } from 'react-router-dom';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  ProgressBar,
  ErrorRender,
  setToken,
} from '../../constants/SharedComponents';
import useCurrentUser from '../../hooks/useCurrentUser';

export function ConversationsShow() {
  // Params
  const { conversationId } = useParams();

  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();
  const token = setToken(currentUser);
  const headerParams = { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 };

  // Objects
  const [flashMessageObject, setFlashMessageObject] = React.useState({});
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  // Functions
  function retryFetch() {
    setLoading(true);
    setError(false);
    setData(null);
    getData();
  }

  const getData = async () => {
    try {
      let Api = new DluchApi('ConversationsShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.conversation;

      setData(responseData);
      setError(false);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (currentUser && currentUser.token) {
      retryFetch();
    }

    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  return (
    <>
      <ProgressBar loading={loading} />
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />
      <ErrorRender object={error} />

      {data && (
        <>
          <div className="grid-container">
            <Grid className="grid-padding-x align-justify">
              <Cell small={12}>
                <div className="page-content">
                  <div className="page-content__header">
                    <Link
                      to="/conversations"
                      className="page-content__button"
                      id="page-navigation-button--go-back"
                    >
                      Go back
                    </Link>
                  </div>

                  <h1 className="page-content__title">Conversation</h1>

                  {!data.isUserManager && (
                    <>
                      <p>You can begin conversation preparation.</p>

                      <Link
                        to={'/conversations/' + data.id + '/team-member-prep/new'}
                        className="page-content__button"
                        id="page-navigation-button--start"
                      >
                        Start
                      </Link>
                    </>
                  )}

                  {data.isUserManager && (
                    <>
                      <p>The conversation has been created.</p>

                      <p>
                        {data.isOrganiserManager ? data.attendeeName : data.organiserName} has been
                        notified and will be invited to either register on the system if they are a
                        first time user, or to log onto their dashboard and undertake the
                        preparation for this meeting.
                      </p>

                      <p>
                        Remember in your capacity as a Manager our FWD Together platform will notify
                        you of the conversation topics{' '}
                        {data.isOrganiserManager ? data.attendeeName : data.organiserName} would
                        like to discuss.
                      </p>
                    </>
                  )}
                </div>
              </Cell>
            </Grid>
          </div>
        </>
      )}
    </>
  );
}
