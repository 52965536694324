// Backend
import React from 'react';
import axios from 'axios';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality
import { useParams, useLocation, useNavigate } from 'react-router-dom';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  ProgressBar,
  ErrorRender,
  setToken,
} from '../../../constants/SharedComponents';

import useCurrentUser from '../../../hooks/useCurrentUser';

export function CanvasesCategoriesShow() {
  // Params
  const { conversationId, categoryId } = useParams();
  const navigate = useNavigate();

  // API
  const currentUser = useCurrentUser();

  // Objects
  const [loading, setLoading] = React.useState(true);

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    setLoading(true);

    if (currentUser && currentUser.token) {
      const redirectTo = '/canvases/' + conversationId + '/categories/';
      navigate(`${redirectTo}${categoryId}/edit`);
    }
  }, [currentUser]);

  return (
    <>
      <ProgressBar loading={loading} />
      <Header />

      <div className="grid-container">
        <Grid className="grid-padding-x">
          <Cell small={12}>
            <p>Please wait...</p>
          </Cell>
        </Grid>
      </div>
    </>
  );
}
