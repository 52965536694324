import React from "react";
import axios from 'axios';

// Frontend
import { Grid, Cell, Button } from 'react-foundation';
import { Trash, Pen, ArrowRightCircle, X } from 'react-bootstrap-icons';
import Modal from "react-bootstrap/Modal";
import PropTypes from 'prop-types'

// Functionality
import { Link } from 'react-router-dom';
import format from 'date-fns/format';

// Components
import {
	DluchApi,
	disableFormButton,
	enableFormButton,
	setToken,
	setUserId,
} from '../constants/SharedComponents';
import useCurrentUser from '../hooks/useCurrentUser';

function EmailColumn({ props }) {
	return props.emailAddress;
}

function ForenameColumn({ props }) {
	return props.firstname;
}

function SurnameColumn({ props }) {
	return props.lastname;
}

function RoleColumn({ props }) {
	return props.role;
}

function ActionsColumn({ props }) {
	let
		className,
		editButton,
		soloEditButton,
		deleteButton,
		editLink,
		currentUser,
		currentUserId,
		token,
		headerParams;

	ActionsColumn.propTypes = {
		props: PropTypes.any,
		id: PropTypes.string,

	};

	editLink = "/admin/users/edit/" + props.id;
	currentUser = useCurrentUser();
	currentUserId = setUserId(currentUser);
	token = setToken(currentUser);
	headerParams = { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 };

	const [show, setShow] = React.useState(false);
	const [userData, setUserData] = React.useState({
		forename: '',
		surname: '',
	});
	const basicFormButtons = React.useRef([]);

	const handleClose = () => {
		setShow(false);
	};
	const handleShow = () => {
		getDeleteData();
		setShow(true);
	};
	const handleDeleteSubmit = (event) => {
		event.preventDefault();
		disableFormButton(basicFormButtons);
		createDeleteSubmission(userData);
		setShow(false);
	}

	const getDeleteData = async () => {
		try {
			let Api = new DluchApi('UsersGet', props.id);
			const response = await axios.get(Api.ApiRequestUrl(), headerParams);

			setUserData(
				{
					forename: response.data.content.user.firstname,
					surname: response.data.content.user.lastname,
				});

		} catch (err) {
			setUserData(null);
		}
	};

	function createDeleteSubmission(formInputs) {
		const Api = new DluchApi('AdminUserDelete', props.id);

		axios
			.delete(Api.ApiRequestUrl(), headerParams)
			.then(() => {
				window.location.reload(false);
			})
			.catch((error) => {
				enableFormButton(basicFormButtons);
			});
	}

	const AdminDeleteUserModal = () => {
		return (
			<div>
				<Modal show={show} onHide={handleClose} centered>
					<div className="basic-form basic-form--background">
						<Modal.Header closeButton>
							<Modal.Title>
								<h2 className="border-0 basic-form__title">Delete User</h2>
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className="basic-form__styles">
								<p>Are you sure that you want to delete the user <b>{userData.forename || ''} {userData.surname || ''}</b>?</p>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button className="data-table__faux-button" variant="secondary" onClick={handleClose}>
								Close
								<X size={16} className="data-table__icon" />
							</Button>
							<form className="basic-form" onSubmit={handleDeleteSubmit}>
								<div className="basic-form__actions">
									<input
										style={boxStyle}
										value="Delete User"
										type="submit"
										className="basic-form__button"
										data-testid="registration-form-submit"
										id="page-form-button--submit"
										ref={(ref) => (basicFormButtons.current[0] = ref)}
									/>
								</div>
							</form>
						</Modal.Footer>
					</div>
				</Modal>
			</div>
		);
	};

	soloEditButton = (
		<Link to={editLink} className="data-table__faux-button" style={{ minWidth: "100px", marginLeft: "130px" }}>
			Edit
			<Pen size={16} className="data-table__icon" />
		</Link>
	);


	editButton = (
		<Link to={editLink} className="data-table__faux-button" style={{ minWidth: "100px", marginLeft: "20px" }}>
			Edit
			<Pen size={16} className="data-table__icon" />
		</Link>
	);

	const [isHover, setIsHover] = React.useState(false);

	const handleMouseEnter = () => {
		setIsHover(true);
	};
	const handleMouseLeave = () => {
		setIsHover(false);
	};

	const boxStyle = {
		minWidth: "110px",
		backgroundColor: isHover ? "rgb(170,0,0)" : "rgb(255, 0, 0)",
	};

	deleteButton = (
		<div>
			<Link className="data-table__faux-button"
				style={boxStyle}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={handleShow}
			>
				Delete
				<Trash size={16} className="data-table__icon" />
			</Link>
			<AdminDeleteUserModal />
		</div>
	);

	if (currentUserId === props.id) {
		return (
			<Grid>
				<Cell large={6}>
					<div>
						<span className={'data-table__icon-link data-table__icon-link--' + className}>
							{soloEditButton}
						</span>
					</div>
				</Cell>
				<Cell large={6}></Cell>
			</Grid>
		);
	}
	else {
		return (
			<Grid>
				<Cell large={6}>
					<span className={'data-table__icon-link data-table__icon-link--' + className} >
						{editButton}
					</span>
				</Cell>
				<Cell large={6}>
					<span className={'data-table__faux-link data-table__faux-link--' + className}>
						{deleteButton}
					</span>
				</Cell>
			</Grid>
		);
	}
}

const UserDataTableColumns = [
	{
		name: 'Email address',
		selector: (row) => row.emailAddress,
		cell: (props) => EmailColumn({ props }),
		sortable: true,
		fixed: true,
		reorder: false,
		width: '350px',
	},
	{
		name: 'Surname',
		selector: (row) => row.lastname,
		cell: (props) => SurnameColumn({ props }),
		sortable: true,
		fixed: true,
		reorder: false,
		width: '200px',
	},
	{
		name: 'Forename',
		selector: (row) => row.firstname,
		cell: (props) => ForenameColumn({ props }),
		sortable: true,
		fixed: true,
		reorder: false,
		width: '200px',
	},
	{
		name: 'Role',
		selector: (row) => row.role,
		cell: (props) => RoleColumn({ props }),
		sortable: true,
		fixed: true,
		reorder: false,
		width: '150px',
	},
	{
		name: 'Actions',
		selector: (row) => row.status,
		cell: (props) => ActionsColumn({ props }),
		sortable: true,
		reorder: false,
		fixed: true,
		width: '270px',
	}
];

const FilterComponent = ({ filterText, filterStatus, onFilterText, onFilterStatus, onClear }) => (
	<form className="conversations-filter-form">
		<Grid className="grid-padding-x align-justify">
			<Cell small={12} large={6}>
				<input
					type="search"
					value={filterText}
					onChange={onFilterText}
					required={true}
					placeholder="Search by Name..."
					className="conversations-filter-form__input"
				/>
			</Cell>

			<Cell small={4}>
				<select
					value={filterStatus}
					onChange={onFilterStatus}
					className="conversations-filter-form__filter"
				>
					<option value="">Filter by Status</option>
					<option value="0">Not Started</option>
					<option value="1">In Progress</option>
					<option value="2">Complete</option>
				</select>
			</Cell>

			<Cell small={2}>
				{(filterStatus || filterText) && (
					<Button onClick={onClear} className="conversations-filter-form__button">
						Clear filter
					</Button>
				)}
			</Cell>
		</Grid>
	</form>
);

FilterComponent.propTypes = {
	filterText: PropTypes.string,
	filterStatus: PropTypes.string,
	onFilterText: PropTypes.func,
	onFilterStatus: PropTypes.func,
	onClear: PropTypes.func
};

function StatusColumn({ props }) {
	let statusString,
		className,
		button,
		buttonString = 'View';

	StatusColumn.propTypes = {
		props: PropTypes.any,
		status: PropTypes.number,
		id: PropTypes.string
	};

	switch (props.status) {
		case 0:
			statusString = 'Not Started';
			className = 'pending';
			break;
		case 1:
			statusString = 'In Progress';
			className = 'in-progress';
			break;
		case 2:
			statusString = 'Complete';
			className = 'complete';
			break;
		case 3:
			statusString = 'Deleted';
			break;
		default:
			statusString = 'unknown';
	}

	if (props.status === 2) {
		button = (
			<Link to={`/admin/canvases/${props.id}/categories/1/admin`} className="data-table__faux-button">
				{buttonString}
				<ArrowRightCircle size={16} className="data-table__icon" />
			</Link>
		);
	}

	return (
		<span className={'data-table__icon-link data-table__icon-link--' + className}>
			<span className="data-table__status-string">{statusString}</span>
			{button}
		</span>
	);
}


function UpdatedAtColumn({ props }) {
	let date = new Date(props.updatedAt);
	let dateTime = format(date, 'dd/MM/yy');

	return dateTime;
}

function CreatedAtColumn({ props }) {
	let date = new Date(props.createdAt);
	let dateTime = format(date, 'dd/MM/yy');

	return dateTime;
}

function ManagerColumn({ props }) {
	const name = props.isOrganiserManager === true ? props.organiserName : props.attendeeName;
	const creator = props.isOrganiserManager === true ? '*' : '';

	const trueName = name === '' ? 'Anonymous' : name;
	return trueName + creator;
}

function TeamMemberColumn({ props }) {
	const name = props.isOrganiserManager !== true ? props.organiserName : props.attendeeName;
	const creator = props.isOrganiserManager !== true ? '*' : '';

	const trueName = name === '' ? 'Anonymous' : name;
	return trueName + creator;
}

function EmployeePrepColumn(props) {
	let statusString, buttonString, conversationLink, className, icon;

	switch (props.employeePrepStatus) {
		case 0:
			statusString = 'Not Started';
			className = 'pending';
			break;
		case 1:
			statusString = 'In Progress';
			className = 'in-progress';
			break;
		case 2:
			buttonString = 'View';
			statusString = 'Complete';
			className = 'complete';
			break;
		default:
			statusString = 'unknown';
	}

	if (props.employeePrepStatus === 2) {
		conversationLink = '/admin/conversations/' + props.id + '/admin';
	}
	else {
		conversationLink = '';
	}

	let button = (
		<span className={`data-table__faux-button data-table__faux-button--${className}`}>
			{buttonString}
			<ArrowRightCircle size={16} className="data-table__icon" />
		</span>
	);

	if (conversationLink) {
		icon = (
			<Link
				to={conversationLink}
				className={'data-table__icon-link data-table__icon-link--' + className}
			>
				<span className="data-table__status-string">{statusString}</span>
				{button}
			</Link>
		);
	} else {
		icon = (
			<span className={'data-table__icon-link data-table__icon-link--' + className}>
				<span className="data-table__status-string">{statusString}</span>
			</span>
		);
	}

	return icon;
}

const ConversationDataTableColumns = [
	{
		name: 'Manager',
		selector: (row) => (row.isOrganiserManager === true ? row.organiserName : row.attendeeName),
		cell: (props) => ManagerColumn({ props }),
		sortable: true,
		fixed: true,
		reorder: false,
		width: '200px',
	},
	{
		name: 'Team member',
		selector: (row) => (row.isOrganiserManager !== true ? row.organiserName : row.attendeeName),
		cell: (props) => TeamMemberColumn({ props }),
		sortable: true,
		fixed: true,
		reorder: false,
		width: '200px',
	},
	{
		name: 'Created',
		selector: (row) => row.createdAt,
		cell: (props) => CreatedAtColumn({ props }),
		sortable: true,
		reorder: false,
		fixed: true,
		width: '110px',
	},
	{
		name: 'Updated',
		selector: (row) => row.updatedAt,
		cell: (props) => UpdatedAtColumn({ props }),
		sortable: true,
		reorder: false,
		fixed: true,
		width: '140px',
	},
	{
		name: 'Preparation',
		selector: (row) => row.employeePrepStatus,
		cell: (props) => EmployeePrepColumn(props),
		sortable: true,
		reorder: false,
		fixed: true,
	},
	{
		name: 'Conversation',
		selector: (row) => row.status,
		cell: (props) => StatusColumn({ props }),
		sortable: true,
		reorder: false,
		fixed: true,
	},
];

const UserFilterComponent = ({ filterText, onFilterText, filterRole, onFilterRole, onClear }) => (
	<form className="conversations-filter-form">
		<Grid className="grid-padding-x align-justify">
			<Cell small={12} large={6}>
				<input
					type="search"
					value={filterText}
					onChange={onFilterText}
					required={true}
					placeholder="Search for User..."
					className="conversations-filter-form__input"
				/>
			</Cell>

			<Cell small={4}>
				<select
					value={filterRole}
					onChange={onFilterRole}
					className="conversations-filter-form__filter"
				>
					<option value="">Filter by Role</option>
					<option value="User">User</option>
					<option value="Admin">Admin</option>
				</select>
			</Cell>

			<Cell small={2}>
				{((filterText) || (filterRole)) && (
					<Button onClick={onClear} className="conversations-filter-form__button">
						Clear filter
					</Button>
				)}
			</Cell>
		</Grid>
	</form>
);

UserFilterComponent.propTypes = {
	filterText: PropTypes.string,
	onFilterText: PropTypes.func,
	filterRole: PropTypes.string,
	onFilterRole: PropTypes.func,
	onClear: PropTypes.func
};

export { UserDataTableColumns, FilterComponent, ConversationDataTableColumns, UserFilterComponent };
