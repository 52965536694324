// Backend
import React from 'react';
import axios from 'axios';

// Frontend
import { Grid, Cell } from 'react-foundation';
import { ChatDots } from 'react-bootstrap-icons';
import '../../stylesheets/components/forms/Conversations.css';
import '../../stylesheets/components/data-tables/Conversations.css';
import flowChartImage from '../../images/content/pages/FWD - FlowChart-sm.png';

// Functionality
import { Link, useLocation } from 'react-router-dom';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  ProgressBar,
  ErrorRender,
  setToken,
} from '../../constants/SharedComponents';

import useCurrentUser from '../../hooks/useCurrentUser';
import DataTable from 'react-data-table-component';
import { FilterComponent, dataTableColumns } from '../../helpers/Conversation';

export function ConversationsIndex() {
  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();
  const token = setToken(currentUser);
  const headerParams = { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 };

  // Objects
  const [flashMessageObject, setFlashMessageObject] = React.useState({});
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  // Functions
  function retryFetch() {
    setLoading(true);
    setError(false);
    setData(null);
    getData();
  }

  const getData = async () => {
    try {
      let Api = new DluchApi('ConversationsIndex');
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.conversations;
      setData(responseData);
      if (responseData && responseData.length < 1) {
        setEmptyData(true);
      }
      setError(false);
    } catch (err) {
      setError(err.message);
      setEmptyData(true);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (currentUser?.token) {
      retryFetch();
    }

    if (state?.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  // DataTable
  const [filterText, setFilterText] = React.useState('');
  const [filterStatus, setFilterStatus] = React.useState('');
  const [emptyData, setEmptyData] = React.useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  let filteredItems;

  if (data && data.length > 0) {
    filteredItems = data.filter((item) => {
      return (
        ((item.organiserName?.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.attendeeName?.toLowerCase().includes(filterText.toLowerCase()))) &&
        (filterStatus ? item.status === parseInt(filterStatus) : true)
      );
    });
  }

  const filterTextFunction = React.useCallback((event) => {
    setFilterText(event.target.value);
  }, []);

  const filterStatusFunction = React.useCallback((event) => {
    setFilterStatus(event.target.value);
  }, []);

  const handleClear = React.useCallback(() => {
    setResetPaginationToggle(!resetPaginationToggle);
    setFilterText('');
    setFilterStatus('');
  }, [resetPaginationToggle]);

  return (
    <>
      <ProgressBar loading={loading} />
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />
      <ErrorRender object={error} />

      <div className="page-content">
        <div className="page-content__header">
          <div className="grid-container">
            <Grid className="grid-padding-x align-justify">
              <Cell small={12} large={4}>
                <div>
                  <Link
                    to="/conversations/new"
                    id="page-navigation-button--create"
                    className="page-content__button page-content__button--bold page-content__button--with-icon"
                  >
                    <span className="page-content__button-icon-wrap">
                      <ChatDots size={19}/>
                    </span>
                    {''}
                    Create Conversation
                  </Link>
                </div>
                * Person who created the conversation
              </Cell>
              <Cell small={12} large={8}>
                <FilterComponent
                  onFilterText={filterTextFunction}
                  onFilterStatus={filterStatusFunction}
                  onClear={handleClear}
                  filterText={filterText}
                  filterStatus={filterStatus}
                />
              </Cell>
            </Grid>
          </div>
        </div>
      </div>
      {currentUser && emptyData && (
        <div className="grid-container">
          <Grid className="grid-padding-x align-justify">
            <Cell small={12}>
              <div className="page-content">
                <h1 className="page-content__title">
                  Welcome {currentUser.firstname} to your dashboard,
                </h1>

                <div className="page-content__styles">
                  <p>
                    <strong>
                      You currently don't have any conversations. Conversations will appear here
                      as they are created.
                    </strong>
                  </p>
                  <p>
                    We recommend that you read our guide on how to use tool:&nbsp;
                    <Link to="/pages/how-to-use-this-tool" className="page-content__button">
                      How to use this tool
                    </Link>
                  </p>
                  <p></p>
                </div>
              </div>
            </Cell>
            <Cell small={12} large={12}>
              <div className="page-content">
                <Link to="/pages/how-to-use-this-tool">
                  <img src={flowChartImage} loading="lazy" alt="FWD Flowchart" />
                </Link>
              </div>
            </Cell>
          </Grid>
        </div>
      )}

      {data && data.length > 0 && (
        <div className="grid-container">
          <Grid className="grid-padding-x align-justify">
            <Cell small={12} className="js--data-table-wrap">
              <DataTable
                columns={dataTableColumns}
                data={filteredItems}
                defaultSortFieldId={3}
                responsive
                fixedHeader
                pagination
                defaultSortAsc={false}
              />
            </Cell>
          </Grid>
        </div>
      )}
    </>
  );
}
