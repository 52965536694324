// Backend
import React from 'react';
import axios from 'axios';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality
import { useParams, useLocation, Link } from 'react-router-dom';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  Flashable,
  ProgressBar,
  disableFormButton,
  enableFormButton,
  ErrorRender,
  setToken,
  filteredCategories,
} from '../../../constants/SharedComponents';

import useCurrentUser from '../../../hooks/useCurrentUser';

export function ConversationsConfirmationsNew() {
  // Params
  const { conversationId } = useParams();

  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();
  const token = setToken(currentUser);
  const headerParams = { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 };

  // Objects
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [flashMessageObject, setFlashMessageObject] = React.useState({});
  const [inputs, setInputs] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [questionResultData, setQuestionResultData] = React.useState(null);
  const [questionCategoriesData, setQuestionCategoriesData] = React.useState(null);
  const [selectedCategories, setSelectedCategories] = React.useState([]);
  const [submissionUnlocked, setSubmissionUnlocked] = React.useState(false);
  const [questionScores, setQuestionScores] = React.useState(null);

  const basicFormButtons = React.useRef([]);

  // Functions

  function processFormData(formInputs) {
    let categoryIds = [];
    let index = 0;
    let questionResults = [];

    for (const key of Object.keys(formInputs)) {
      let matches = key.split('--');

      if (matches[1] === undefined) {
        continue;
      }

      categoryIds.push(matches[1]);
    }

    categoryIds = Array.from(new Set(categoryIds));

    for (const categoryId in categoryIds) {
      const key = 'notes--' + categoryIds[categoryId];

      let notes = '';

      if (formInputs.hasOwnProperty(key)) {
        notes = formInputs[key];
      }

      let formData = {};

      formData['notes'] = notes;
      formData['isConversationTopic'] =
        formInputs['isConversationTopic--' + categoryIds[categoryId]] !== undefined
          ? formInputs['isConversationTopic--' + categoryIds[categoryId]]
          : false;
      formData['conversationId'] = conversationId;
      formData['categoryId'] = categoryIds[categoryId];
      formData['priorityRank'] = formInputs['priorityRank--' + categoryIds[categoryId]];
      formData['optionalCategoryName'] =
        formInputs['optionalCategoryName--' + categoryIds[categoryId]];
      questionResults[index] = formData;
      index++;
    }

    return questionResults;
  }

  const sortedQuestionResultData = function (categoryData) {
    let responseData = categoryData.sort(function (a, b) {
      let weight = -1;

      if (a.priorityRank > b.priorityRank) {
        weight = 1;
      } else if (a.priorityRank === b.priorityRank) {
        weight = a.categoryName > b.categoryName ? 1 : -1;
      }

      return weight;
    });

    return responseData.reverse();
  };

  function createSubmission(formInputs) {
    let questionResults = processFormData(formInputs);
    let Api = new DluchApi('QuestionResultsEdit');
    let requestPayload;

    requestPayload = {
      questionResults: questionResults,
      canShareWithManager: formInputs.can_share_with_manager === true,
      isFinished: true,
      generalNotes: formInputs.general_notes,
    };

    axios
      .put(Api.ApiRequestUrl(), requestPayload, headerParams)
      .then(() => {
        setFlashMessageObject(
          Flashable('Thank you. Your chosen topics have now been saved.', 'notice', false),
        );
        setSubmissionUnlocked(false);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          setFlashMessageObject(Flashable(error.response.data.errorDesc, 'alert', false));
        } else {
          setFlashMessageObject(Flashable(error.message, 'alert', false));
        }
      });

    enableFormButton(basicFormButtons);
    setLoading(false);
  }

  const getQuestionScores = async () => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionScoresShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.sortedScores;
      setError(false);
      getTemperatureQuestionCategory(responseData[0]);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const getTemperatureQuestionCategory = async (questionScores) => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionsCategoriesIndex', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.categories;
      let result = filteredCategories(responseData, 3);
      getQuestionOptions(questionScores, result[0]);
      setError(false);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const getQuestionOptions = async (questionScores, temperatureQuestionCategory) => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionsIndex', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content;
      let result = responseData.sortedQuestions.find(
        (obj) => obj.categoryId === temperatureQuestionCategory.id,
      );
      questionScores.optionText = JSON.parse(result.questionOptions).find(
        (obj) => obj.OptionScore === questionScores.score,
      ).OptionText;
      setQuestionScores(questionScores);
      setError(false);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const getData = async () => {
    setLoading(true);

    try {
      let Api = new DluchApi('ConversationsShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.conversation;
      setData(responseData);
      setSubmissionUnlocked(responseData.employeePrepStatus !== 2);
      getQuestionCategoriesData();
      setError(false);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const getQuestionCategoriesData = async () => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionsCategoriesIndex', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.categories;
      let allCategories = filteredCategories(responseData, 3, false);
      setQuestionCategoriesData(allCategories);
      setError(false);
      getQuestionResultData(allCategories);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const getQuestionResultData = async (questionCategories) => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionResultsShow', conversationId);
      let defaultInputs = {};
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      //let responseData = sortedQuestionResultData(response.data.content.results);
      let responseData = response.data.content.results;

      defaultInputs['general_notes'] = response.data.content.generalNotes;

      let chosenCategories = responseData.filter((obj) => {
        return obj.isConversationTopic === true;
      });
      let notChosenCategories = responseData.filter((obj) => {
        return obj.isConversationTopic !== true;
      });

      chosenCategories = chosenCategories.map((obj) => obj.categoryId);
      notChosenCategories = notChosenCategories.map((obj) => obj.categoryId);
      chosenCategories = questionCategories.filter((obj) => chosenCategories.includes(obj.id));
      notChosenCategories = questionCategories.filter((obj) =>
        notChosenCategories.includes(obj.id),
      );

      for (const conversationTopic of responseData) {
        defaultInputs['isConversationTopic--' + conversationTopic.categoryId] =
          conversationTopic.isConversationTopic;
        defaultInputs['priorityRank--' + conversationTopic.categoryId] =
          conversationTopic.priorityRank;
        defaultInputs['notes--' + conversationTopic.categoryId] = conversationTopic.notes;
        defaultInputs['optionalCategoryName--' + conversationTopic.categoryId] =
          conversationTopic.optionalCategoryName;
      }

      defaultInputs['can_share_with_manager'] = true;
      setSelectedCategories(chosenCategories);
      setInputs(defaultInputs);
      setQuestionResultData(responseData);
      setError(false);
      getQuestionScores();
    } catch (err) {
      setInputs({});
      setSelectedCategories([]);
    } finally {
      setLoading(false);
    }
  };

  function retryFetch() {
    setLoading(true);
    setError(false);
    setData(null);
    getData();
  }

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (currentUser && currentUser.token) {
      retryFetch();
    }

    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    disableFormButton(basicFormButtons);
    createSubmission(inputs);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    }

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const questionResultsContent = (questionCategoryId, field) => {
    if (!questionResultData) {
      return;
    }

    const category = questionResultData.find((obj) => {
      return obj.categoryId === questionCategoryId;
    });

    if (category) {
      return category[field];
    }
  };

  return (
    <>
      <ProgressBar loading={loading} />
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />
      <ErrorRender object={error} />

      {data && questionCategoriesData && (
        <>
          <div className="grid-container">
            <Grid className="grid-padding-x">
              <Cell small={12}>
                <div className="page-content">
                  {!submissionUnlocked && (
                    <>
                      <div className="page-content__header">
                        <Link
                          to={`/conversations`}
                          className="page-content__button"
                          id="page-navigation-button--go-back"
                        >
                          Back to your Dashboard
                        </Link>
                      </div>

                      <h1 className="page-content__title">
                        Thank you, your prep has been submitted
                      </h1>

                      <div className="page-content__styles">
                        <p>
                          You manager has been notified that you have completed your meeting
                          preparation.
                        </p>
                        <p>You can review this information at anytime from your dashboard.</p>
                      </div>
                    </>
                  )}

                  {submissionUnlocked && (
                    <>
                      <div className="page-content__header">
                        <Link
                          to={`/conversations/` + conversationId + `/categories/edit`}
                          className="page-content__button"
                          id="page-navigation-button--go-back"
                        >
                          Back to Team Member Prep
                        </Link>
                      </div>

                      <h1 className="page-content__title">Review</h1>

                      <div className="page-content__styles">
                        <p>
                          <strong>
                            Please review the following information and click the complete button if
                            you are happy.
                          </strong>
                        </p>
                        <p>
                          Once you have completed your prep you will not be able to make changes but
                          you will be able to read and download your notes as required.
                        </p>
                      </div>

                      <h2 className="page-content__subtitle">
                        The following information will be submitted.
                      </h2>
                    </>
                  )}

                  {submissionUnlocked && !data.isUserManager && (
                    <>
                      <form className="basic-form basic-form--background">
                        <h3 className="page-content__subtitle page-content__subtitle--small">
                          Key issues to discuss are:
                        </h3>
                        <ul className="page-content-list page-content-list--bold">
                          {selectedCategories &&
                            selectedCategories.map((questionCategory) => (
                              <li key={'selected-category-' + questionCategory.id}>
                                {questionResultsContent(questionCategory.id, 'optionalCategoryName')
                                  ? questionResultsContent(
                                      questionCategory.id,
                                      'optionalCategoryName',
                                    )
                                  : questionCategory.name}
                              </li>
                            ))}
                        </ul>

                        {questionScores && (
                          <>
                            <h3 className="page-content__subtitle page-content__subtitle--small">
                              {questionScores.questionText}
                            </h3>

                            <div className="page-content__styles">
                              <p>
                                <b>{questionScores.optionText}</b>
                              </p>
                            </div>
                          </>
                        )}

                        <div className="basic-form__field">
                          <input
                            id="can_share_with_manager"
                            name="can_share_with_manager"
                            type="checkbox"
                            value={true}
                            onChange={handleChange}
                            className="basic-form__faux-checkbox"
                            defaultChecked
                          />

                          <label
                            htmlFor="can_share_with_manager"
                            className="page-content__subtitle page-content__subtitle--small"
                          >
                            I would like to share this information with my manager.
                          </label>
                        </div>

                        <div className="basic-form__actions">
                          <input
                            value="Complete"
                            type="submit"
                            className="basic-form__button basic-form__button--inline"
                            onClick={handleSubmit}
                            ref={(ref) => (basicFormButtons.current[0] = ref)}
                            id="page-form-button--submit"
                          />
                        </div>
                      </form>
                    </>
                  )}
                </div>
              </Cell>
            </Grid>
          </div>
        </>
      )}
    </>
  );
}
