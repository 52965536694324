import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import RefreshToken from './RefreshToken';
import secureLocalStorage from 'react-secure-storage';

// @see https://stackoverflow.com/a/70866269
const PublicRoute = ({ Component, userRoleRequired, redirectPath }) => {
  const localStorageToken = secureLocalStorage.getItem('userDetails');
  const tokenable = new RefreshToken();

  const user = function () {
    if (localStorageToken && JSON.parse(localStorageToken)) {
      return JSON.parse(localStorageToken);
    }
  };

  const accessAvailable = function () {
    let accessGranted = true;

    if (user() && tokenable.tokenValid()) {
      accessGranted = false;
    }

    return accessGranted;
  };

  const renderObject = function () {
    if (Component) {
      return <Component />;
    }

    return <Outlet />;
  };

  return accessAvailable() ? renderObject() : <Navigate to={redirectPath} />;
};

export default PublicRoute;
