import React from "react";
import { Person, Chat } from "react-bootstrap-icons";
import { Cell, Grid, Link } from "react-foundation";

import ProgressBar from "../../components/ProgressBar";
import { setToken, setUserRoles } from "../../constants/SharedComponents";
import useCurrentUser from "../../hooks/useCurrentUser";
import Header from "../../shared/Header";

export function AdminIndex() {
  const [roleData, setRoleData] = React.useState('');
  const currentUser = useCurrentUser();
  const currentUserRole = setUserRoles(currentUser);
  const token = setToken(currentUser);

  React.useEffect(() => {
    if (token === undefined) {
      return;
    }
    const currentRole = currentUserRole[0].roleName;
    setRoleData(currentRole);

  }, [token, currentUserRole]);

  return (
    <>
      <ProgressBar />
      <Header />

      {roleData === "User" && (
        <div className="grid-container">
          <Grid className="grid-padding-x align-justify">
            <Cell small={12}>
              <div className="page-content">
                <h1 className="page-content__title">
                  Page not found
                </h1>
                <div className="page-content__styles">
                  <p>
                    The page you requested cannot be found. Please try again.
                  </p>
                </div>
              </div>
            </Cell>
          </Grid>
        </div>
      )
      }
      {
        (roleData === "Admin" || roleData === "SystemAdmin") && (
          <div className="page-content">
            <div className="page-content__header">
              <div className="grid-container">

                <Grid className="grid-padding-x align-justify">
                  <Cell small={12} large={6}>
                    <div>
                      <Link
                        href="/admin/users"
                        id="page-navigation-button--create"
                        className="page-content__button page-content__button--bold page-content__button--with-icon"
                        style={{ width: "100%", paddingTop: "50px", paddingBottom: "50px", }}
                      >
                        <span className="page-content__button-icon-wrap"><Person size={19} /></span>Manage Users
                      </Link>
                    </div>
                  </Cell>
                  <Cell small={12} large={6}>
                    <div>
                      <Link
                        href="/admin/conversations"
                        id="page-navigation-button--create"
                        className="page-content__button page-content__button--bold page-content__button--with-icon"
                        style={{ width: "100%", paddingTop: "50px", paddingBottom: "50px", }}
                      >
                        <span className="page-content__button-icon-wrap"><Chat size={19} /></span>Manage User Conversations
                      </Link>
                    </div>
                  </Cell>

                </Grid>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}
