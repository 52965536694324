import axios from "axios";
import React from "react";
import { PersonAdd } from "react-bootstrap-icons";
import { Cell, Grid, Link } from "react-foundation";
import DataTable from "react-data-table-component";

import ProgressBar from "../../../components/ProgressBar";
import { setToken, setUserRoles } from "../../../constants/SharedComponents";
import { UserFilterComponent, UserDataTableColumns } from "../../../helpers/Administrator";
import useCurrentUser from "../../../hooks/useCurrentUser";
import DluchApi from "../../../settings/DluchApi";
import Header from "../../../shared/Header";

export function AdminUserIndex() {
  const [data, setData] = React.useState([]);
  const [emptyData, setEmptyData] = React.useState(true);
  const [roleData, setRoleData] = React.useState('');
  const [filterText, setFilterText] = React.useState('');
  const [filterRole, setFilterRole] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const currentUser = useCurrentUser();
  const currentUserRole = setUserRoles(currentUser);
  const token = setToken(currentUser);
  let filteredItems;

  if (data && data.length > 0) {
    filteredItems = data;
  }

  React.useEffect(() => {
    if (token === undefined) {
      return;
    }

    const currentRole = currentUserRole[0].roleName;
    setRoleData(currentRole);

    if (currentRole === "User") {
      setLoading(false);
      setData([]);
      setEmptyData(true);
    }
    else {
      const headerParams = { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 };

      const api = new DluchApi('AdminUserList');
      const uri = api.ApiRequestUrl();

      axios
        .get(uri, headerParams)
        .then(response => {
          const users = response.data.content.users;
          setLoading(false);
          setData(users);
          setEmptyData(users.length === 0);
        })
        .catch(err => {
          setLoading(false);
          setData([]);
          setEmptyData(true);
        });
    }
  }, [token, currentUserRole]);

  // DataTable
  if (data && data.length > 0) {
    filteredItems = data.filter((item) => {
      return (
        (item.firstname?.toLowerCase().includes(filterText.toLowerCase()) ||
          item.lastname?.toLowerCase().includes(filterText.toLowerCase()) ||
          item.emailAddress?.toLowerCase().includes(filterText.toLowerCase())) &&
          (filterRole ? item.role === filterRole : true)
      );
    });
  }

  const filterTextFunction = React.useCallback((event) => {
    setFilterText(event.target.value);
  }, []);

  const filterRoleFunction = React.useCallback((event) => {
    setFilterRole(event.target.value);
  }, []);

  const handleClear = React.useCallback(() => {
    setResetPaginationToggle(!resetPaginationToggle);
    setFilterText('');
    setFilterRole('');
  }, [resetPaginationToggle]);

  return (
    <>
      <ProgressBar loading={loading} />
      <Header />

      {roleData === "User" && (
        <div className="grid-container">
          <Grid className="grid-padding-x align-justify">
            <Cell small={12}>
              <div className="page-content">
                <h1 className="page-content__title">
                  Page not found
                </h1>
                <div className="page-content__styles">
                  <p>
                    The page you requested cannot be found. Please try again.
                  </p>
                </div>
              </div>
            </Cell>
          </Grid>
        </div>
      )
      }
      {
        (roleData === "Admin" || roleData === "SystemAdmin") && (
          <>
            <div className="page-content">
              <div className="page-content__header">
                <div className="grid-container">
                  <Grid className="grid-padding-x align-justify">
                    <Cell small={12} large={12}>
                      <div>
                        <Link
                          href="/admin"
                          id="page-navigation-button--create"
                          className="primary-header__button"
                          style={{ fontWeight: "bold", fontSize: "12pt" }}
                        >
                          Back
                        </Link>
                      </div>
                    </Cell>
                    <Cell small={12} large={4}>
                      <div>
                        <Link
                          href="/admin/users/new"
                          id="page-navigation-button--create"
                          className="page-content__button page-content__button--bold page-content__button--with-icon"
                        >
                          <span className="page-content__button-icon-wrap"><PersonAdd size={19}/></span>Add New User
                        </Link>
                      </div>
                    </Cell>
                    <Cell small={12} large={8}>
                      <UserFilterComponent
                        onFilterText={filterTextFunction}
                        onClear={handleClear}
                        onFilterRole={filterRoleFunction}
                        filterText={filterText}
                        filterRole={filterRole}
                      />
                    </Cell>
                  </Grid>
                </div>
              </div>
            </div>

            {currentUser && emptyData && (
              <div className="grid-container">
                <Grid className="grid-padding-x align-justify">
                  <Cell small={12}>
                    <div className="page-content">
                      <h1 className="page-content__title">
                        Welcome {currentUser.firstname} to administrator portal,
                      </h1>

                      <div className="page-content__styles">
                        <p>
                          <strong>
                            You currently don't have any users. Users will appear here
                            as they are created.
                          </strong>
                        </p>
                        <p></p>
                      </div>
                    </div>
                  </Cell>
                </Grid>
              </div>
            )}

            {data && data.length > 0 && (
              <div className="grid-container">
                <Grid className="grid-padding-x align-justify">
                  <Cell small={12} className="js--data-table-wrap">
                    <DataTable
                      columns={UserDataTableColumns}
                      data={filteredItems}
                      defaultSortFieldId={3}
                      responsive
                      fixedHeader
                      pagination
                      defaultSortAsc={true}
                    />
                  </Cell>
                </Grid>
              </div>
            )}
          </>
        )
      }
    </>
  );
}
