// Backend
import React from 'react';
import axios from 'axios';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality
import { useParams, useNavigate } from 'react-router-dom';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  Flashable,
  ProgressBar,
  disableFormButton,
  enableFormButton,
} from '../../constants/SharedComponents';

import PasswordStrengthMeter from '../../components/PasswordStrengthMeter';

export function PasswordResetsEdit() {
  // Params
  const { userId, userToken } = useParams();

  // API
  const redirectTo = '/sessions/new';
  const navigate = useNavigate();

  // Objects
  const [loading, setLoading] = React.useState(false);
  const [flashMessageObject, setFlashMessageObject] = React.useState({});
  const [inputs, setInputs] = React.useState({});
  const basicFormButtons = React.useRef([]);

  // Functions
  function createSubmission(formInputs) {
    const Api = new DluchApi('PasswordResetsEdit');

    if (formInputs.password === formInputs.confirmPassword) {
      let requestPayload = { userId: userId, token: userToken, password: formInputs.password };

      axios
        .post(Api.ApiRequestUrl(), requestPayload, { timeout: 30000 })
        .then(() => {
          navigate(redirectTo, { state: { flashMessage: 'Password was successfully reset.', flashAlert: 'notice' } });
        })
        .catch((error) => {
          if (error.response?.data) {
            setFlashMessageObject(Flashable(error.response.data.errorDesc, 'alert', false));
          } else {
            setFlashMessageObject(Flashable(error.message, 'alert', false));
          }

          enableFormButton(basicFormButtons);
          setLoading(false);
        });
    }
    else {
      setFlashMessageObject(Flashable('Both passwords should be the same.', 'alert', false));
      enableFormButton(basicFormButtons);
      setLoading(false);
    }
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    disableFormButton(basicFormButtons);
    createSubmission(inputs);
  };

  // Content
  return (
    <>
      <ProgressBar loading={loading} />

      <Header />

      <div className="grid-container">
        <Grid className="grid-padding-x align-justify">
          <Cell small={12} large={6}>
            <div className="page-content">
              <h1 className="page-content__title">
                Welcome to FWD Together. Ready for your conversation?
              </h1>

              <div className="page-content__styles">
                <p>Your nearly there!</p>
                <p>Once you've set a password you'll be taken to your personal dashboard</p>

                <p>Funded by DLUHC Local Digital Fund</p>
              </div>
            </div>
          </Cell>

          <Cell small={12} large={5}>
            <div className="basic-form basic-form--background">
              <h2 className="basic-form__title">Forgotten Password</h2>

              <FlashMessage object={flashMessageObject} columnWrap={false} />

              <form className="basic-form" onSubmit={handleSubmit}>
                <div className="basic-form__field">
                  <label
                    htmlFor="password"
                    className="basic-form__label  basic-form__label--sr-only"
                  >
                    Password:
                  </label>

                  <input
                    className="basic-form__input"
                    type="password"
                    name="password"
                    id="password"
                    data-testid="password"
                    value={inputs.password || ''}
                    onChange={handleChange}
                    required={true}
                    placeholder="Password"
                  />
                  <PasswordStrengthMeter password={inputs.password || ''} />

                  <input
                    className="basic-form__input"
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    data-testid="password"
                    value={inputs.confirmPassword || ''}
                    onChange={handleChange}
                    required={true}
                    placeholder="Confirm Password"
                  />

                </div>

                <div className="basic-form__actions">
                  <input
                    value="Reset Password"
                    type="submit"
                    className="basic-form__button"
                    ref={(ref) => (basicFormButtons.current[0] = ref)}
                    id="page-form-button--submit"
                  />
                </div>
              </form>
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  );
}
