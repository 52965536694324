// Backend
import React from 'react';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality

// Components
import { Header } from '../../../constants/SharedComponents';

export function PlaceholdersIndex() {
  // Content
  return (
    <>
      <Header />

      <div className="grid-container">
        <Grid className="grid-padding-x align-justify">
          <Cell small={12}>
            <h1>Hello world</h1>
          </Cell>
        </Grid>
      </div>
    </>
  );
}
