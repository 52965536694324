// Backend
import React from 'react';
import axios from 'axios';

// Frontend
import { Grid, Cell, Link } from 'react-foundation';

// Functionality
import { useNavigate } from 'react-router-dom';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  Flashable,
  ProgressBar,
  disableFormButton,
  enableFormButton,
  setToken,
  setTenantId,
  setUserRoles,
} from '../../../constants/SharedComponents';
import useCurrentUser from '../../../hooks/useCurrentUser';

const AdminUserNew = () => {
  // API
  const currentUser = useCurrentUser();
  const token = setToken(currentUser);
  const currentUserRole = setUserRoles(currentUser);
  const redirectTo = '/admin/users';
  const organisationId = setTenantId(currentUser);
  const navigate = useNavigate();

  // Objects
  const [loading, setLoading] = React.useState(false);
  const [flashMessageObject, setFlashMessageObject] = React.useState({});
  const [inputs, setInputs] = React.useState({});
  const [roleData, setRoleData] = React.useState('');
  const basicFormButtons = React.useRef([]);

  // Functions
  function createSubmission(formInputs) {
    setFlashMessageObject({});

    let requestPayload = {
      organisationId: organisationId,
      firstname: formInputs.forename,
      lastname: formInputs.surname,
      email: formInputs.email_address,
    };

    let headerParams = {
      headers: {
        Authorization: `Bearer ${token}`,
        timeout: 30000
      }
    }

    const Api = new DluchApi('AdminUserNew');

    axios
      .post(Api.ApiRequestUrl(), requestPayload, headerParams)
      .then(() => {
        setFlashMessageObject(Flashable('Please wait...', 'notice', false));
        navigate(redirectTo);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            setFlashMessageObject(Flashable(error.response.data.errorDesc, 'alert', false));
          }
          else {
            setFlashMessageObject(Flashable(error.message, 'alert', false));
          }
        } else {
          setFlashMessageObject(Flashable(error.message, 'alert', false));
        }

        enableFormButton(basicFormButtons);
        setLoading(false);
      });
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    disableFormButton(basicFormButtons);
    setLoading(true);
    disableFormButton(basicFormButtons);
    createSubmission(inputs);
  };

  // Content

  React.useEffect(() => {
    if (token === undefined) {
      return;
    }
    const currentRole = currentUserRole[0].roleName;
    setRoleData(currentRole);
  }, [token, currentUserRole]);

  return (
    <>
      <ProgressBar loading={loading} />

      <Header />
      {roleData === "User" && (
        <div className="grid-container">
          <Grid className="grid-padding-x align-justify">
            <Cell small={12}>
              <div className="page-content">
                <h1 className="page-content__title">
                  Page not found
                </h1>
                <div className="page-content__styles">
                  <p>
                    The page you requested cannot be found. Please try again.
                  </p>
                </div>
              </div>
            </Cell>
          </Grid>
        </div>
      )
      }
      {
        (roleData === "Admin" || roleData === "SystemAdmin") && (
          <div className="grid-container">
            <Grid className="grid-padding-x align-justify">
              <Cell small={12} large={12}>
                <div>
                  <Link
                    href="/admin/users"
                    id="page-navigation-button--create"
                    className="primary-header__button"
                    style={{ fontWeight: "bold", fontSize: "12pt" }}
                  >
                    Back
                  </Link>
                </div>
              </Cell>
              <Cell small={12} large={3}></Cell>

              <Cell small={12} large={5}>
                <div className="basic-form basic-form--background">
                  <h2 className="basic-form__title">New User</h2>

                  <div className="basic-form__styles">
                    <p>Enter the details for a user with a <b>non-organisation email address</b> below:</p>
                  </div>

                  <FlashMessage object={flashMessageObject} columnWrap={false} />

                  <form className="basic-form" onSubmit={handleSubmit}>
                    <div className="basic-form__field">
                      <label
                        htmlFor="forename"
                        className="basic-form__label  basic-form__label--sr-only"
                      >
                        First name:
                      </label>

                      <input
                        className="basic-form__input"
                        type="text"
                        name="forename"
                        id="forename"
                        data-testid="forename"
                        value={inputs.forename || ''}
                        onChange={handleChange}
                        required={true}
                        placeholder="First name"
                      />
                    </div>

                    <div className="basic-form__field">
                      <label
                        htmlFor="surname"
                        className="basic-form__label  basic-form__label--sr-only"
                      >
                        Last name:
                      </label>

                      <input
                        className="basic-form__input"
                        type="text"
                        name="surname"
                        id="surname"
                        data-testid="surname"
                        value={inputs.surname || ''}
                        onChange={handleChange}
                        required={true}
                        placeholder="Last name"
                      />
                    </div>

                    <div className="basic-form__field">
                      <label
                        htmlFor="email_address"
                        className="basic-form__label  basic-form__label--sr-only"
                      >
                        Email address:
                      </label>

                      <input
                        className="basic-form__input"
                        type="text"
                        name="email_address"
                        id="email_address"
                        data-testid="email_address"
                        value={inputs.email_address || ''}
                        onChange={handleChange}
                        required={true}
                        placeholder="Email address"
                      />
                    </div>

                    <div className="basic-form__actions">
                      <input
                        value="Add User"
                        type="submit"
                        className="basic-form__button"
                        data-testid="registration-form-submit"
                        id="page-form-button--submit"
                        ref={(ref) => (basicFormButtons.current[0] = ref)}
                      />
                    </div>
                  </form>
                </div>
              </Cell>

              <Cell small={12} large={3}></Cell>
            </Grid>
          </div>
        )}
    </>
  );


};

export default AdminUserNew;
