import axios from "axios";
import React from "react";
import { Cell, Grid, Link } from "react-foundation";
import DataTable from "react-data-table-component";

import ProgressBar from "../../../components/ProgressBar";
import { setToken, setUserRoles } from "../../../constants/SharedComponents";
import { FilterComponent, ConversationDataTableColumns } from "../../../helpers/Administrator";
import useCurrentUser from "../../../hooks/useCurrentUser";
import DluchApi from "../../../settings/DluchApi";
import Header from "../../../shared/Header";

export function AdminConversationIndex() {
  const [data, setData] = React.useState([]);
  const [emptyData, setEmptyData] = React.useState(true);
  const [roleData, setRoleData] = React.useState('');
  const [filterStatus, setFilterStatus] = React.useState('');
  const [filterText, setFilterText] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const currentUser = useCurrentUser();
  const currentUserRole = setUserRoles(currentUser);
  const token = setToken(currentUser);
  let filteredItems;

  if (data && data.length > 0) {
    filteredItems = data;
  }

  React.useEffect(() => {
    if (token === undefined) {
      return;
    }

    const currentRole = currentUserRole[0].roleName;
    setRoleData(currentRole);

    if (currentRole === "User") {
      setLoading(false);
      setData([]);
      setEmptyData(true);
    }
    else {


      const api = new DluchApi('AdminConversationList');
      const uri = api.ApiRequestUrl();

      const config = {
        headers: {
          'authorization': 'Bearer ' + token
        }
      };

      axios
        .get(uri, config, { timeout: 30000 })
        .then(response => {
          const conversations = response.data.content.conversations;
          setLoading(false);
          setData(conversations);
          setEmptyData(conversations.length === 0);
        })
        .catch(err => {
          setLoading(false);
          setData([]);
          setEmptyData(true);
        });

    }
  }, [token, currentUserRole]);

  // DataTable
  if (data && data.length > 0) {
    filteredItems = data.filter((item) => {
      return (
        (
          item.organiserName?.toLowerCase().includes(filterText.toLowerCase()) ||
          item.attendeeName?.toLowerCase().includes(filterText.toLowerCase())) &&
        (filterStatus ? item.status === parseInt(filterStatus) : true)
      );
    });
  }

  const filterTextFunction = React.useCallback((event) => {
    setFilterText(event.target.value);
  }, []);

  const filterStatusFunction = React.useCallback((event) => {
    setFilterStatus(event.target.value);
  }, []);

  const handleClear = React.useCallback(() => {
    setResetPaginationToggle(!resetPaginationToggle);
    setFilterText('');
    setFilterStatus('');
  }, [resetPaginationToggle]);

  return (
    <>
      <ProgressBar loading={loading} />
      <Header />

      {roleData === "User" && (
        <div className="grid-container">
          <Grid className="grid-padding-x align-justify">
            <Cell small={12}>
              <div className="page-content">
                <h1 className="page-content__title">
                  Page not found
                </h1>
                <div className="page-content__styles">
                  <p>
                    The page you requested cannot be found. Please try again.
                  </p>
                </div>
              </div>
            </Cell>
          </Grid>
        </div>
      )
      }
      {
        (roleData === "Admin" || roleData === "SystemAdmin") && (
          <>


            <div className="page-content">
              <div className="page-content__header">
                <div className="grid-container">
                  <Grid className="grid-padding-x align-justify">
                    <Cell small={12} large={12}>
                      <div>
                        <Link
                          href="/admin"
                          id="page-navigation-button--create"
                          className="primary-header__button"
                          style={{ fontWeight: "bold", fontSize: "12pt" }}
                        >
                          Back
                        </Link>
                      </div>
                    </Cell>
                    <Cell small={12} large={4}>
                      <div style={{ marginTop: "10px" }}>
                        * Person who created the conversation
                      </div>
                    </Cell>
                    <Cell small={12} large={8}>
                      <FilterComponent
                        onFilterText={filterTextFunction}
                        onFilterStatus={filterStatusFunction}
                        onClear={handleClear}
                        filterText={filterText}
                        filterStatus={filterStatus}
                      />
                    </Cell>
                  </Grid>
                </div>
              </div>
            </div>

            {currentUser && emptyData && (
              <div className="grid-container">
                <Grid className="grid-padding-x align-justify">
                  <Cell small={12}>
                    <div className="page-content">
                      <h1 className="page-content__title">
                        Welcome {currentUser.firstname} to administrator portal,
                      </h1>

                      <div className="page-content__styles">
                        <p>
                          <strong>
                            There are currently no conversations. User's conversations
                            will appear here as they are created.
                          </strong>
                        </p>
                        <p></p>
                      </div>
                    </div>
                  </Cell>
                </Grid>
              </div>
            )}

            {data && data.length > 0 && (
              <div className="grid-container">
                <Grid className="grid-padding-x align-justify">
                  <Cell small={12} className="js--data-table-wrap">
                    <DataTable
                      columns={ConversationDataTableColumns}
                      data={filteredItems}
                      defaultSortFieldId={3}
                      responsive
                      fixedHeader
                      pagination
                      defaultSortAsc={true}
                    />
                  </Cell>
                </Grid>
              </div>
            )}
          </>
        )}
    </>
  );
}
