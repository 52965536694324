const disableFormButton = (buttons) => {
  for (const button in buttons.current) {
    if (buttons.current[button]) {
      buttons.current[button].disabled = 'disabled';
    }
  }
};

const enableFormButton = (buttons) => {
  setTimeout(function () {
    for (const button in buttons.current) {
      if (buttons.current[button]) {
        buttons.current[button].removeAttribute('disabled');
      }
    }
  }, 1000);
};

const setToken = (currentUser) => {
  if (currentUser) {
    if (currentUser.token) {
      return currentUser.token;
    }
  }
};

const setTenantId = (currentUser) => {
  if (currentUser) {
    if (currentUser.tenantId) {
      return currentUser.tenantId;
    }
  }
}

const setUserId = (currentUser) => {
  if (currentUser) {
    if (currentUser.userId) {
      return currentUser.userId;
    }
  }
}

const setUserRoles = (currentUser) => {
  if (currentUser) {
    if (currentUser.roles) {
      return currentUser.roles;
    }
  }
}

const googleReCaptchaSiteKey = () => {
  return "6LcJRjYpAAAAAPegT5D1Ez1Ud2a6Ve5G4yoWFino";
}

// Remove 'Temperature Question' category
const filteredCategories = (responseData, categoryType, selection = true) => {
  const filteredCategories = responseData.filter((obj) => {
    if (selection) {
      return obj.categoryType === categoryType;
    } else {
      return obj.categoryType < categoryType;
    }
  });

  return filteredCategories;
};

const questionCategoryModifier = (category) => {
  let className;

  switch (category.id) {
    case '4af58c57-cbe4-499e-99ec-fb33c45c1b98':
      className = 'change';
      break;
    case '7185abba-d609-4071-b6b9-6e199c475e38':
      className = 'relationships';
      break;
    case '8d0b0089-2cd2-4c4f-97ff-588710cba91d':
      className = 'demands';
      break;
    case '9608c2cc-6822-4a22-9ebf-4e5d7cab50f3':
      className = 'role';
      break;
    case 'bae25492-5633-48ec-b04f-69f2bc9bc43b':
      className = 'peer-support';
      break;
    case 'bb844672-2318-4a9f-b9ac-354f535c7dea':
      className = 'management-support';
      break;
    case 'ee048b5c-97b2-4f1e-bf90-0da6169729f8':
      className = 'control';
      break;
    default:
      className = category.id;
  }

  return className;
};

export { default as DluchApi } from '../settings/DluchApi';
export { default as FlashMessage } from '../components/FlashMessage';
export { default as Header } from '../shared/Header';
export { default as ProgressBar } from '../components/ProgressBar';
export { default as ErrorRender } from '../components/ErrorRender';
export { Flashable } from '../helpers/Flashable';
export {
  disableFormButton,
  enableFormButton,
  setToken,
  setTenantId,
  setUserId,
  setUserRoles,
  filteredCategories,
  questionCategoryModifier,
  googleReCaptchaSiteKey
};
