// Frontend
import { Grid, Cell } from 'react-foundation';

const ErrorRender = ({ object }) => {
  return (
    <>
      {object && (
        <div className="grid-container">
          <Grid className="grid-padding-x align-justify">
            <Cell small={12}>
              <div className="flash-message flash-message--alert">
                An error occurred: <b>{object}</b>
              </div>
            </Cell>
          </Grid>
        </div>
      )}
    </>
  );
};

export default ErrorRender;
