// Backend
import React from 'react';
import axios from 'axios';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality
import { useNavigate } from 'react-router-dom';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  Flashable,
  ProgressBar,
  disableFormButton,
  enableFormButton,
  googleReCaptchaSiteKey
} from '../../constants/SharedComponents';

import ReCAPTCHA from 'react-google-recaptcha';

export function PasswordResetsNew() {
  // API
  const redirectTo = '/password-resets';
  const navigate = useNavigate();

  // Objects
  const [loading, setLoading] = React.useState(false);
  const [flashMessageObject, setFlashMessageObject] = React.useState({});
  const [inputs, setInputs] = React.useState({});
  const [capVal, setCapVal] = React.useState(null);
  const basicFormButtons = React.useRef([]);
  const googleSiteKey = googleReCaptchaSiteKey();

  // Functions
  function createSubmission(formInputs) {
    if (formInputs.agree_to_terms_and_privacy_policy !== '1') {
      setFlashMessageObject(
        Flashable('Please agree that the email provided is for your account', 'alert', false),
      );
      enableFormButton(basicFormButtons);
      setLoading(false);
    } else {
      setFlashMessageObject({});

      const Api = new DluchApi('PasswordResetsNew');
      let requestPayload = { email: formInputs.email_address };

      axios
        .post(Api.ApiRequestUrl(), requestPayload, { timeout: 30000 })
        .then(() => {
          navigate(redirectTo);
        })
        .catch((error) => {
          if (error.response?.data) {
            setFlashMessageObject(Flashable(error.response.data.errorDesc, 'alert', false));
          } else {
            setFlashMessageObject(Flashable(error.message, 'alert', false));
          }

          enableFormButton(basicFormButtons);
          setLoading(false);
        });
    }
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    disableFormButton(basicFormButtons);
    createSubmission(inputs);
  };

  // Content
  return (
    <>
      <ProgressBar loading={loading} />

      <Header />

      <div className="grid-container">
        <Grid className="grid-padding-x align-justify">
          <Cell small={12} large={6}>
            <div className="page-content">
              <h1 className="page-content__title">
                Welcome to FWD Together. Ready for your conversation?
              </h1>

              <div className="page-content__styles">
                <p>
                  If you haven't already, you can sign-up to the service with your work email using
                  the signup form. If you aren't sure, speak to your line manager who may be able to
                  assist with this.
                </p>

                <p>Funded by DLUHC Local Digital Fund</p>
              </div>
            </div>
          </Cell>

          <Cell small={12} large={5}>
            <div className="basic-form basic-form--background">
              <h2 className="basic-form__title">Forgotten Password</h2>

              <div className="basic-form__styles">
                <p>
                  We just need a few more details from you and we'll have you back up and running...
                </p>
              </div>

              <FlashMessage object={flashMessageObject} columnWrap={false} />

              <form className="basic-form" onSubmit={handleSubmit}>
                <div className="basic-form__field">
                  <label
                    htmlFor="email_address"
                    className="basic-form__label  basic-form__label--sr-only"
                  >
                    Email address:
                  </label>

                  <input
                    className="basic-form__input"
                    type="text"
                    name="email_address"
                    id="email_address"
                    data-testid="email_address"
                    value={inputs.email_address || ''}
                    onChange={handleChange}
                    required={true}
                    placeholder="Email address (example@hullcitycouncil.gov.uk)"
                  />
                </div>

                <div className="basic-form__field">
                  <Grid>
                    <Cell large={1}>
                      <input
                        className="basic-form__input"
                        type="checkbox"
                        name="agree_to_terms_and_privacy_policy"
                        id="agree_to_terms_and_privacy_policy"
                        data-testid="agree_to_terms_and_privacy_policy"
                        value={inputs.agree_to_terms_and_privacy_policy || '1'}
                        onChange={handleChange}
                      />
                    </Cell>
                    <Cell large={11}>
                      <label htmlFor="agree_to_terms_and_privacy_policy" className="basic-form__label">
                        By ticking this box I agree that this account belongs to me and all the
                        information provided is correct.
                      </label>
                    </Cell>
                  </Grid>
                </div>

                <ReCAPTCHA
                  sitekey={googleSiteKey}
                  onChange={(val) => setCapVal(val)}
                  style={{ paddingBottom: 6 }}
                />

                <div className="basic-form__actions">
                  <input
                    disabled={!capVal}
                    value="Reset Password"
                    type="submit"
                    className="basic-form__button"
                    id="page-form-button--submit"
                    ref={(ref) => (basicFormButtons.current[0] = ref)}
                  />
                </div>
              </form>
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  );
}
