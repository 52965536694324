// Frontend
import Modal from 'react-modal';
import { Grid, Cell } from 'react-foundation';
import { InfoCircleFill, PlusCircle, XCircle } from 'react-bootstrap-icons';
import '../stylesheets/components/forms/Categories.css';

// Functionality
import { questionCategoryModifier } from '../constants/SharedComponents';
import QuestionCategoryContent from './QuestionCategoryContent';

// Configurations
Modal.setAppElement('#root');

const QuestionCategory = ({
  questionCategory,
  openModal,
  currentModal,
  afterOpenModal,
  closeModal,
  submissionUnlocked,
  handleChange,
  questionResultsContent,
  deselectCategory,
  selectCategory,
  categoryFormButtons,
  selectedCategories,
  showSummary,
  showNotes,
}) => {
  return (
    <>
      {!submissionUnlocked && (
        <div className="category-form-field-wrap">
          <div
            className={`category-form-field category-form-field--${questionCategoryModifier(
              questionCategory,
            )}`}
          >
            <p
              className={`category-form-field__title category-form-field__title--${questionCategoryModifier(
                questionCategory,
              )}`}
            >
              {questionResultsContent(questionCategory.id, 'optionalCategoryName')
                ? questionResultsContent(questionCategory.id, 'optionalCategoryName')
                : questionCategory.name}
            </p>

            {showSummary && (
              <>
                <div className="category-form-field__styles category-form-field__styles--selected">
                  <p>
                    {questionCategory.description}{' '}
                    <button
                      onClick={openModal}
                      data-modal-id={`question-category-content--${questionCategory.id}`}
                      className="category-form-field__link"
                      id={`page-function-button--view-${questionCategory.id}`}
                    >
                      More
                    </button>
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {submissionUnlocked && questionCategory.selfGuided && selectCategory && (
        <>
          <div className="category-form-field-wrap">
            <div
              className={`category-form-field category-form-field--${questionCategoryModifier(
                questionCategory,
              )}`}
            >
              <label
                className="category-form-field__label category-form-field__label--self-guided"
                htmlFor={'optionalCategoryName--' + questionCategory.id}
              >
                <b>Personalised topic</b> (Different to the options above)
              </label>

              <input
                id={'optionalCategoryName--' + questionCategory.id}
                name={'optionalCategoryName--' + questionCategory.id}
                onChange={handleChange}
                placeholder="Add your own topic title"
                defaultValue={questionResultsContent(questionCategory.id, 'optionalCategoryName')}
                className="basic-form__input category-form-field__input category-form-field__input--self-guided"
              />

              <div className="text-right">
                <button
                  className="category-form-field__button"
                  data-category-id={questionCategory.id}
                  onClick={selectCategory}
                  ref={(ref) =>
                    (categoryFormButtons.current['deselected' + questionCategory.id] = ref)
                  }
                  style={{ display: selectedCategories.length === 3 ? 'none' : 'inline-block' }}
                  id={`page-function-button--add-${questionCategory.id}`}
                >
                  Choose this topic
                  <PlusCircle
                    size={21}
                    className="category-form-field__button-icon category-form-field__button-icon--select util--hide-icon"
                  />
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {submissionUnlocked && questionCategory.selfGuided && deselectCategory && (
        <>
          <div className="category-form-field-wrap">
            <div
              className={`category-form-field category-form-field--${questionCategoryModifier(
                questionCategory,
              )}`}
            >
              <Grid className="grid-padding-x">
                <Cell small={12} large={8}>
                  <p
                    className={`category-form-field__title category-form-field__title--${questionCategoryModifier(
                      questionCategory,
                    )}`}
                  >
                    {questionResultsContent(questionCategory.id, 'optionalCategoryName')
                      ? questionResultsContent(questionCategory.id, 'optionalCategoryName')
                      : questionCategory.name}
                  </p>

                  {showSummary && (
                    <>
                      <div className="category-form-field__styles category-form-field__styles--selected">
                        <p>
                          <button
                            onClick={openModal}
                            data-modal-id={`question-category-content--${questionCategory.id}`}
                            className="category-form-field__link"
                            id={`page-function-button--view-${questionCategory.id}`}
                          >
                            More
                          </button>
                        </p>
                      </div>
                    </>
                  )}
                </Cell>
                <Cell small={12} large={4} className="text-right">
                  <button
                    data-category-id={questionCategory.id}
                    onClick={deselectCategory}
                    ref={(ref) =>
                      (categoryFormButtons.current['selected' + questionCategory.id] = ref)
                    }
                    id={`page-function-button--remove-${questionCategory.id}`}
                  >
                    <span className="category-form-field__link">Remove</span>
                    <XCircle
                      size={16}
                      className="category-form-field__button-icon category-form-field__button-icon--deselect util--hide-icon"
                    />
                  </button>
                </Cell>
              </Grid>
            </div>
          </div>
        </>
      )}

      {submissionUnlocked && !questionCategory.selfGuided && deselectCategory && (
        <>
          <div className="category-form-field-wrap">
            <div
              className={`category-form-field category-form-field--selected category-form-field--${questionCategoryModifier(
                questionCategory,
              )}`}
            >
              <Grid className="grid-padding-x">
                <Cell small={12} large={7}>
                  <span
                    className={`category-form-field__title category-form-field__title--${questionCategoryModifier(
                      questionCategory,
                    )}`}
                  >
                    {questionResultsContent(questionCategory.id, 'optionalCategoryName')
                      ? questionResultsContent(questionCategory.id, 'optionalCategoryName')
                      : questionCategory.name}
                  </span>
                </Cell>
                <Cell small={12} large={5} className="text-right">
                  <button
                    data-category-id={questionCategory.id}
                    onClick={deselectCategory}
                    ref={(ref) =>
                      (categoryFormButtons.current['selected' + questionCategory.id] = ref)
                    }
                    id={`page-function-button--remove-${questionCategory.id}`}
                  >
                    <span className="category-form-field__link">Remove</span>
                    <XCircle
                      size={16}
                      className="category-form-field__button-icon category-form-field__button-icon--deselect util--hide-icon"
                    />
                  </button>
                </Cell>
              </Grid>

              {showSummary && (
                <>
                  <div className="category-form-field__styles category-form-field__styles--selected">
                    <p>
                      {questionCategory.description}{' '}
                      <button
                        onClick={openModal}
                        data-modal-id={`question-category-content--${questionCategory.id}`}
                        className="category-form-field__link"
                        id={`page-function-button--view-${questionCategory.id}`}
                      >
                        More
                      </button>
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}

      {submissionUnlocked && !questionCategory.selfGuided && selectCategory && (
        <>
          <div className="category-form-field-wrap">
            <div
              className={`category-form-field category-form-field--${questionCategoryModifier(
                questionCategory,
              )}`}
            >
              <p
                className={`category-form-field__title category-form-field__title--${questionCategoryModifier(
                  questionCategory,
                )}`}
              >
                <InfoCircleFill className="category-form-field__icon" />

                {questionResultsContent(questionCategory.id, 'optionalCategoryName')
                  ? questionResultsContent(questionCategory.id, 'optionalCategoryName')
                  : questionCategory.name}
              </p>

              {showSummary && (
                <>
                  <div className="category-form-field__styles category-form-field__styles--deselected">
                    <p>
                      {questionCategory.description}{' '}
                      <button
                        onClick={openModal}
                        data-modal-id={`question-category-content--${questionCategory.id}`}
                        className="category-form-field__link"
                        id={`page-function-button--view-${questionCategory.id}`}
                      >
                        More
                      </button>
                    </p>
                  </div>
                </>
              )}

              <div className="text-right">
                <button
                  className="category-form-field__button"
                  data-category-id={questionCategory.id}
                  onClick={selectCategory}
                  ref={(ref) =>
                    (categoryFormButtons.current['deselected' + questionCategory.id] = ref)
                  }
                  style={{ display: selectedCategories.length === 3 ? 'none' : 'inline-block' }}
                  id={`page-function-button--add-${questionCategory.id}`}
                >
                  Choose this topic
                  <PlusCircle
                    size={21}
                    className="category-form-field__button-icon category-form-field__button-icon--select util--hide-icon"
                  />
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <Modal
        isOpen={currentModal === `question-category-content--${questionCategory.id}`}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel={questionCategory.id}
        shouldCloseOnOverlayClick={true}
      >
        <div className="text-right">
          <button
            onClick={closeModal}
            id={`page-function-button--modal-close-${questionCategory.id}`}
          >
            <XCircle size={48} />
          </button>
        </div>

        {!questionCategory.selfGuided && (
          <h2>
            {questionResultsContent(questionCategory.id, 'optionalCategoryName')
              ? questionResultsContent(questionCategory.id, 'optionalCategoryName')
              : questionCategory.name}
          </h2>
        )}

        {questionCategory.selfGuided && (
          <>
            <label
              className="category-form-field__label category-form-field__label--self-guided"
              htmlFor={'optionalCategoryName--' + questionCategory.id}
            >
              <b>Personalised topic</b>
            </label>

            <input
              id={'optionalCategoryName--' + questionCategory.id}
              name={'optionalCategoryName--' + questionCategory.id}
              onChange={handleChange}
              placeholder="Add your own topic title"
              defaultValue={questionResultsContent(questionCategory.id, 'optionalCategoryName')}
              className="basic-form__input category-form-field__input category-form-field__input--self-guided"
            />
          </>
        )}

        <QuestionCategoryContent categoryId={questionCategory.id} />

        {showNotes && (
          <div>
            <div>
              <label htmlFor={'notes--' + questionCategory.id}>Notes</label>

              <textarea
                id={'notes--' + questionCategory.id}
                name={'notes--' + questionCategory.id}
                onChange={handleChange}
                placeholder="Add any additional notes here..."
                defaultValue={questionResultsContent(questionCategory.id, 'notes')}
                className="basic-form__input category-form-modal__input"
              ></textarea>
            </div>

            <div>
              <button
                onClick={closeModal}
                id={`page-function-button--modal-save-and-close-${questionCategory.id}`}
                className="category-form-modal__button"
              >
                Save
              </button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default QuestionCategory;
