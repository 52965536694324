import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import { unstable_HistoryRouter as Router} from 'react-router-dom';

import 'foundation-sites/dist/css/foundation.min.css';

import './stylesheets/layout/Generic.css';
import './stylesheets/components/forms/Shared.css';
import './stylesheets/bootstrap/bootstrap.min.css';

import App from './App';
import history from './helpers/history';
import { LogLevel, PublicClientApplication } from '@azure/msal-browser';


const pca = new PublicClientApplication({
  auth: {
    clientId: 'ac42a9d6-c37e-485c-a3b5-25deade4ca12',
    authority: 'https://login.microsoftonline.com/organizations',
    navigateToLoginRequestUrl: false,
    redirectUri: '/postauthentication',
    postLogoutRedirectUri: '/',
    clientCapabilities: ['CP1']
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPII) => {
        
      },
      logLevel: LogLevel.Error
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router history={history}>
    <App msalInstance={pca} />
  </Router>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
