// Backend
import React from 'react';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Components
import { Header } from '../../constants/SharedComponents';

export function RegistrationsShow() {
  // Content
  return (
    <>
      <Header />

      <div className="grid-container">
        <Grid className="grid-padding-x align-justify">
          <Cell small={12} large={6}>
            <div className="page-content">
              <h1 className="page-content__title page-content__title--with-subtext">
                Welcome to FWD Together
                <span className="page-content__title-subtext">
                  , funded by DLUHC to change the way workplace well being is managed.
                </span>
              </h1>

              <div className="page-content__styles">
                <p>
                  Using this platform will enable you to have proactive, highly confidential
                  conversations with team members and line managers about mental well being.
                </p>

                <p>Funded by DLUHC Local Digital Fund</p>
              </div>
            </div>
          </Cell>

          <Cell small={12} large={5}>
            <div className="basic-form basic-form--background">
              <h2 className="basic-form__title">Thank you for signing up</h2>

              <div className="basic-form__styles">
                <p>
                  You should receive an email asking you to verify your account. Once verified you
                  will then be asked to create a password.
                </p>
                <p>
                  Please check your junk folder just in case the email has been incorrectly
                  filtered.
                </p>
                <p>
                  Once registered you will be able to access your personal dashboard with full
                  instructions on how to start your first conversation.
                </p>
              </div>
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  );
}
