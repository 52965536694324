// Backend
import React from 'react';
import axios from 'axios';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

// Components
import { Header, DluchApi, ProgressBar, setToken } from '../../constants/SharedComponents';
import useCurrentUser from '../../hooks/useCurrentUser';
import { useMsal } from '@azure/msal-react';

export function SessionsDestroy() {
  // API
  const redirectTo = '/sessions/new';
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  // Objects
  const [onCurrentUserChange, setonCurrentUserChange] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const { instance } = useMsal();

  const createSubmission = React.useCallback(() => {
    setLoading(true);

    const token = setToken(currentUser);
    const headerParams = { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 };

    let Api = new DluchApi('SessionsDestroy');
    let requestPayload;

    requestPayload = { refreshToken: currentUser.refreshToken };

    axios
      .post(Api.ApiRequestUrl(), requestPayload, headerParams)
      .then(() => {
        if(currentUser.isUsingSSO) {
          instance.logoutRedirect();
        }
      })
      .catch((error) => {
      });

    secureLocalStorage.removeItem('userDetails');
    setonCurrentUserChange(1);
    setLoading(false);
  }, [currentUser, instance]);


  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (currentUser && currentUser.token) {
      createSubmission();
    }
  }, [currentUser, createSubmission, navigate, redirectTo]);

  // Content
  return (
    <>
      <ProgressBar loading={loading} />
      <Header onCurrentUserChange={onCurrentUserChange} />

      <div className="grid-container">
        <Grid className="grid-padding-x align-justify">
          <Cell small={12} large={6}>
            <div className="page-content">
              <h1 className="page-content__title">
                Welcome to FWD Together. Ready for your conversation?
              </h1>

              <div className="page-content__styles">
                <p>
                  If you haven't already, you can sign-up to the service with your work email using
                  the signup form. If you aren't sure, speak to your line manager who may be able to
                  assist with this.
                </p>

                <p>Funded by DLUHC Local Digital Fund</p>
              </div>
            </div>
          </Cell>

          <Cell small={12} large={5}>
            <div className="basic-form basic-form--background">
              {!onCurrentUserChange && (
                <>
                  <div className="basic-form__styles">
                    <p>Please wait...</p>
                  </div>
                </>
              )}

              {onCurrentUserChange && (
                <>
                  <h2 className="basic-form__title">Logged out</h2>

                  <div className="basic-form__styles">
                    <p>You have successfully logged out.</p>
                  </div>
                </>
              )}
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  );
}
