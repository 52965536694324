import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import RefreshToken from './RefreshToken';
import secureLocalStorage from 'react-secure-storage';

const User = function () {
  const localStorageToken = secureLocalStorage.getItem('userDetails');

  this.valid = function () {
    const tokenable = new RefreshToken();
    let validToken = tokenable.tokenValid();

    return this.details() && validToken;
  };

  this.details = function () {
    if (localStorageToken) {
      return JSON.parse(localStorageToken);
    }
  };

  this.role = function () {
    if (this.details()) {
      return this.details().roles;
    }
  };
};

const PrivateRoute = ({ Component, userRoleRequired, redirectPath }) => {
  const user = new User();

  const rolePermitted = function () {
    return user.role() === userRoleRequired;
  };

  const accessPermitted = function () {
    let accessGranted = false;

    if (user.valid()) {
      accessGranted = true;

      if (userRoleRequired) {
        accessGranted = rolePermitted();
      }
    }

    return accessGranted;
  };

  const flashMessage = function () {
    let message = 'Please sign in.';

    if (user.details() && !rolePermitted()) {
      message = 'You are not authorised to access this area.';
    }

    return message;
  };

  const renderObject = function () {
    if (Component) {
      return <Component />;
    }

    return <Outlet />;
  };

  
  const awaitingObject = function () {
    if (!user.details()) {
      return <Navigate to={redirectPath} state={flashMessage()}/>;
    }
  };

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return accessPermitted() ? renderObject() : awaitingObject();
};

export default PrivateRoute;
