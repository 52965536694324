// Backend
import React from 'react';
import axios from 'axios';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality
import { useLocation, Link } from 'react-router-dom';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  ProgressBar,
  ErrorRender,
  setToken,
  filteredCategories,
} from '../../constants/SharedComponents';
import useCurrentUser from '../../hooks/useCurrentUser';

export function ResourcesManagerMeetingPrep() {
  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();
  const token = setToken(currentUser);
  const headerParams = { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 };

  // Objects
  const [flashMessageObject, setFlashMessageObject] = React.useState({});
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  // Functions
  function retryFetch() {
    setLoading(true);
    setError(false);
    setData(null);
    getData();
  }

  const getData = async () => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionsCategoriesIndex');
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.categories;
      let allCategories = filteredCategories(responseData, 1, false);
      setData(allCategories);
      setError(false);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (currentUser && currentUser.token) {
      retryFetch();
    }

    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  return (
    <>
      <ProgressBar loading={loading} />
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />
      <ErrorRender object={error} />

      <div className="grid-container">
        <Grid className="grid-padding-x align-justify">
          <Cell small={12}>
            <div className="page-content">
              <h1 className="page-content__title">Manager Meeting Preparation</h1>
              <div className="page-content__styles">
                <p>Welcome to your meeting preparation</p>
                <p>
                  To help you prepare for the coming conversation, the following activity is
                  recommended. This activity is designed to help you:
                </p>
                <ul>
                  <li>Better understand the challenges facing your team member</li>
                  <li>Explore some avenues for offering support to your team member</li>
                </ul>
                <p>
                  Hopefully, your team member will come to this meeting with key points to discuss.
                  This preparation is intended to guide you through a process to develop shared
                  understanding about the issues your team member is facing, so that you work
                  together to make a forward plan that addresses them.
                </p>
                <p>
                  Your planning can be structured according to areas of work characteristics. Below
                  is a non-exhaustive list of areas to explore for each work characteristic. For
                  each area, there are some guiding questions to help you to reflect on the
                  situation of your team member. Keep in mind the specific situation of your team
                  member to reflect on these questions, but bear in mind the issue might sometimes
                  be a characteristic of your team as a whole.
                </p>
                <ul>
                  {data &&
                    data.map((questionCategory) => (
                      <li key={'selected-category-' + questionCategory.id}>
                        <Link to={`/resources/${questionCategory.id}`}>
                          {questionCategory.name}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  );
}
