import { useCallback } from 'react';
import { Handle, Position } from 'reactflow';

function ControlNode({ data, isConnectable }) {
  const onChange = useCallback(
    (e) => {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight + 5}px`;
      data.textAreaHeight = e.target.scrollHeight + 5;
      data.value = e.target.value;
    },
    [data],
  );

  return (
    <div className="conversation-canvas-node conversation-canvas-node--category">
      <Handle
        type="source"
        position={Position.Top}
        id="control-target"
        isConnectable={isConnectable}
        className="conversation-canvas-node__handle conversation-canvas-node__handle--category"
      />
      <div className="conversation-canvas-node__title conversation-canvas-node__title--category">
        {data.title}
      </div>
      <div className="conversation-canvas-node-content">
        {!data.hasOwnProperty('locked') && (
          <div>
            <textarea
              className="conversation-canvas-node-content__input nodrag"
              id="text"
              name="text"
              maxLength="500"
              onChange={onChange}
              defaultValue={data.value}
              style={{ height: data.textAreaHeight ? data.textAreaHeight + 'px' : 'auto' }}
            ></textarea>
          </div>
        )}
        {data.hasOwnProperty('locked') && (
          <>
            <p>{data.value}</p>
          </>
        )}
      </div>

      <Handle
        type="source"
        position={Position.Left}
        id="control-source-left"
        isConnectable={isConnectable}
        className="conversation-canvas-node__handle conversation-canvas-node__handle--category"
      />
      <Handle
        type="source"
        position={Position.Right}
        id="control-source-right"
        isConnectable={isConnectable}
        className="conversation-canvas-node__handle conversation-canvas-node__handle--category"
      />

      <Handle
        type="source"
        position={Position.Bottom}
        id="control-source-bottom"
        isConnectable={isConnectable}
        className="conversation-canvas-node__handle conversation-canvas-node__handle--category"
      />
    </div>
  );
}

export default ControlNode;
