// Backend
import React from 'react';
import axios from 'axios';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  ProgressBar,
  ErrorRender,
  setToken,
} from '../../../constants/SharedComponents';

import useCurrentUser from '../../../hooks/useCurrentUser';

export function CanvasesCategoriesNew() {
  // Params
  const { conversationId } = useParams();
  const navigate = useNavigate();

  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();
  const token = setToken(currentUser);
  const headerParams = { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 };
  const redirectTo = '/canvases/' + conversationId + '/categories/';

  // Objects
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [flashMessageObject, setFlashMessageObject] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  // Functions
  const getData = async () => {
    setLoading(true);

    try {
      let Api = new DluchApi('ConversationsShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.conversation;
      setData(responseData);
      setError(false);

      if (responseData.employeePrepStatus === 2 || responseData.isCanvasData) {
        redirectToCanvas();
      }
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const redirectToCanvas = async () => {
    setLoading(true);
    navigate(`${redirectTo}1/edit`);
  };

  const beginCanvasContent = async () => {
    navigate(`${redirectTo}1/edit`);
  };

  function retryFetch() {
    setLoading(true);
    setError(false);
    setData(null);
    getData();
  }

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (currentUser && currentUser.token) {
      retryFetch();
    }

    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  return (
    <>
      <ProgressBar loading={loading} />
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />
      <ErrorRender object={error} />

      {data && data.employeePrepStatus === 2 && (
        <>
          <div className="grid-container">
            <Grid className="grid-padding-x">
              <Cell small={12}>
                <p>Please wait...</p>
              </Cell>
            </Grid>
          </div>
        </>
      )}

      {data && data.employeePrepStatus !== 2 && (
        <>
          <div className="grid-container">
            <Grid className="grid-padding-x">
              <Cell small={12} large={8}>
                <div className="page-content">
                  <h1 className="page-content__title">Conversation prep not complete</h1>

                  <div className="page-content__styles">
                    <p>
                      The Team Member has not completed their prep for this conversation. Starting
                      this conversation will mean that the Team Member cannot compete their prep.
                      Would you like to continue?
                    </p>
                    <Link
                      to={`/conversations`}
                      className="page-content__button"
                      id="page-navigation-button--go-back"
                    >
                      Go back
                    </Link>{' '}
                    <button
                      onClick={beginCanvasContent}
                      className="page-content__button"
                      id="page-form-button--continue"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </Cell>
            </Grid>
          </div>
        </>
      )}
    </>
  );
}
