// Backend
import React from 'react';
import axios from 'axios';
import history from '../../../helpers/history';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality
import { useParams, useLocation, useNavigate } from 'react-router-dom';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  ProgressBar,
  disableFormButton,
  ErrorRender,
  setToken,
  filteredCategories,
  Flashable,
} from '../../../constants/SharedComponents';
import useCurrentUser from '../../../hooks/useCurrentUser';

export function ConversationsScreeningsNew() {
  // Params
  const { conversationId } = useParams();
  const navigate = useNavigate();

  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();
  const token = setToken(currentUser);
  const headerParams = { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 };

  // Objects
  const [flashMessageObject, setFlashMessageObject] = React.useState({});
  const [inputs, setInputs] = React.useState({});
  const [data, setData] = React.useState(null);
  const [categoryData, setCategoryData] = React.useState(null);
  const [questionData, setQuestionData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [formChanged, setFormChanged] = React.useState(false);
  const [pageNavigation, setPageNavigation] = React.useState({});
  const basicFormButtons = React.useRef([]);

  // Functions
  function processQuestionScores(formInputs) {
    let questionScores = [];
    let index = 0;

    // @see https://stackoverflow.com/a/921808
    for (const key in formInputs) {
      if (!inputs.hasOwnProperty(key)) {
        continue;
      }

      // @see https://stackoverflow.com/a/1493071
      let matches = key.split('[');
      let questionId;

      if (matches) {
        questionId = matches[1];
        questionId = questionId.substring(0, questionId.length - 1);
      }

      let obj = inputs[key];

      for (const prop in obj) {
        if (!obj.hasOwnProperty(prop)) {
          continue;
        }

        questionScores[index] = {
          conversationId: conversationId,
          questionId: questionId,
          score: parseInt(obj[prop]),
        };
      }

      index++;
    }

    return questionScores;
  }

  function createSubmission(formInputs) {
    const redirectTo = '/conversations/' + conversationId + '/team-member-prep/routes';

    let questionScores = processQuestionScores(formInputs);
    let requestPayload;

    if (data && data.id) {
      requestPayload = {
        questionScores: [questionScores],
      };
    }

    localStorage.setItem('questionScores', JSON.stringify(requestPayload));

    setFormChanged(false);
    setPageNavigation({
      to: redirectTo,
      options: {},
    });
  }

  function retryFetch() {
    setLoading(true);
    setError(false);
    setData(null);
    getData();
  }

  const getData = async () => {
    try {
      let Api = new DluchApi('ConversationsShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.conversation;
      setData(responseData);
      setError(false);
      getCategoryData();
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const getCategoryData = async () => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionsCategoriesIndex', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content;
      let result = filteredCategories(responseData.categories, 3);

      setCategoryData(result);
      setError(false);
      getQuestionData(result[0]);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const getQuestionData = async (temperatureCategory) => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionsIndex', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content;
      let result = responseData.sortedQuestions.filter(
        (obj) => obj.categoryId === temperatureCategory.id,
      );

      setQuestionData(result);
      setError(false);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (currentUser && currentUser.token) {
      retryFetch();
    }

    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  React.useEffect(() => {
    let unblock;
    if (formChanged) {
      unblock = history.block((tx) => {
        let url = tx.location.pathname;

        if (
          window.confirm(
            `You have unsaved changes. If you navigate away from this page, these changes will be lost.`,
          )
        ) {
          unblock();
          tx.retry();
        }
      });
    }

    if (!formChanged && pageNavigation) {
      navigate(pageNavigation.to, pageNavigation.options);
    }

    return () => {
      if (typeof unblock === 'function') {
        unblock();
      }
    };
  }, [formChanged, pageNavigation]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (Object.keys(inputs).length !== 0) {
      setFlashMessageObject({});
      disableFormButton(basicFormButtons);
      setLoading(true);
      createSubmission(inputs);
    } else {
      setFlashMessageObject(Flashable('Please answer the required question.', 'alert', false));
      return false;
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    setFormChanged(true);
  };

  return (
    <>
      <ProgressBar loading={loading} />
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />
      <ErrorRender object={error} />

      {data && categoryData && questionData && (
        <>
          <div className="grid-container">
            <Grid className="grid-padding-x align-justify">
              <Cell small={12}>
                <div className="page-content">
                  <h1 className="page-content__title">How are things going in your work?</h1>

                  <div className="page-content__styles">
                    <p>
                      Please consider the following question and answer as you feeling at this
                      moment.
                    </p>
                  </div>
                </div>

                {!data.isUserManager && (
                  <>
                    <form>
                      <div className="basic-form basic-form--background">
                        {questionData.map((question, questionIndex) => {
                          return (
                            <div
                              className="basic-form__field basic-form__field"
                              key={'question-' + question.id}
                            >
                              <p className="basic-form__title">{question.questionText}</p>

                              {JSON.parse(question.questionOptions).map(
                                (questionOption, optionIndex) => {
                                  return (
                                    <div
                                      className="basic-form__field basic-form__field--inline"
                                      key={
                                        'question-' +
                                        question.id +
                                        '-response-' +
                                        questionOption.OptionScore
                                      }
                                    >
                                      <input
                                        type="radio"
                                        name={'question_score[' + question.id + '][option_score]'}
                                        value={questionOption.OptionScore}
                                        onChange={handleChange}
                                        className="basic-form__radio-button"
                                        id={
                                          'util--question-' +
                                          questionIndex +
                                          '-response-' +
                                          optionIndex
                                        }
                                        required
                                      />

                                      <label
                                        htmlFor={
                                          'util--question-' +
                                          questionIndex +
                                          '-response-' +
                                          optionIndex
                                        }
                                      >
                                        {questionOption.OptionText}
                                      </label>
                                    </div>
                                  );
                                },
                              )}
                            </div>
                          );
                        })}
                      </div>

                      <input
                        value="Next Step"
                        type="submit"
                        className="basic-form__button basic-form-route-choice__button"
                        onClick={handleSubmit}
                        ref={(ref) => (basicFormButtons.current[0] = ref)}
                        id="page-form-button--submit-guided"
                      />
                    </form>
                  </>
                )}
              </Cell>
            </Grid>
          </div>
        </>
      )}
    </>
  );
}
