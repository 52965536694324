// Backend
import React from 'react';
import axios from 'axios';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality
import { useParams, useLocation, Link } from 'react-router-dom';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  ProgressBar,
  ErrorRender,
  setToken,
} from '../../../../constants/SharedComponents';
import useCurrentUser from '../../../../hooks/useCurrentUser';

export function ConversationsScreeningRoutesShow() {
  // Params
  const { conversationId } = useParams();

  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();
  const token = setToken(currentUser);
  const headerParams = { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 };

  // Objects
  const [flashMessageObject, setFlashMessageObject] = React.useState({});
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  // Functions

  function retryFetch() {
    setLoading(true);
    setError(false);
    setData(null);
    getData();
  }

  const getData = async () => {
    try {
      let Api = new DluchApi('ConversationsShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.conversation;
      setData(responseData);
      setError(false);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (currentUser && currentUser.token) {
      retryFetch();
    }

    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  return (
    <>
      <ProgressBar loading={loading} />
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />
      <ErrorRender object={error} />

      {data && (
        <>
          <div className="grid-container">
            <Grid className="grid-padding-x align-justify">
              <Cell small={12}>
                <div className="page-content">
                  <h1 className="page-content__title">Choose your route</h1>

                  <div className="page-content__styles">
                    <p>
                      To help you to prepare in a way that best suits you, you have two slightly
                      different options. It doesn't matter which of these options you pick, both
                      allow you the opportunity to feedback to your manager on the issues you'd like
                      to discuss.
                    </p>
                  </div>

                  <Grid className="grid-padding-x align-justify">
                    <Cell small={12} large={5}>
                      <div className="basic-form basic-form--background">
                        <p className="basic-form__subtitle">Guided route</p>

                        <p>
                          For the curious who are interested in considering their work
                          characteristics using insightful data reflecting their experiences at
                          work:
                        </p>

                        <p>This involves</p>

                        <ul>
                          <li>
                            Completion of a well-known and scientifically validated survey that asks
                            you to score various aspects of your work
                          </li>
                          <li>
                            Summary of your results, providing a list of three priorities based on
                            your responses
                          </li>
                          <li>
                            Opportunity to learn more about your results and consider how well they
                            reflect your current challenges
                          </li>
                        </ul>

                        <Link
                          to={`/conversations/${conversationId}/full-questions`}
                          className="page-content__button"
                          id="page-navigation-button--start"
                        >
                          Next Step
                        </Link>
                      </div>
                    </Cell>

                    <Cell small={12} large={2} className="text-center">
                      <div className="basic-form">
                        <p className="basic-form__subtitle">or</p>
                      </div>
                    </Cell>
                    <Cell small={12} large={5}>
                      <div className="basic-form basic-form--background">
                        <p className="basic-form__subtitle">Self-reflection</p>

                        <p>
                          For those who don't feel the need for structured guidance, there is a more
                          autonomous route that allows you time to reflect. This involves:
                        </p>

                        <p>This involves</p>

                        <ul>
                          <li>
                            Presentation of the most common characteristics related to employee
                            mental health in the workplace
                          </li>
                          <li>Reflection time around the characteristics presented to you</li>
                        </ul>

                        <Link
                          to={`/conversations/${conversationId}/categories/edit`}
                          className="page-content__button"
                          id="page-navigation-button--start"
                        >
                          Next Step
                        </Link>
                      </div>
                    </Cell>
                  </Grid>
                </div>
              </Cell>
            </Grid>
          </div>
        </>
      )}
    </>
  );
}
