// Backend
import React from 'react';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality
import { useLocation, Link } from 'react-router-dom';

// Components
import { Header, FlashMessage } from '../../constants/SharedComponents';
import useCurrentUser from '../../hooks/useCurrentUser';

export function ResourcesManagerRoleOfResources() {
  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();

  // Objects
  const [flashMessageObject, setFlashMessageObject] = React.useState({});

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  return (
    <>
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />

      <div className="grid-container">
        <Grid className="grid-padding-x align-justify">
          <Cell small={12}>
            {
              // subnav here
            }
            <div className="page-content">
              <div className="page-content__header">
                <Link to="/resources/manager-training" className="page-content__button">
                  Back
                </Link>
              </div>
              <h1 className="page-content__title">Role of resources</h1>
              <div className="page-content__styles">
                <p>
                  You've been through the main sources of stress in the workplace (common work
                  characteristics presented). The focus of this conversation is for you to work
                  together to think about the ways these stress risks can be managed or
                  controlled.&nbsp; You can think together about what to put in place to help your
                  team member. This represents a{' '}
                  <strong>preventative approach to managing wellbeing</strong> at work. To support
                  your thinking about this, we need to consider <strong>resources</strong> and how
                  they have been highlighted in the literature as a way of alleviating the burden of
                  stressors.{' '}
                  <strong>
                    Resources are the aspects of the job that help to achieve work goals, meet the
                    demands or stimulate personal development. They help us to manage or control the
                    risks of challenging work characteristics.{' '}
                  </strong>
                  Increasing the resources your team member can rely on will be helpful for them to
                  successfully deal with a stressful situation or to better react when facing
                  sources of stress.
                </p>
                <p>Various forms of resources can be provided:</p>
                <ul>
                  <li>Social resources (e.g., advice, feedback, social support)</li>
                  <li>
                    Structural job resources (e.g., time, autonomy/control through participative
                    decision-making, working flexibility, adequate equipment and tools, rewards)
                  </li>
                </ul>
                <p>While others can be developed, such as:</p>
                <ul>
                  <li>
                    Individual resources (e.g., skills and knowledge, self-confidence in one's own
                    capacities).
                  </li>
                </ul>
                <p>
                  These are the most common resources identified in the literature but this list is
                  not exhaustive. You may like to discuss with your team member what kind of
                  resources seem the most appropriate to control this stress risks in their
                  situation, as well as whether and how you can provide or develop them. Although
                  resources have to be designed to align with your team member situation, here is a
                  summary of a recent scientific article (Ong &amp; Johnson, 2021) that may guide
                  you in the choice of resources to develop: <br />
                  Results pointed to three types of work situations leading to exhaustion:
                </p>
                <ul>
                  <li>
                    workers having low social support (comprising peer and management support)
                  </li>
                  <li>
                    workers having at the same time a high workload and high levels of emotional
                    demands
                  </li>
                  <li>workers having at the same time a high workload and low control</li>
                </ul>
                <p>
                  In practical terms, the management of stress risks is likely to benefit from very
                  specific controls that help the team member with their unique challenge.&nbsp; For
                  example, a team member might find that workload is unmanageable at one particular
                  point in the year, and the key to a good conversation will be the joint
                  exploration of what precedes this particular concern and what is the impact.
                </p>
                <p>
                  By exploring and understanding their unique challenge, you will be able to
                  generate meaningful ideas of about this stress risk can be managed.&nbsp;
                </p>
              </div>
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  );
}
