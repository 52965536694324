import React, { Component } from 'react';
import { Grid, Cell } from 'react-foundation';

import PropTypes from 'prop-types';
import zxcvbn from 'zxcvbn';
import '../stylesheets/components/PasswordStrengthMeter.css';

class PasswordStrengthMeter extends Component {
    createPasswordLabel = (result) => {
        switch (result.score) {
            case 0:
                return 'Weak';
            case 1:
                return 'Weak';
            case 2:
                return 'Fair';
            case 3:
                return 'Good';
            case 4:
                return 'Strong';
            default:
                return 'Weak';
        }
    }

    render() {
        const { password } = this.props;
        const testedResult = zxcvbn(password);

        PasswordStrengthMeter.propTypes = {
            password: PropTypes.string
        };

        return (
            <div className="password-strength-meter" style={{ paddingBottom: 10 }}>
                {password && (
                    <Grid>
                        <Cell large={4}>
                            <label
                                className="password-strength-meter-label"
                            >
                                <strong>Password strength:</strong>
                            </label>
                        </Cell>
                        <Cell large={8}>
                            <progress
                                className={`password-strength-meter-progress strength-${this.createPasswordLabel(testedResult)}`}
                                value={testedResult.score}
                                max="4"
                            />
                        </Cell>
                    </Grid>
                )}
            </div>
        );
    }
}

export default PasswordStrengthMeter;