// Backend
import React from 'react';
import axios from 'axios';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality
import { Link, useNavigate } from 'react-router-dom';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  Flashable,
  enableFormButton,
  disableFormButton,
  ProgressBar,
  setToken,
} from '../../constants/SharedComponents';
import useCurrentUser from '../../hooks/useCurrentUser';

const ConversationsNew = () => {
  // API
  const currentUser = useCurrentUser();
  const redirectTo = '/conversations';
  const navigate = useNavigate();
  const token = setToken(currentUser);
  const headerParams = { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 };

  // Objects
  const [loading, setLoading] = React.useState(false);
  const [flashMessageObject, setFlashMessageObject] = React.useState({});
  const [inputs, setInputs] = React.useState({});
  const [data, setData] = React.useState(null);
  const [userInvitationPending, setUserInvitationPending] = React.useState(false);
  const [userInvitationSent, setUserInvitationSent] = React.useState(false);
  const basicFormButtons = React.useRef([]);

  // Functions
  function createConversation(formInputs) {
    let Api = new DluchApi('ConversationsNew');

    let requestPayload;

    if (data && data.id) {
      requestPayload = {
        attendeeId: data.id,
        conversationDescription: formInputs.content,
        isOrganiserManager: formInputs.role === 'employer',
      };
    }

    axios
      .post(Api.ApiRequestUrl(), requestPayload, headerParams)
      .then((response) => {
        let conversation = response.data.content.conversation;
        navigate(redirectTo + '/' + conversation.id, {
          state: Flashable(
            'You conversation has been created, the recipient has been notified, and you can now both start your prep.',
            'notice',
          ),
        });
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          setFlashMessageObject(Flashable(error.response.data.errorDesc, 'alert', false));
        } else {
          setFlashMessageObject(Flashable(error.message, 'alert', false));
        }

        enableFormButton(basicFormButtons);
        setLoading(false);
      });
  }

  function findUser(formInputs) {
    let Api = new DluchApi('UsersShow');

    let getHeaderParams = {
      params: { email: formInputs.email_address },
      headers: { Authorization: `Bearer ${token}`, timeout: 30000 },
    };

    axios
      .get(Api.ApiRequestUrl(), getHeaderParams)
      .then((response) => {
        if (response.data.content.user.id === currentUser.userId) {
          setFlashMessageObject(Flashable('You cannot invite yourself.', 'alert', false));
          setUserInvitationPending(false);
          setUserInvitationSent(false);
        } else {
          setData({
            forename: response.data.content.user.firstname,
            surname: response.data.content.user.lastname,
            id: response.data.content.user.id,
            email_address: response.data.content.user.emailAddress,
          });
          setUserInvitationPending(false);
          setUserInvitationSent(false);
        }
      })
      .catch((error) => {
        if (error.response?.data?.errorDesc === "User email is not valid for your organisation's policy") {
          setFlashMessageObject(Flashable(error.response.data.errorDesc, 'alert', false));
          setUserInvitationPending(false);
          setUserInvitationSent(false);
        } else {
          setUserInvitationPending(true);
          setUserInvitationSent(false);
        }
      });
  }

  function inviteUser(formInputs) {
    let Api = new DluchApi('UsersNew');

    let requestPayload = { email: formInputs.email_address };

    axios
      .post(Api.ApiRequestUrl(), requestPayload, headerParams)
      .then((response) => {
        setFlashMessageObject(Flashable(response.data.content.description, 'notice', false));
        setUserInvitationPending(false);
        setUserInvitationSent(true);
      })
      .catch((error) => {
        setFlashMessageObject(Flashable(error.message, 'alert', false));
      });
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    disableFormButton(basicFormButtons);
    createConversation(inputs);
  };

  const findUserSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    findUser(inputs);
    setLoading(false);
  };

  const inviteUserSubmit = React.useCallback((event) => {
    event.preventDefault();
    setLoading(true);
    inviteUser(inputs);
    setLoading(false);
  });

  // Content
  return (
    <>
      <ProgressBar loading={loading} />

      <Header />

      <div className="grid-container">
        <Grid className="grid-padding-x align-justify">
          <Cell small={12}>
            <FlashMessage object={flashMessageObject} columnWrap={false} />

            {!data && !userInvitationPending && !userInvitationSent && (
              <>
                <div className="page-content">
                  <div className="page-content__header">
                    <Link
                      to="/conversations"
                      className="page-content__button"
                      id="page-navigation-button--go-back"
                    >
                      Back to Dashboard
                    </Link>
                  </div>

                  <h1 className="page-content__title">
                    Who would you like to have a conversation with?
                  </h1>

                  <p>
                    Enter an email address to set-up a conversation. If that user isn’t currently
                    registered they will be sent an invitation to register by email.
                  </p>

                  <form className="basic-form" onSubmit={findUserSubmit}>
                    <div className="basic-form__field">
                      <label htmlFor="email_address" className="basic-form__label">
                        Email address:
                      </label>

                      <input
                        className="basic-form__input"
                        type="text"
                        name="email_address"
                        id="email_address"
                        value={inputs.email_address || ''}
                        onChange={handleChange}
                        required={true}
                        placeholder="Email address (example@hullcitycouncil.gov.uk)"
                      />
                    </div>

                    <div className="basic-form__actions">
                      <input
                        value="Find User"
                        type="submit"
                        className="basic-form__button"
                        ref={(ref) => (basicFormButtons.current[0] = ref)}
                        id="page-form-button--complete"
                      />
                    </div>
                  </form>
                </div>
              </>
            )}

            {userInvitationPending && !userInvitationSent && (
              <>
                <div className="page-content">
                  <div className="page-content__header">
                    <Link
                      to="/conversations"
                      className="page-content__button"
                      id="page-navigation-button--go-back"
                    >
                      Go back
                    </Link>
                  </div>

                  <h1 className="page-content__title">Invite User</h1>

                  <p>
                    This user isn't registered on the system, click the button below to invite them
                  </p>
                  <p>
                    Please follow your organisations communication policy when sending an invite.
                  </p>

                  <p>
                    <button
                      onClick={inviteUserSubmit}
                      className="page-content__button"
                      id="page-form-button--submit"
                    >
                      Invite user
                    </button>
                  </p>
                </div>
              </>
            )}
            {userInvitationSent && (
              <>
                <div className="page-content">
                  <h1 className="page-content__title">Invite Sent</h1>

                  <Link
                    to="/conversations"
                    className="page-content__button"
                    id="page-navigation-button--go-back"
                  >
                    Go back
                  </Link>
                </div>
              </>
            )}

            {data && (
              <>
                <div className="page-content">
                  <div className="page-content__header">
                    <Link
                      to="/conversations"
                      className="page-content__button"
                      id="page-navigation-button--go-back"
                    >
                      Go back
                    </Link>
                  </div>

                  <h1 className="page-content__title">Create Conversation</h1>
                  <p>
                    Complete the form below to create a conversation with {data.forename}{' '}
                    {data.surname} ({data.email_address})
                  </p>
                  <form className="basic-form" onSubmit={handleSubmit}>
                    <Grid className="grid-padding-x align-justify">
                      <Cell small={12} large={6}>
                        <div className="basic-form__field">
                          <label htmlFor="forename" className="basic-form__label">
                            First name:
                          </label>

                          <input
                            className="basic-form__input"
                            type="text"
                            name="forename"
                            id="forename"
                            value={data.forename}
                            onChange={handleChange}
                            required={true}
                            placeholder="First name"
                          />
                        </div>

                        <div className="basic-form__field">
                          <label htmlFor="surname" className="basic-form__label">
                            Last name:
                          </label>

                          <input
                            className="basic-form__input"
                            type="text"
                            name="surname"
                            id="surname"
                            value={data.surname}
                            onChange={handleChange}
                            required={true}
                            placeholder="Last name"
                          />
                        </div>

                        <div className="basic-form__field">
                          <label htmlFor="email_address" className="basic-form__label">
                            Email address:
                          </label>

                          <input
                            className="basic-form__input"
                            type="text"
                            name="email_address"
                            id="email_address"
                            value={data.email_address}
                            onChange={handleChange}
                            required={true}
                            placeholder="Email address (example@hullcitycouncil.gov.uk)"
                          />
                        </div>

                        <p>My role in the conversation</p>

                        <div className="basic-form__field basic-form__field--inline">
                          <input
                            className="basic-form__input"
                            type="radio"
                            name="role"
                            id="employee"
                            value="employee"
                            onChange={handleChange}
                            required={true}
                          />

                          <label htmlFor="employee" className="basic-form__label">
                            Team Member
                          </label>
                        </div>

                        <div className="basic-form__field basic-form__field--inline">
                          <input
                            className="basic-form__input"
                            type="radio"
                            name="role"
                            id="employer"
                            value="employer"
                            onChange={handleChange}
                            required={true}
                          />

                          <label htmlFor="employer" className="basic-form__label">
                            Manager
                          </label>
                        </div>
                      </Cell>

                      <Cell small={12} large={6}>
                        <p>You have requested to hold a conversation with {data.forename}.</p>

                        <p>
                          You'll need to specify whether your holding this conversation in the
                          capacity as a manager or as a team member. E.g. are you {data.forename}'s
                          line manager? Or are they yours?
                        </p>

                        <p>
                          Once you click 'submit' {data.forename} will receive an email with further
                          instructions on how to prepare for the conversation. You will see the
                          conversation on your dashboard page and will be invited to prepare for the
                          meeting as well.
                        </p>

                        <p>
                          Remember, you will still need to schedule the meeting, FWD Together simply
                          logs the request.
                        </p>
                      </Cell>
                    </Grid>

                    <input value={data.id} name="attendee_id" type="hidden" />

                    <div className="basic-form__actions">
                      <input
                        value="Create Conversation"
                        type="submit"
                        className="basic-form__button"
                        id="page-form-button--submit"
                        ref={(ref) => (basicFormButtons.current[1] = ref)}
                      />
                    </div>
                  </form>
                </div>
              </>
            )}
          </Cell>
        </Grid>
      </div>
    </>
  );
};

export default ConversationsNew;
