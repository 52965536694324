import { useMsal } from "@azure/msal-react"
import { ReactSVG } from "react-svg";
import MSLoginSvg from '../images/components/icons/ms_signin_light.svg'


export const SignInButton = () => {

    const { instance } = useMsal();

    const handleMSSignIn = () => {
        instance.loginRedirect({ scopes: ["user.read"] });
    }

    return (
        <ReactSVG src={MSLoginSvg}
            style={{ width: "210px", "cursor": "pointer" }}
            onClick={handleMSSignIn} />
    )
};