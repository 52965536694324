const QuestionCategoryContent = ({ categoryId }) => {
  const categoryContent = function (categoryId) {
    let content;

    switch (categoryId) {
      case 'ee048b5c-97b2-4f1e-bf90-0da6169729f8':
        content = (
          <div className="page-content">
            <h1 className="page-content__title">What is Control about?</h1>
            <div className="page-content__styles">
              <p>
                Control refers to the extent to which you can decide things about your work. Control
                can have various forms: it can be whether you have discretion on your working
                patterns, whether you can decide how to do things in your work, or whether you can
                make decisions or influence the decisions that affect you. Levels and forms of
                appropriate control can vary greatly in different jobs. For example, your work may
                be highly regulated with fixed safety and compliance procedures. This would result
                in a limited degree of freedom over how you do things or over decisions.
                Alternatively, you may have flexibility over working hours but limited influence
                over decisions. Yet, having some control helps to buffer the effects of potential
                sources of stress by allowing you to deal with them in the way that best suits you.
              </p>
              <p>
                <strong>Some examples demonstrating control over work:</strong>
              </p>
              <ul>
                <li>As long as my objectives are completed, I can work in a way that suits me</li>
                <li>I can handle work problems on my own</li>
                <li>I can freely organise the time I spend working on projects</li>
              </ul>
              <p>
                <strong>
                  You can also have a look at the following items from the questionnaire and put
                  into your notes any that you feel are particularly relevant for you
                </strong>{' '}
              </p>
              <ul>
                <li>I can decide when to take a break</li>
                <li>I have a say in my own work speed</li>
                <li>I have a choice in deciding how I do my work</li>
                <li>I have a choice in deciding what I do at work</li>
                <li>I have some say over the way I work</li>
                <li>My working time can be flexible</li>
              </ul>
              <p>
                <strong>
                  It may help to make some notes if there a particular aspect of your work for which
                  you think having more control may help your wellbeing.
                </strong>
              </p>
            </div>
          </div>
        );
        break;
      case 'bb844672-2318-4a9f-b9ac-354f535c7dea':
        content = (
          <div className="page-content">
            <h1 className="page-content__title">What is Management Support about?</h1>
            <div className="page-content__styles">
              <p>
                Management support refers to the extent you feel that your line manager is providing
                you with appropriate support. Support can be technical advice, sufficient resources,
                information, emotional help, or helpful feedback on your work. etc. If you perceive
                your manager is helpful and supportive to you, this is a good sign. On the other
                hand, you may feel that the support you receive from your line manager could be
                improved. This could be due to lack of availability and one-to-one contact, lack of
                understanding of your work, or of your needs and personal circumstances.
              </p>
              <p>
                <strong>
                  Some examples of work situation demonstrating limited management support:
                </strong>{' '}
              </p>
              <ul>
                <li>
                  My manager does not understand what I do and is limited in the advice and support
                  they can offer
                </li>
                <li>My manager and I do not meet often so he or she doesn't know how I am doing</li>
                <li>My manager does not understand my current (work and personal) challenges</li>
              </ul>
              <p>
                <strong>
                  You can also have a look at the following statements from the questionnaire and
                  make notes about any areas that are particularly relevant for you
                </strong>{' '}
              </p>
              <ul>
                <li>I am given supportive feedback on the work I do by my line manager</li>
                <li>I can rely on my manager to help out with a work problem</li>
                <li>
                  I can talk to my line manager about something that has upset or annoyed me about
                  work
                </li>
                <li>I am supported through emotionally demanding work by my line manager</li>
              </ul>
              <p>
                Your planned meeting with your manager may be a good opportunity to discuss the ways
                you and your manager work together. However, you may also feel this isn&rsquo;t the
                most appropriate time or context for a conversation involving direct feedback to
                your manager. If you feel this is an appropriate meeting to discuss manager support,
                you should make some notes about key things you wish to discuss, particularly
                focussing on areas where specific support may be beneficial to you. Try to be as
                factual as possible. However, you can also refer to your organisational policies on
                line management and reflect on the different ways you could address any concerns you
                have.
              </p>
            </div>
          </div>
        );
        break;
      case 'bae25492-5633-48ec-b04f-69f2bc9bc43b':
        content = (
          <div className="page-content">
            <h1 className="page-content__title">What is Peer Support about?</h1>
            <div className="page-content__styles">
              <p>
                Peer support is about the extent to which you perceive your colleagues to be
                supportive. It refers to the social support, encouragement and resources provided by
                work colleagues. Support can take various forms: emotional help, practical help on a
                work problem, informational help or giving helpful feedback, or simply an extra pair
                of hand to help you with your workload.
              </p>
              <p>
                If you perceive mutual help and support within your team, this is a really good
                sign. On the other hand, you may perceive a lack of help, compassion or trust within
                your team. Team support and cohesion can be limited when team members have little
                understanding about other&rsquo;s circumstances or members focus on individual
                outcomes / performance. At the individual level, it can add to job pressure because
                people are less likely to understand and help each other and are less likely to
                display supporting behaviours that could enhance wellbeing by making people feel
                valued, appreciated or simply cared about. Social support is an important aspect of
                a positive work experience and may buffer stressful or demanding work situations.
              </p>
              <p>
                <strong>
                  Some examples of work situations that demonstrate a lack of peer support:
                </strong>{' '}
              </p>
              <ul>
                <li>I am doing lone working and don't feel part of a team</li>
                <li>
                  Our team has few opportunities to be together, which is impacting on our team
                  cohesion
                </li>
                <li>In my team, people are not considerate of others' personal circumstances</li>
              </ul>
              <p>
                <strong>
                  You can also have a look at the following statements from the questionnaire and
                  put into your notes those that feel particularly relevant for you
                </strong>{' '}
              </p>
              <ul>
                <li>If work gets difficult, my colleagues will help me</li>
                <li>I get the help and support I need from colleagues</li>
                <li>I receive the respect at work I deserve from my colleagues</li>
                <li>My colleagues are willing to listen to my work-related problems</li>
              </ul>
              <p>
                <strong>
                  You may find it helpful to make notes, if there a particular situation in which
                  you felt there was a lack of cohesion and support in the workplace?{' '}
                </strong>
              </p>
            </div>
          </div>
        );
        break;
      case '9608c2cc-6822-4a22-9ebf-4e5d7cab50f3':
        content = (
          <div className="page-content">
            <h1 className="page-content__title">What is Role about?</h1>
            <div className="page-content__styles">
              <p>
                Role refers to clarity about what is expected from you to do your job and how your
                work contributes to the wider organisation. In other words, what type of behaviours
                you should adopt to do your job well. Having clarity over what is expected of you is
                important as it gives you a clear direction. Lack of clarity (e.g., over what
                behaviours you should adopt, what your priorities are) can create uncertainty which
                may impact on your wellbeing and how you feel about your job and your employer.
              </p>
              <p>Lack of role clarity can be due to several factors:</p>
              <ul>
                <li>
                  You may not have been provided with sufficient information about how to go about
                  doing your work
                </li>
                <li>
                  There may be a mismatch between what you believe is required of you and what you
                  are able to achieve within your work (e.g. your role may be too broad or include
                  responsibilities that are outside of your expertise)
                </li>
                <li>
                  You may not have been provided with sufficient information to help you to know how
                  your role fits with the objectives of your team or the wider organisation
                </li>
                <li>
                  You may be asked to do different things that are actually conflicting, e.g. doing
                  well at one task leads to difficulties with other tasks.
                </li>
              </ul>
              <p>
                <strong>
                  Some examples of work situation demonstrating a lack of role clarity:
                </strong>
              </p>
              <ul>
                <li>
                  What my organisation and my line manager expect me to do seem different to me
                </li>
                <li>
                  What I am asked to do on one hand conflicts with things I am asked to do in other
                  parts of my role
                </li>
                <li>
                  In my job I value quality over quantity whereas my organisation values quantity
                  over quality
                </li>
              </ul>
              <p>
                <strong>
                  You can also have a look at the following statements from the questionnaire and
                  put into your notes those that feel particularly relevant for you
                </strong>{' '}
              </p>
              <ul>
                <li>I am clear about what is expected of me at work</li>
                <li>I know how to go about getting my job done</li>
                <li>I am clear what my duties and responsibilities are</li>
                <li>I am clear about the goals and objectives for my department</li>
                <li>I understand how my job fits into the overall aim of the organisation</li>
              </ul>
              <p>
                <strong>
                  Try to think about your work situation: are you lacking clarity in your role? It
                  may help you to make notes on the aspects of you work in which you would like more
                  clarity, or maybe the aspects of your work that make you feel torn between
                  competing or conflicting tasks.
                </strong>
              </p>
            </div>
          </div>
        );
        break;
      case '8d0b0089-2cd2-4c4f-97ff-588710cba91d':
        content = (
          <div className="page-content">
            <h1 className="page-content__title">What is Demands about?</h1>
            <div className="page-content__styles">
              <p>
                Demands refers to aspects of your job that require effort. Work demands are very
                broad and varied across different jobs and they are really the core of what your job
                is about. Demands and can be physical or mental and could include: workload (e.g.,
                working under time pressure, multitasking, emotionally difficult situations), work
                patterns (e.g., doing night shifts) and work environment (e.g., working under high
                levels of noise, in uncomfortable positions, or standing for long periods). Work
                demands can also be increased if you don&rsquo;t have the right tools to do the job
                (systems, technologies or PPE).
              </p>
              <p>
                Having too much demand in the long-term can be harmful, as demands are physically or
                psychologically taxing. Dealing with excessive demands can make you feel tired or
                even exhausted at the end of the day. Feeling occasionally tired is normal and part
                of a busy job, but there may be cause for concern if you are always very tired or
                exhausted after work, or you do not feel you have time to recover from your demands.
              </p>
              <p>
                Thinking about your level of demands: would you categorise your demand as healthy,
                too high, or even too low?
              </p>
              <ul>
                <li>
                  In terms of <em>frequency</em>: if your level of demands is high, is it temporary
                  or recurrent?
                </li>
                <li>
                  In terms of <em>intensity</em>: do you have time to take breaks? How often do you
                  have to work under time pressure? Do you have enough time to recover from work?
                </li>
                <li>
                  In terms of <em>impact</em>: how often do you feel exhausted at the end of the
                  day?
                </li>
              </ul>
              <p>
                <strong>Some examples of work situations where demand may be too high:</strong>
              </p>
              <ul>
                <li>I can't meet my objectives (because I don't have enough time)</li>
                <li>
                  Because there is too much to do, I feel like I am always working in a hurry and
                  feel I can&rsquo;t do things right
                </li>
                <li>
                  I feel like I&rsquo;m letting people down because I have to prioritise other tasks
                </li>
                <li>
                  Because some roles in my team have not been replaced, I need to pick up additional
                  tasks and responsibilities that make my workload unmanageable
                </li>
              </ul>
              <p>
                <strong>
                  You can also have a look at the following statements from the questionnaire and
                  put into your notes any issues that particularly reflect how you feel:
                </strong>
              </p>
              <ul>
                <li>Different groups at work demand things from me that are hard to combine</li>
                <li>I have unachievable deadlines</li>
                <li>I have to work very intensively</li>
                <li>I have to neglect some tasks because I have too much to do</li>
                <li>I am unable to take sufficient breaks</li>
                <li>I am pressured to work long hours</li>
                <li>I have to work very fast</li>
                <li>I have unrealistic time pressures</li>
              </ul>
              <p>
                <strong>
                  You may also like to think about a situation where you have felt particularly
                  exhausted, either at the end of the day or at a specific time point. What
                  circumstances led you to feel this way?
                </strong>{' '}
                <br />
                This may be helpful to capture in your notes, so you can explore specific aspects of
                your work with your manager.
              </p>
            </div>
          </div>
        );
        break;
      case '7185abba-d609-4071-b6b9-6e199c475e38':
        content = (
          <>
            <div className="page-content">
              <h1 className="page-content__title">What is Relationships about?</h1>
              <div className="page-content__styles">
                <p>
                  Relationships refers to the quality of workplace relationships. Ideally, people
                  should be working in a positive work atmosphere in which conflict is properly
                  resolved and unacceptable behaviour is not tolerated. However, it is not always
                  the case.
                </p>
                <p>
                  Poor workplace relationships can include a broad range of negative behaviours. The
                  most severe behaviours are bullying and harassment, which are not acceptable in
                  the workplace. If you have witnessed or been a victim of bullying or harassment,
                  please refer to your organisational policies which will guide you to find
                  appropriate help. It is important to note that your employer has a responsibility
                  to address any instances of workplace bullying or harassment. Alternatively, you
                  could speak to your manager or to an HR representative. Your planned meeting with
                  your manager may be an opportunity to raise and discuss any concerns you have
                  about bullying and harassment, but you may also feel this isn&rsquo;t the most
                  appropriate time or context for a conversation such as this.
                </p>
                <p>
                  Less severe (but potentially harmful) work behaviours can include criticism,
                  exclusion, inappropriate topics of conversation, gossiping, and undermining
                  colleagues. Such behaviours can create a negative work atmosphere. In these
                  circumstances, employees may withdraw from the team, or this could develop and
                  lead to more serious conflict. A poor interpersonal culture can also add to job
                  pressure at the individual level, because people are less likely to help each
                  other.
                </p>
                <p>
                  <strong>
                    Some examples of work situations demonstrating poor workplace behaviours:{' '}
                  </strong>
                </p>
                <ul>
                  <li>I have attended meetings in which employees were put down/talked down to</li>
                  <li>I have been personally putting down during a meeting</li>
                  <li>I have received harsh critics about my competencies</li>
                  <li>I have received nasty emails written in an inappropriate tone</li>
                  <li>Some clients or service users have treated me badly</li>
                </ul>
                <p>
                  <strong>
                    You can also have a look at the following items coming from the questionnaire
                    and put into your notes those that particularly resonate for you
                  </strong>{' '}
                </p>
                <ul>
                  <li>
                    I am subject to personal harassment in the form of unkind words or behaviour
                  </li>
                  <li>There is friction or anger between colleagues</li>
                  <li>I am subject to bullying at work</li>
                  <li>Relationships at work are strained</li>
                </ul>
                <p>
                  <strong>
                    Is there a situation that you felt was inappropriate in your workplace? Make
                    some notes about your experience and think carefully about whether this is the
                    most suitable context to report and discuss this experience, or whether you
                    should follow your organisational policy to find appropriate help.
                  </strong>
                </p>
              </div>
            </div>
          </>
        );
        break;
      case '4af58c57-cbe4-499e-99ec-fb33c45c1b98':
        content = (
          <>
            <div className="page-content">
              <h1 className="page-content__title">What is Change about?</h1>
              <div className="page-content__styles">
                <p>
                  Change relates about how workplace changes are managed. Change could include major
                  organisations-level changes, such as restructures, merger, acquisition, new
                  physical workspace, or new management, or it can be more local changes in systems
                  and ways of working, such as new division of the workload between team members.
                  Change can be a source of stress as it creates uncertainty either about job
                  security or about how things will be done after the changes are in place. Lack of
                  information about change, its potential consequences for workers or lack of
                  participation in decisions around the change can also impact on employee
                  wellbeing.
                </p>
                <p>
                  <strong>Some examples demonstrating poor experiences of change: </strong>
                </p>
                <ul>
                  <li>The impact of the change wasn&rsquo;t considered thoroughly for my team</li>
                  <li>
                    I don't know what the change is about, what the plan is or how it will affect me
                    or my work
                  </li>
                  <li>
                    I had some views and ideas regarding the change that have not been heard or
                    listened to
                  </li>
                </ul>
                <p>
                  <strong>
                    You can also have a look at the following statements from the questionnaire and
                    put into your notes those that feel particularly relevant for you
                  </strong>{' '}
                </p>
                <ul>
                  <li>
                    I have sufficient opportunities to question my manager about changes at work
                  </li>
                  <li>Staff are always consulted about change at work</li>
                  <li>
                    When changes are made at work, I am clear how they will work out in practice
                  </li>
                </ul>
                <p>
                  <strong>
                    If your experience of change is impacting on your wellbeing, you may like to
                    make some notes about the particular aspects of this that are difficult for you.
                    It may be the case that wider organisation change is beyond the scope of what
                    you and your manager can influence, but this may still be a helpful aspect of
                    your work to discuss with your manager.
                  </strong>
                </p>
              </div>
            </div>
          </>
        );
        break;
    }

    return <div>{content}</div>;
  };

  return <>{categoryContent(categoryId)}</>;
};

export default QuestionCategoryContent;
