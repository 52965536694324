// Backend
import React from 'react';

// Frontend
import { Grid, Cell } from 'react-foundation';
import userGuideImage from '../../images/content/pages/16-sm.png';
import resourcesImage from '../../images/content/pages/resources-sm.png';
import '../../stylesheets/components/Pages.css';

// Functionality
import { useLocation, Link } from 'react-router-dom';

// Components
import { Header, FlashMessage } from '../../constants/SharedComponents';
import useCurrentUser from '../../hooks/useCurrentUser';

export function PagesAboutFwd() {
  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();

  // Objects
  const [flashMessageObject, setFlashMessageObject] = React.useState({});

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  return (
    <>
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />

      <div className="grid-container">
        <Grid className="grid-padding-x align-justify">
          <Cell small={12}>
            <div className="page-content">
              <h1 className="page-content__title">Future Work Design Together</h1>
              <div className="page-content__styles">
                <p>FWD Together is a ground-breaking approach to proactively manage workplace wellbeing. The tool was developed by a team at the Centre for Human Factors led by Professor Fiona Earle.  
                </p>
              </div>
              <h2 className="page-content__title">Academic Foundations</h2>
            </div>
          </Cell>
          <Cell small={12} large={6}>
            <div className="page-content">
              <div className="page-content__styles">
                <p>
               A Chartered Occupational Psychologist, Fiona specialises in work-related stress.
			   She works with organisations to understand the unique sources of stress facing their workforce,
			   and helps organisations to proactively manage employee wellbeing. Recent research has shown that
			   the average cost to a Local Authority can be close to £2000 per employee, if they were experiencing
			   a severe mental health problem. In our work with public and private sector organisations we have identified
			   some of the most significant risks to mental health and we are using this insight to support the design of healthier work.{' '}
                </p>
                <p>
              Through a collaboration with the university's renowned Risk Institute, it became clear that a
			  ‘risk-based approach’ to managing employee wellbeing could build on traditional methods of risk
			  assessment to provide a solid scientific framework to support healthier work. This is a well-established
			  approach to managing traditional ‘health & safety’, by identifying the risks, understanding the impacts
			  and then proposing controls to reduce the risk. However, despite this being the HSE’s recommended approach
			  for managing work-related stress, and the growing evidence-base of the benefits of this approach, risk
			  assessments for mental health are much less common.
                </p>
                <p>
                  <strong>
                  Our FWD Together tool supports managers to work with their team members to proactively manage work-related stress.{' '}
                  </strong>
                </p>
              </div>
            </div>
          </Cell>
          <Cell small={12} large={6}>
            <div className="page-content">
			<h4>Watch Fiona Explain the background to the FWD Together Tool</h4>
              <div className="page-content__styles">
                <iframe
                  className="sproutvideo-player"
                  src="https://videos.sproutvideo.com/embed/a79fd8b91019e7c12e/b61cf852c6491138"
                  width="640"
                  height="363"
                  allowFullScreen
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Video Player"
                ></iframe>
              </div>
            </div>
          </Cell>
          <Cell small={12} large={6}>
             <div className="page-content">
			   <Link
                      to={process.env.PUBLIC_URL + '/FWD - How to guide.pdf'}
                      target="_blank"
                      rel="noopener noreferrer"
                   >
			  <div className="image-container">
                <img src={userGuideImage} loading="lazy" alt="" />
			    <div className="image-overlay">
					<h3>User Guide</h3>
				  	<span >Download User Guide</span>
				</div>
			  </div>
			  </Link>
            </div>
          </Cell>
          <Cell small={12} large={6}>
             <div className="page-content">
			      <Link to='/resources' >
			  <div className="image-container">
                <img src={resourcesImage} loading="lazy" alt="" />
			    <div className="image-overlay">
					<h3>Resources</h3>
				  	<span>View Resources</span>
				</div>
			  </div>
			  </Link>
            </div>
          </Cell>
          <Cell small={12}>
            <div className="page-content">
              <div className="page-content__styles">
                <p>
                  The rise of the Covid-19 pandemic was a worrying time for the wellbeing of workers. New ways of working emerged in response to national lockdowns, and these changes introduced work characteristics with novel risks to employee wellbeing. The dominance of digital meetings and changes to the nature of social interaction at work introduced new risk factors that needed to be understood and addressed.
                </p>
                <p>
                  Along with these changes to working practices, we have seen a national rise in mental health difficulties, unmanageable levels of sickness absence and the risk of presenteeism. In light of these major changes to the landscape of modern work, DLUHC offered support for projects through their Local Digital Fund.{' '}
                </p>
                <p>
                  <strong>Future Work Design</strong> is a series of funded projects based on a collaboration between an academic team from the University of Hull and our four regional Local Authorities in the Humber. This forward thinking partnership is led by East Riding of Yorkshire Council and is supported by Hull City Council, North Lincolnshire Council and North East Lincolnshire Council. This team have created an innovative support tool that facilitates a highly effective conversation to proactively explore and manage work-related stress risks.
                </p>
                <h2 className="page-content__title">Who should use the FWD Together tool</h2>
                <p>
                 The tool can be used by any line manager and team member to explore together the root cause of any work-related stress risks experienced by the team member. The focus is on understanding the unique experience of work and finding a way forward together.{' '}
                </p>
              </div>
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  );
}
