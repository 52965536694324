// Backend
import React from 'react';
import axios from 'axios';
import history from '../../../helpers/history';

// Frontend
import {
  ChevronUp,
  ChevronDown,
  Download,
  Save,
  Check2Circle,
  PlusCircle,
} from 'react-bootstrap-icons';
import { Alert, Modal, Button } from 'react-bootstrap';
import { Grid, Cell } from 'react-foundation';
import '../../../stylesheets/components/conversations/Canvas.css';
import { Tooltip } from 'react-tooltip';

// Functionality
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom';

// Components
import {
  DluchApi,
  FlashMessage,
  Flashable,
  ProgressBar,
  ErrorRender,
  setToken,
  filteredCategories,
} from '../../../constants/SharedComponents';

import useCurrentUser from '../../../hooks/useCurrentUser';

// ReactFlow - core
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  useNodesState,
  useEdgesState,
  Controls,
  MiniMap,
} from 'reactflow';
import 'reactflow/dist/style.css';
import Sidebar from '../../../components/ReactFlow/Sidebar';

// ReactFlow - nodes
import ImpactNode from '../../../components/ReactFlow/ImpactNode';
import CategoryNode from '../../../components/ReactFlow/CategoryNode';
import WhyNode from '../../../components/ReactFlow/WhyNode';
import { HashLink } from 'react-router-hash-link';

const initialNodes = [];

const nodeTypes = {
  impactNode: ImpactNode,
  categoryNode: CategoryNode,
  whyNode: WhyNode,
};

let id = 0;
const defaultViewport = { x: 0, y: 0, zoom: 0.75 };
const getId = (nodeType) => `${nodeType}--${id++}`;
const snapGrid = [25, 25];

const onConnectStart = (_, { nodeId, handleType }) => {
};

const onConnectEnd = (event) => {
};

const nodeColor = (node) => {
  switch (node.type) {
    case 'impactNode':
      return '#c15360';
    case 'categoryNode':
      return '#c35221';
    case 'whyNode':
      return '#fbed8a';
    default:
      return '#fbed8a';
  }
};

function Flow(props) {
  return <ReactFlow {...props} />;
}

export function CanvasesCategoriesEdit() {
  // Params
  const { conversationId, categoryId } = useParams();
  const categoryIndex = parseInt(categoryId);

  // API
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const { state } = useLocation();
  const token = setToken(currentUser);
  const headerParams = { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 };

  // Objects
  const [inputs, setInputs] = React.useState({});
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [flashMessageObject, setFlashMessageObject] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [categoriesData, setCategoriesData] = React.useState(null);
  const [categoryData, setCategoryData] = React.useState(null);
  const [canvasData, setCanvasData] = React.useState({});
  const [submissionUnlocked, setSubmissionUnlocked] = React.useState(false);
  const [newRecord, setNewRecord] = React.useState(false);
  const [controlsCount, setControlsCount] = React.useState(1);
  const [actionsCount, setActionsCount] = React.useState({ 0: 1 });

  const [formChanged, setFormChanged] = React.useState(false);

  const [controlsAndActionsAreaClassName, setControlsAndActionsAreaClassName] =
    React.useState('hide');
  const [controlsAndActionsButtonClassName, setControlsAndActionsButtonClassName] =
    React.useState('');

  const [saveAlert, setSaveAlert] = React.useState(false);
  const handleCloseSaveAlert = () => {
    setSaveAlert(false);
  }

  const [deleteControlModal, setDeleteControlModal] = React.useState(undefined);
  function handleOpenDeleteControlModal(e, id) {
    e.preventDefault();
    setDeleteControlModal(id);
  }
  function handleCloseDeleteControlModal(e) {
    e.preventDefault();
    setDeleteControlModal(undefined);
  }

  const [deleteActionModal, setDeleteActionModal] = React.useState(undefined);
  function handleOpenDeleteActionModal(e, id) {
    e.preventDefault();
    setDeleteActionModal(id);
  }
  function handleCloseDeleteActionModal(e) {
    e.preventDefault();
    setDeleteActionModal(undefined);
  }


  const controlsAndActionsAreaWrapper = React.useRef(null);
  const controlsAndActionsButtonWrapper = React.useRef(null);

  // Functions
  function createSubmission(
    isFinished,
    newCanvas,
    reactFlowInstance,
    formInputs,
    canvasData,
    redirect,
  ) {
    setLoading(true);
    let Api = new DluchApi('ConversationDataEdit');

    const flow = reactFlowInstance.toObject();
    const flowData = JSON.stringify(flow);

    canvasData[categoryIndex] = { flowData: flowData, additionalInput: JSON.stringify(formInputs) };

    let requestPayload = {
      conversationId: conversationId,
      canvasData: JSON.stringify(canvasData),
      isFinished: isFinished,
    };

    if (newCanvas) {
      axios
        .post(Api.ApiRequestUrl(), requestPayload, headerParams)
        .then(() => {
          setSaveAlert(true);
          setLoading(false);
          setNewRecord(false);
          setFormChanged(false);

          if (isFinished) {
            setSubmissionUnlocked(false);
          }

          if (redirect) {
            navigate(redirect);
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            setFlashMessageObject(Flashable(error.response.data.errorDesc, 'alert', false));
          } else {
            setFlashMessageObject(Flashable(error.message, 'alert', false));
          }

          setLoading(false);
        });
    } else {
      axios
        .put(Api.ApiRequestUrl(), requestPayload, headerParams)
        .then(() => {
          setSaveAlert(true);
          setLoading(false);
          setFormChanged(false);

          if (isFinished) {
            setSubmissionUnlocked(false);
          }

          if (redirect) {
            navigate(redirect);
          }
        })
        .catch((error) => {
          setFlashMessageObject(Flashable(error.response.data.errorDesc, 'alert', false));
          setLoading(false);
        });
    }
  }

  // ReactFlow - core
  const reactFlowWrapper = React.useRef(null);
  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = React.useState(null);

  // ReactFlow - functionality
  const onNodesChange = React.useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes],
  );
  const onEdgesChange = React.useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges],
  );

  const onInit = React.useCallback((reactFlowInstance) => {
    setReactFlowInstance(reactFlowInstance);
  }, []);

  const onConnect = React.useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);

  const onSave = React.useCallback(() => {
    createSubmission(false, newRecord, reactFlowInstance, inputs, canvasData, null);
  }, [reactFlowInstance, newRecord, inputs, canvasData]);

  const saveBeforeNavigation = React.useCallback(
    (event) => {
      if (submissionUnlocked) {
        event.preventDefault();
        const href = event.target.getAttribute('href');
        createSubmission(false, newRecord, reactFlowInstance, inputs, canvasData, href);
        setFormChanged(false);
      }
    },
    [reactFlowInstance, newRecord, inputs, canvasData, submissionUnlocked],
  );

  const toggleControlsAndActions = React.useCallback(
    (event) => {
      const href = event.target.getAttribute('href');
      event.preventDefault();

      if (controlsAndActionsAreaClassName === 'hide') {
        setControlsAndActionsButtonClassName('hide');
        setControlsAndActionsAreaClassName('');
      } else {
        setControlsAndActionsButtonClassName('');
        setControlsAndActionsAreaClassName('hide');
      }

      if (href.includes('#')) {
        const id = `${href.substring(href.indexOf('#') + 1)}`;
        if (id) {
          setTimeout(
            function () {
              const anchor = document.getElementById(id);
              anchor.scrollIntoView({ behavior: 'smooth' });
            },
            250,
            id,
          );
        }
      }
    },
    [controlsAndActionsAreaClassName],
  );

  const addControl = React.useCallback(
    (event) => {
      event.preventDefault();
      var currentControlsCount = controlsCount + 1;

      const currentActionsCount = { ...actionsCount };
      currentActionsCount[controlsCount] = 1;

      setActionsCount(currentActionsCount);
      setControlsCount(currentControlsCount);
    },
    [controlsCount, actionsCount, inputs],
  );

  const addAction = React.useCallback(
    (event) => {
      event.preventDefault();

      const currentCount = { ...actionsCount };
      currentCount[event.target.getAttribute('data-controls-count')] =
        actionsCount[event.target.getAttribute('data-controls-count')] + 1;

      setActionsCount(currentCount);
    },
    [actionsCount],
  );

  const removeAction = React.useCallback(
    (event) => {
      event.preventDefault();

      const controlCount = event.target.getAttribute('data-controls-count');
      const actionCount = event.target.getAttribute('data-actions-count');

      let id = `canvas-category-actions--${controlCount}-${actionCount}`;

      document.getElementById(id).value = '';
      document.getElementById(id).parentElement.style = 'display:none';
      inputs[id] = '';
      setDeleteActionModal(false);
      setInputs(inputs);
    },
    [inputs],
  );

  const removeControl = React.useCallback(
    (event) => {
      event.preventDefault();

      const controlCount = event.target.getAttribute('data-controls-count');
      const actionCount = event.target.getAttribute('data-actions-count');

      let id = `canvas-category-controls--${controlCount}`;

      document.getElementById(id).value = '';
      document.getElementById(id).parentElement.parentElement.parentElement.parentElement.style =
        'display:none';
      inputs[id] = '';

      Array.apply(null, { length: actionCount }).map((count, actionsIndex) => {
        let action = `canvas-category-actions--${controlCount}-${actionsIndex}`;

        let elem = document.getElementById(action);

        if (elem) {
          elem.value = '';
        }
        inputs[action] = '';
      });
      setDeleteControlModal(false);
      setInputs(inputs);
    },
    [inputs],
  );

  const onDragOver = React.useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = React.useCallback(
    (event) => {
      setFormChanged(true);
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      const newNode = {
        id: getId(type),
        type,
        position,
        data: { label: `${type} node` },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance],
  );

  // @see https://github.com/wbkd/react-flow/blob/afa38727fad2400e817a3dcc6c575cb83cbf2bd2/examples/vite-app/src/examples/UseReactFlow/index.tsx
  const onNodeClick = React.useCallback(
    (event, element) => {
      if (
        event.target.className === 'js--delete-node conversation-canvas-node__button' &&
        submissionUnlocked
      ) {
        reactFlowInstance.deleteElements({
          nodes: [{ id: element.id }],
          edges: [],
        });
      }
    },
    [reactFlowInstance, submissionUnlocked],
  );

  // Functions
  const getData = async () => {
    setLoading(true);

    try {
      let Api = new DluchApi('ConversationsShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.conversation;
      setData(responseData);
      setError(false);
      getQuestionCategoriesData();
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const sortedQuestionResultData = function (categoryData) {
    let responseData = categoryData.sort(function (a, b) {
      let weight = -1;

      if (a.priorityRank > b.priorityRank) {
        weight = 1;
      } else if (a.priorityRank === b.priorityRank) {
        weight = a.categoryName > b.categoryName ? 1 : -1;
      }

      return weight;
    });

    responseData = responseData.reverse();

    responseData = responseData.sort(function (a, b) {
      return a.categoryType - b.categoryType;
    });

    return responseData;
  };

  const getQuestionCategoriesData = async () => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionsCategoriesIndex', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.categories;
      let allCategories = filteredCategories(responseData, 3, false);
      setError(false);
      getQuestionResultsData(allCategories);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const getCanvasData = async (category = undefined) => {
    setLoading(true);

    let categoryTitle =
      category !== undefined && !category.selfGuided
        ? `Topic ${categoryIndex}: ${category.title}`
        : `Topic ${categoryIndex}`;

    let categoryValue =
      category !== undefined && category.selfGuided && category.canShareWithManager
        ? category.title
        : '';

    let flow;
    const defaultNodes = {
      nodes: [
        {
          id: 'categoryNode',
          type: 'categoryNode',
          deletable: false,
          position: { x: 0, y: 0 },
          data: {
            title: categoryTitle,
            value: categoryValue,
          },
        },
      ],
    };

    try {
      let Api = new DluchApi('ConversationDataShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.data;
      let canvasData = JSON.parse(responseData.canvasData);
      let locked = responseData.isFinished;
      let defaultInputs = {};

      if (locked) {
        setSubmissionUnlocked(false);
      } else {
        setSubmissionUnlocked(true);
      }

      if (canvasData.hasOwnProperty(categoryIndex)) {
        const flow = JSON.parse(canvasData[categoryIndex].flowData);
        let additionalInput = JSON.parse(canvasData[categoryIndex].additionalInput);

        if (locked) {
          for (let row of flow.nodes) {
            row.data.locked = locked;
          }
        }

        for (const [key, value] of Object.entries(additionalInput)) {
          defaultInputs[key] = value;
        }

        setEdges(flow.edges);
        setNodes(flow.nodes);
        setInputs(defaultInputs);

        let controlsCount = 0;
        let actionsCountObject = {};

        if (defaultInputs) {
          for (let row of Object.keys(defaultInputs)) {
            if (row.includes('controls--')) {
              let value = row.split('--')[1];

              if (value > controlsCount) {
                controlsCount = parseInt(value);
              }
            }

            if (row.includes('actions--')) {
              let values = row.split('--')[1].split('-');
              let control = parseInt(values[0]);
              let actionsCountTotal = parseInt(values[1]);

              if (
                actionsCount[control] === undefined ||
                actionsCountTotal > actionsCount[control]
              ) {
                actionsCount[control] = actionsCountTotal;
              }
            }
          }

          for (const [key, value] of Object.entries(actionsCount)) {
            actionsCountObject[key] = value + 1;
          }

          setControlsCount(controlsCount + 1);
          setActionsCount(actionsCountObject);
          setControlsAndActionsButtonClassName('hide');
          setControlsAndActionsAreaClassName('');
        }
      } else {
        setNodes(defaultNodes.nodes);
      }

      setCanvasData(canvasData);
      setError(false);
    } catch (err) {
      setNewRecord(true);
      setSubmissionUnlocked(true);
      flow = {};
      flow[categoryIndex] = defaultNodes;
      setCanvasData(flow);
      setNodes(flow[categoryIndex].nodes);
    } finally {
      setLoading(false);
    }
  };

  const getQuestionResultsData = async (questionCategories) => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionResultsShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.results.filter(
        (obj) => obj.isConversationTopic === true,
      );

      let guidedOptionalCategories = filteredCategories(questionCategories, 1);
      guidedOptionalCategories = guidedOptionalCategories.map((obj) => obj.id);

      let selfGuidedCategories = filteredCategories(questionCategories, 2);
      selfGuidedCategories = selfGuidedCategories.map((obj) => obj.id);

      for (let row of questionCategories) {
        row.selfGuided =
          guidedOptionalCategories.includes(row.id) || selfGuidedCategories.includes(row.id);
      }

      for (let row of responseData) {
        row.title = row.optionalCategoryName === null ? row.categoryName : row.optionalCategoryName;
        let category = questionCategories.find((obj) => obj.id === row.categoryId);

        row.selfGuided = false;

        if (category) {
          row.selfGuided = category.selfGuided;
        }
      }
      setCategoriesData(responseData);
      let category = responseData[categoryIndex - 1];
      setCategoryData(category);
      setError(false);

      getCanvasData(category);
    } catch (err) {
      setError(false);
      getCanvasData();
    } finally {
      setLoading(false);
    }
  };

  function isViewable(data, submissionUnlocked) {
    return (data.isUserManager && submissionUnlocked) || !submissionUnlocked;
  }

  function retryFetch() {
    setLoading(true);
    setError(false);
    setData(null);
    getData();
  }

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (
      event.target.className.includes('canvas-category-controls-and-actions-section-item__input')
    ) {
      event.target.style.height = 0;
      event.target.style.height = `${event.target.scrollHeight + 5}px`;

      if (event.target.value === '') {
        value = ' '
      }
    }

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const [isHover, setIsHover] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const boxStyle = {
    minWidth: "110px",
    backgroundColor: isHover ? "rgb(170,0,0)" : "rgb(255, 0, 0)",
  };

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (currentUser && currentUser.token) {
      retryFetch();
    }

    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  React.useEffect(() => {
    let unblock;
    if (formChanged) {
      unblock = history.block((tx) => {
        let url = tx.location.pathname;
        
        if (
          window.confirm(
            `You have unsaved changes. If you navigate away from this page, these changes will be lost.`,
          )
        ) {
          unblock();
          tx.retry();
        }
      });
    }

    return () => {
      if (typeof unblock === 'function') {
        unblock();
      }
    };
  }, [formChanged]);

  return (
    <div className="canvas-content-wrap">
      <ProgressBar loading={loading} />
      <FlashMessage
        object={flashMessageObject}
        columnWrap={true}
        className="flash-message-compact"
      />
      <ErrorRender object={error} />

      {data && (
        <>
          {!data.isUserManager && submissionUnlocked && categoryData && (
            <div className="grid-container">
              <Grid className="grid-padding-x">
                <Cell small={12}>
                  <div className="page-content">
                    <h1 className="page-content__title">Canvas in progress</h1>

                    <div className="page-content__styles">
                      <p>
                        This canvas is not yet complete. You will be able to see this when it is
                        finished.
                      </p>
                    </div>
                  </div>
                </Cell>
              </Grid>
            </div>
          )}

          {isViewable(data, submissionUnlocked) && (
            <div className="page-content">
              <div className="page-content__header page-content__header--bordered page-content__header--fixed">
                <Grid className="grid-padding-x align-middle align-center">
                  <Cell small={12} large={3}>
                    <Link
                      to="/conversations"
                      className="page-content__button page-content__button--inline"
                      id="page-navigation-button--go-back"
                    >
                      Back to Dashboard
                    </Link>
                    <Link
                      to="/resources"
                      className="page-content__button page-content__button--secondary"
                      id="page-navigation-button--resources"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Resources
                    </Link>
                  </Cell>

                  <Cell small={12} large={6} className="text-center">
                    <ul className="canvas-categories-list">
                      {!categoriesData &&
                        Array.apply(null, { length: 3 }).map((count, categoriesDataIndex) => (
                          <li
                            className="canvas-categories-list__item"
                            key={'category-' + categoriesDataIndex}
                          >
                            {categoryIndex !== categoriesDataIndex + 1 && (
                              <Link
                                to={`/canvases/${data.id}/categories/${categoriesDataIndex + 1
                                  }`}
                                state={{ state: { categoryId: categoriesDataIndex + 1 } }}
                                className="canvas-categories-list__link"
                                id={`canvas-category-list-link--${categoriesDataIndex}`}
                                onClick={saveBeforeNavigation}
                              >
                                {`Topic ${categoriesDataIndex + 1}`}

                                <span className="canvas-categories-list__icon canvas-categories-list__icon--deselected util--hide-icon">
                                  <ChevronUp size={12} />
                                </span>
                              </Link>
                            )}

                            {categoryIndex === categoriesDataIndex + 1 && (
                              <span className="canvas-categories-list__title">
                                {`Topic ${categoriesDataIndex + 1}`}

                                <span className="canvas-categories-list__icon canvas-categories-list__icon--selected  util--hide-icon">
                                  <ChevronDown size={12} />
                                </span>
                              </span>
                            )}
                          </li>
                        ))}

                      {categoriesData && (
                        <>
                          {categoriesData.map((category, categoriesDataIndex) => {
                            return (
                              <li
                                className="canvas-categories-list__item"
                                key={'category-' + category.categoryId}
                              >
                                {categoryIndex !== categoriesDataIndex + 1 && (
                                  <Link
                                    to={`/canvases/${data.id}/categories/${categoriesDataIndex + 1
                                      }`}
                                    state={{ state: { categoryId: categoriesDataIndex + 1 } }}
                                    className="canvas-categories-list__link"
                                    id={`canvas-category-list-link--${categoriesDataIndex}`}
                                    onClick={saveBeforeNavigation}
                                  >
                                    {`Topic ${categoriesDataIndex + 1}`}
                                    <span className="canvas-categories-list__icon canvas-categories-list__icon--deselected  util--hide-icon">
                                      <ChevronUp size={12} />
                                    </span>
                                  </Link>
                                )}

                                {categoryIndex === categoriesDataIndex + 1 && (
                                  <span className="canvas-categories-list__title">
                                    {`Topic ${categoriesDataIndex + 1}`}
                                    <span className="canvas-categories-list__icon canvas-categories-list__icon--selected  util--hide-icon">
                                      <ChevronDown size={12} />
                                    </span>
                                  </span>
                                )}
                              </li>
                            );
                          })}
                        </>
                      )}
                    </ul>
                  </Cell>

                  <Cell small={12} large={3} className="text-right">
                    <ul className="canvas-categories-navigation-list">
                      {submissionUnlocked && (
                        <li className="canvas-categories-navigation-list__item">
                          <span
                            onClick={onSave}
                            id="page-navigation-button--save"
                            className="canvas-categories-navigation-list__save-button"
                          >
                            Save for later
                            <span className="canvas-categories-navigation-list__icon">
                              <Save size={14} />
                            </span>
                          </span>
                        </li>
                      )}

                      {submissionUnlocked && (
                        <li className="canvas-categories-navigation-list__item">
                          <Link
                            to={`/canvases/${data.id}/confirmations/new`}
                            id="page-navigation-button--complete"
                            className="canvas-categories-navigation-list__save-button"
                            onClick={saveBeforeNavigation}
                          >
                            Save and finish
                            <span className="canvas-categories-navigation-list__icon">
                              <Check2Circle size={14} />
                            </span>
                          </Link>
                        </li>
                      )}

                      {!submissionUnlocked && (
                        <li className="canvas-categories-navigation-list__item">
                          <Link
                            to={`/canvases/${data.id}/exports/new`}
                            id="page-navigation-button--download"
                            className="canvas-categories-navigation-list__save-button"
                            onClick={saveBeforeNavigation}
                          >
                            Download PDF
                            <span className="canvas-categories-navigation-list__icon">
                              <Download size={14} />
                            </span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </Cell>
                </Grid>
              </div>

              <Alert variant="info" show={saveAlert} onClose={handleCloseSaveAlert} dismissible>
                <Alert.Heading><b>Save Complete</b></Alert.Heading>
                <p>
                  This canvas has now been saved. You can now close this page safely and return to this canvas later.
                </p>
              </Alert>

              {canvasData && (
                <div className="reactflow-wrap">
                  <ReactFlowProvider>
                    <div className="reactflow" ref={reactFlowWrapper}>
                      <Flow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        onConnectStart={onConnectStart}
                        onConnectEnd={onConnectEnd}
                        onNodeClick={onNodeClick}
                        onInit={onInit}
                        onDrop={onDrop}
                        onDragOver={onDragOver}
                        nodeTypes={nodeTypes}
                        snapToGrid={true}
                        snapGrid={snapGrid}
                        defaultViewport={defaultViewport}
                        nodesConnectable={submissionUnlocked}
                        nodesDraggable={submissionUnlocked}
                        elementsSelectable={submissionUnlocked}
                        // edgeTypes={edgeTypes}
                        connectionMode="loose"
                        fitView
                      >
                        <Controls showInteractive={submissionUnlocked} />
                        <MiniMap
                          nodeColor={nodeColor}
                          nodeStrokeWidth={3}
                          zoomable
                          pannable
                        />
                      </Flow>
                    </div>
                    <Sidebar submissionUnlocked={submissionUnlocked} />
                  </ReactFlowProvider>
                </div>
              )}
            </div>
          )}

          {submissionUnlocked && (
            <div
              className={`grid-container ${controlsAndActionsButtonClassName}`}
              ref={controlsAndActionsButtonWrapper}
            >
              <Grid className="grid-padding-x align-justify">
                <Cell small={12}>
                  <div className="canvas-category-footer-wrap canvas-category-footer-wrap--top">
                    <span className="canvas-category-footer-wrap__title">Next steps:</span>
                    <HashLink
                      to="#canvas-controls-and-actions-form"
                      className="page-content__button"
                      onClick={toggleControlsAndActions}
                    >
                      Add controls and actions for Topic {categoryIndex}:{' '}
                      {categoryData && categoryData.canShareWithManager && categoryData.title}
                    </HashLink>
                  </div>
                </Cell>
              </Grid>
            </div>
          )}
          <div
            className={`grid-container ${submissionUnlocked ? controlsAndActionsAreaClassName : ''
              }`}
            ref={controlsAndActionsAreaWrapper}
            id="canvas-controls-and-actions-form"
          >
            <Grid className="grid-padding-x">
              <Cell small={12}>
                <div className="canvas-category-controls-and-actions">
                  <div className="canvas-category-controls-and-actions-header">
                    <h2 className="canvas-category-controls-and-actions-header__title">
                      <strong className="canvas-category-controls-and-actions-header__emphasis canvas-category-controls-and-actions-header__emphasis--controls">
                        Controls
                      </strong>{' '}
                      and{' '}
                      <strong className="canvas-category-controls-and-actions-header__emphasis canvas-category-controls-and-actions-header__emphasis--actions">
                        Actions
                      </strong>{' '}
                      for Topic {categoryIndex}
                      {categoryData && categoryData.canShareWithManager && (
                        <>: {categoryData.title}</>
                      )}
                    </h2>
                  </div>
                </div>

                <form className="basic-form">
                  {Array.apply(null, { length: controlsCount }).map((count, controlsIndex) => (
                    <Grid
                      className="canvas-category-controls-and-actions-section-wrap"
                      key={`controls-count-${controlsIndex}`}
                    >
                      {inputs['canvas-category-controls--' + controlsIndex] !== '' && (
                        <>
                          <Cell
                            small={12}
                            large={6}
                            className="canvas-category-controls-and-actions-section canvas-category-controls-and-actions-section--left"
                          >
                            <div className="canvas-category-controls-and-actions-section__inner">
                              <div className="canvas-category-controls-and-actions-section-item">
                                <div className="canvas-category-controls-and-actions-section-item__title canvas-category-controls-and-actions-section-item__title--control">
                                  {submissionUnlocked && (
                                    <>
                                      <span
                                        data-tooltip-id="control-tooltip"
                                        data-tooltip-content="What may prevent the topic to leading to stress"
                                        data-tooltip-place="top"
                                      >
                                        Control{' '}
                                      </span>
                                      <button
                                        className="canvas-category-controls-and-actions-section-item__delete-button"
                                        id="canvas-category-controls-and-actions-section-item__delete-button"
                                        onClick={(e) => { handleOpenDeleteControlModal(e, controlsIndex) }}
                                        data-controls-count={controlsIndex}
                                        data-actions-count={actionsCount[controlsIndex]}
                                      >
                                        X
                                      </button>

                                      <Modal show={deleteControlModal === controlsIndex} style={{ paddingTop: "20px" }} id={controlsIndex}>
                                        <Modal.Header>
                                          <Modal.Title><b>Delete Control</b></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>Are you sure you want to delete this control?</Modal.Body>
                                        <Modal.Footer>
                                          <Button className="data-table__faux-button" onClick={(e) => { handleCloseDeleteControlModal(e) }}>
                                            Close
                                          </Button>
                                          <Button
                                            className="data-table__faux-button"
                                            style={boxStyle}
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                            variant="danger"
                                            onClick={removeControl}
                                            data-controls-count={controlsIndex}
                                            data-actions-count={actionsCount[controlsIndex]}
                                          >
                                            Delete
                                          </Button>
                                        </Modal.Footer>
                                      </Modal>

                                    </>
                                  )}

                                  {!submissionUnlocked && <>Controls</>}
                                </div>
                                {submissionUnlocked && (
                                  <textarea
                                    className="canvas-category-controls-and-actions-section-item__input"
                                    id={`canvas-category-controls--${controlsIndex}`}
                                    name={`canvas-category-controls--${controlsIndex}`}
                                    defaultValue={
                                      inputs[`canvas-category-controls--${controlsIndex}`] || ''
                                    }
                                    onChange={handleChange}
                                    rows={5}
                                  ></textarea>
                                )}
                                {!submissionUnlocked && (
                                  <>
                                    <div className="canvas-category-controls-and-actions-section-item__styles">
                                      <p>
                                        {inputs[`canvas-category-controls--${controlsIndex}`]}
                                      </p>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </Cell>
                          <Cell
                            className="canvas-category-controls-and-actions-section canvas-category-controls-and-actions-section--right"
                            small={12}
                            large={6}
                          >
                            <div className="canvas-category-controls-and-actions-section__inner">
                              <div className="canvas-category-controls-and-actions-section-item">
                                {submissionUnlocked && (
                                  <>
                                    {Array.apply(null, {
                                      length:
                                        actionsCount[controlsIndex] > 0
                                          ? actionsCount[controlsIndex]
                                          : 1,
                                    }).map((count, actionsIndex) => (
                                      <div
                                        key={`controls-count-${controlsIndex}--${actionsIndex}`}
                                      >
                                        {inputs[
                                          'canvas-category-actions--' +
                                          controlsIndex +
                                          '-' +
                                          actionsIndex
                                        ] !== '' && (
                                            <>
                                              <div className="canvas-category-controls-and-actions-section-item__title canvas-category-controls-and-actions-section-item__title--action">
                                                {submissionUnlocked && (
                                                  <>
                                                    <span
                                                      data-tooltip-id="action-tooltip"
                                                      data-tooltip-content="Steps for the control to be put in place"
                                                      data-tooltip-place="top"
                                                    >
                                                      Action{' '}
                                                    </span>
                                                    <button
                                                      className="canvas-category-controls-and-actions-section-item__delete-button"
                                                      id="canvas-category-controls-and-actions-section-item__delete-button"
                                                      onClick={(e) => handleOpenDeleteActionModal(e, controlsIndex + "+" + actionsIndex)}
                                                      data-controls-count={controlsIndex}
                                                      data-actions-count={actionsIndex}
                                                    >
                                                      X
                                                    </button>

                                                    <Modal show={deleteActionModal === (controlsIndex + "+" + actionsIndex)} 
                                                     style={{ paddingTop: "20px" }} 
                                                     id={controlsIndex + "+" + actionsIndex}
                                                     >
                                                      <Modal.Header>
                                                        <Modal.Title><b>Delete Action</b></Modal.Title>
                                                      </Modal.Header>
                                                      <Modal.Body>Are you sure you want to delete this action?</Modal.Body>
                                                      <Modal.Footer>
                                                        <Button className="data-table__faux-button" onClick={(e) => { handleCloseDeleteActionModal(e) }}>
                                                          Close
                                                        </Button>
                                                        <Button
                                                          onClick={removeAction}
                                                          className="data-table__faux-button"
                                                          style={boxStyle}
                                                          onMouseEnter={handleMouseEnter}
                                                          onMouseLeave={handleMouseLeave}
                                                          data-controls-count={controlsIndex}
                                                          data-actions-count={actionsIndex}
                                                        >
                                                          Delete
                                                        </Button>
                                                      </Modal.Footer>
                                                    </Modal>

                                                  </>
                                                )}
                                                {!submissionUnlocked && <>Actions</>}
                                              </div>

                                              <textarea
                                                className="canvas-category-controls-and-actions-section-item__input canvas-category-controls-and-actions-section-item__input--action"
                                                id={`canvas-category-actions--${controlsIndex}-${actionsIndex}`}
                                                name={`canvas-category-actions--${controlsIndex}-${actionsIndex}`}
                                                onChange={handleChange}
                                                value={
                                                  inputs[
                                                  `canvas-category-actions--${controlsIndex}-${actionsIndex}`
                                                  ] || ''
                                                }
                                                rows={5}
                                              ></textarea>
                                            </>
                                          )}
                                      </div>
                                    ))}
                                    <div className="canvas-category-controls-and-actions-section-button-wrap">
                                      <button
                                        className="canvas-category-controls-and-actions-section-button canvas-category-controls-and-actions-section-button--action"
                                        id="canvas-category-controls-and-actions-section-add-button"
                                        onClick={addAction}
                                        data-controls-count={controlsIndex}
                                      >
                                        Add another action
                                        <span className="canvas-category-controls-and-actions-section-button__icon">
                                          <PlusCircle size={16}                                         
                                            data-controls-count={controlsIndex}
                                          />
                                        </span>
                                      </button>
                                    </div>
                                  </>
                                )}

                                {!submissionUnlocked && (
                                  <>
                                    {Array.apply(null, {
                                      length:
                                        actionsCount[controlsIndex] > 0
                                          ? actionsCount[controlsIndex]
                                          : 1,
                                    }).map((count, actionsIndex) => (
                                      <div
                                        className="canvas-category-controls-and-actions-section-item__styles"
                                        key={`controls-count-${controlsIndex}--${actionsIndex}`}
                                      >
                                        <p>
                                          {inputs[
                                            `canvas-category-actions--${controlsIndex}-${actionsIndex}`
                                          ] || ''}
                                        </p>
                                      </div>
                                    ))}
                                  </>
                                )}
                              </div>
                            </div>
                          </Cell>
                        </>
                      )}
                    </Grid>
                  ))}

                  <Tooltip id="control-tooltip" />
                  <Tooltip id="action-tooltip" />

                  {submissionUnlocked && (
                    <Grid className="canvas-category-controls-and-actions-section-wrap text-right">
                      <Cell small={12}>
                        <div className="canvas-category-controls-and-actions-section-button-wrap">
                          <button
                            className="canvas-category-controls-and-actions-section-button canvas-category-controls-and-actions-section-button--control"
                            id="canvas-category-controls-and-actions-section-add-button"
                            onClick={addControl}
                          >
                            Add another control
                            <span className="canvas-category-controls-and-actions-section-button__icon">
                              <PlusCircle size={16} />
                            </span>
                          </button>
                        </div>

                        <div className="page-content">
                          <span
                            className="page-content__button"
                            id="canvas-category-controls-and-actions-section--save"
                            onClick={onSave}
                          >
                            Save for later
                            <span className="page-content__button-icon">
                              <Save size={16} />
                            </span>
                          </span>
                        </div>
                      </Cell>
                    </Grid>
                  )}
                </form>
              </Cell>
            </Grid>

            {!newRecord && data && submissionUnlocked && (
              <div className="canvas-category-footer-wrap">
                <Grid className="grid-padding-x">
                  <Cell small={12} large={9}>
                    <p>
                      When you have completed your conversation, you can mark the conversation
                      as complete. This will lock the content so it can no longer be edited but
                      you will be able to return to review the content and download as a PDF.
                    </p>
                  </Cell>
                  <Cell small={12} large={3} className="text-right">
                    <Link
                      to={`/canvases/${data.id}/confirmations/new`}
                      id="page-navigation-button--complete"
                      className="canvas-category-footer-button"
                    >
                      Save and finish
                    </Link>
                  </Cell>
                </Grid>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
