// Backend
import React from 'react';
import axios from 'axios';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality
import { useNavigate, useLocation } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  Flashable,
  ProgressBar,
  disableFormButton,
  enableFormButton,
} from '../../constants/SharedComponents';

export function AuthenticationsNew() {
  // API
  const navigate = useNavigate();
  const { state } = useLocation();

  // Objects
  const [loading, setLoading] = React.useState(false);
  const [flashMessageObject, setFlashMessageObject] = React.useState({});
  const [inputs, setInputs] = React.useState({});
  const basicFormButtons = React.useRef([]);

  // Functions
  function userId() {
    if (secureLocalStorage.getItem('userAuthentications')) {
      try {
        return JSON.parse(secureLocalStorage.getItem('userAuthentications')).userId;
      } catch {}
    }
  }

  function createSubmission(formInputs) {
    const Api = new DluchApi('AuthenticationsNew');

    let requestPayload = { otp: formInputs.otp_code, userId: userId() };

    axios
      .post(Api.ApiRequestUrl(), requestPayload, { timeout: 30000 })
      .then((response) => {
        let responseData = response.data.content;

        const redirectTo = '/conversations';
        secureLocalStorage.removeItem('userAuthentications');
        secureLocalStorage.setItem('userDetails', JSON.stringify(responseData));
        navigate(redirectTo);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          setFlashMessageObject(Flashable(error.response.data.errorDesc, 'alert', false));
        } else {
          setFlashMessageObject(Flashable(error.message, 'alert', false));
        }

        enableFormButton(basicFormButtons);
        setLoading(false);
      });
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    disableFormButton(basicFormButtons);
    createSubmission(inputs);
  };

  React.useEffect(() => {
    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [state]);

  // Content
  return (
    <>
      <ProgressBar loading={loading} />

      <Header />

      <div className="grid-container">
        <Grid className="grid-padding-x align-justify">
          <Cell small={12} large={6}>
            <div className="page-content">
              <h1 className="page-content__title page-content__title--with-subtext">
                Welcome to FWD Together
                <span className="page-content__title-subtext">
                  , a ground breaking tool that can improve workplace well being one conversation at
                  a time.
                </span>
              </h1>

              <div className="page-content__styles">
                <p>
                  Please enter your one time password code that you should have received by email.
                </p>

                <p>Funded by DLUHC Local Digital Fund</p>
              </div>
            </div>
          </Cell>

          <Cell small={12} large={5}>
            <div className="basic-form basic-form--background">
              <h2 className="basic-form__title">Verify your account</h2>

              <p>
                We have sent you an email with a 6 digit code. Please enter the code here to
                complete sign-in.
              </p>

              <FlashMessage object={flashMessageObject} columnWrap={false} />

              <form className="basic-form" onSubmit={handleSubmit}>
                <div className="basic-form__field">
                  <label
                    htmlFor="email_address"
                    className="basic-form__label  basic-form__label--sr-only"
                  >
                    One time password code:
                  </label>

                  <input
                    className="basic-form__input"
                    type="number"
                    name="otp_code"
                    id="otp_code"
                    value={inputs.otp_code || ''}
                    onChange={handleChange}
                    required={true}
                    placeholder="000000"
                  />
                </div>

                <div className="basic-form__actions">
                  <input
                    value="Sign-In"
                    type="submit"
                    className="basic-form__button"
                    id="page-form-button--submit"
                    ref={(ref) => (basicFormButtons.current[0] = ref)}
                  />
                </div>
              </form>
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  );
}
