// Backend
import React from 'react';
import axios from 'axios';

// Frontend
import { Grid, Cell, Link } from 'react-foundation';

// Functionality
import { useNavigate, useLocation, useParams } from 'react-router-dom';

// Components
import {
    Header,
    DluchApi,
    FlashMessage,
    Flashable,
    ProgressBar,
    disableFormButton,
    enableFormButton,
    setToken,
    setUserRoles,
} from '../../../constants/SharedComponents';
import useCurrentUser from '../../../hooks/useCurrentUser';

const AdminUserEdit = () => {
    // API
    const currentUser = useCurrentUser();
    const token = setToken(currentUser);
    const currentUserRole = setUserRoles(currentUser);
    const { userId } = useParams();
    const redirectTo = '/admin/users';
    const navigate = useNavigate();
    const headerParams = { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 };
    const { state } = useLocation();

    // Objects
    const [loading, setLoading] = React.useState(false);
    const [flashMessageObject, setFlashMessageObject] = React.useState({});
    const basicFormButtons = React.useRef([]);
    const [data, setData] = React.useState(null);
    const [roleData, setRoleData] = React.useState('');

    // Functions
    function retryFetch() {
        const currentRole = currentUserRole[0].roleName;
        setRoleData(currentRole);

        if (currentRole === "User") {
            setLoading(false);
            setData(null);
        }
        else {
            setLoading(true);
            setData(null);
            getData();
        }
    }

    const getData = async () => {
        try {
            let Api = new DluchApi('UsersGet', userId);
            const response = await axios.get(Api.ApiRequestUrl(), headerParams);

            let Api2 = new DluchApi('AdminRolesGet');
            const response2 = await axios.get(Api2.ApiRequestUrl(), headerParams);

            let Api3 = new DluchApi('AdminRolesUserGet', userId);
            const response3 = await axios.get(Api3.ApiRequestUrl(), headerParams);

            setData(
                {
                    forename: response.data.content.user.firstname,
                    surname: response.data.content.user.lastname,
                    twoFactorEnabled: response.data.content.user.twoFactorEnabled,
                    isActive: response.data.content.user.isActive,
                    roleOptionValue1: response2.data.content.roles[0].id,
                    roleOptionName1: response2.data.content.roles[0].roleDescription,
                    roleOptionValue2: response2.data.content.roles[1].id,
                    roleOptionName2: response2.data.content.roles[1].roleDescription,
                    selectedValue: response3.data.content.role.id,
                });

        } catch (err) {
            setData(null);
        } finally {
            setLoading(false);
        }
    };

    function createSubmission(formInputs) {
        setFlashMessageObject({});

        let requestPayload = {
            userId: userId,
            roleId: formInputs.selectedValue,
            firstName: formInputs.forename,
            lastName: formInputs.surname,
            isActive: formInputs.isActive,
            twoFactorEnabled: formInputs.twoFactorEnabled
        };

        const Api = new DluchApi('AdminUserEdit');

        axios
            .put(Api.ApiRequestUrl(), requestPayload, headerParams)
            .then(() => {
                setFlashMessageObject(Flashable('Please wait...', 'notice', false));
                navigate(redirectTo);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        setFlashMessageObject(Flashable(error.response.data.errorDesc, 'alert', false));
                    }
                    else {
                        setFlashMessageObject(Flashable(error.message, 'alert', false));
                    }
                } else {
                    setFlashMessageObject(Flashable(error.message, 'alert', false));
                }

                enableFormButton(basicFormButtons);
                setLoading(false);
            });
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setData((values) => ({ ...values, [name]: value }));
    };

    const handleCheckedChange = (event) => {
        const name = event.target.name;
        const value = event.target.checked;
        setData((values) => ({ ...values, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        disableFormButton(basicFormButtons);
        setLoading(true);
        disableFormButton(basicFormButtons);
        createSubmission(data);
    };

    // Content
    React.useEffect(() => {
        if (currentUser) {
            if (currentUser.token) {
                retryFetch();
            }
        }

        if (state) {
            if (state.hasOwnProperty('flash')) {
                setFlashMessageObject(state.flash);
            }
        }
    }, [currentUser, state]);

    return (
        <>
            <ProgressBar loading={loading} />

            <Header />
            {roleData === "User" && (

                <div className="grid-container">
                    <Grid className="grid-padding-x align-justify">
                        <Cell small={12}>
                            <div className="page-content">
                                <h1 className="page-content__title">
                                    Page not found
                                </h1>
                                <div className="page-content__styles">
                                    <p>
                                        The page you requested cannot be found. Please try again.
                                    </p>
                                </div>
                            </div>
                        </Cell>
                    </Grid>
                </div>
            )
            }
            {
                (roleData === "Admin" || roleData === "SystemAdmin") && (
                    <div className="grid-container">
                        <Grid className="grid-padding-x align-justify">

                            {data && (
                                <>
                                    <Cell small={12} large={12}>
                                        <div>
                                            <Link
                                                href="/admin/users"
                                                id="page-navigation-button--create"
                                                className="primary-header__button"
                                                style={{ fontWeight: "bold", fontSize: "12pt" }}
                                            >
                                                Back
                                            </Link>
                                        </div>
                                    </Cell>
                                    <Cell small={12} large={3}></Cell>

                                    <Cell small={12} large={5}>
                                        <div className="basic-form basic-form--background">
                                            <h2 className="basic-form__title">Edit User</h2>

                                            <div className="basic-form__styles">
                                                <p>Edit the details for the user below:</p>
                                            </div>

                                            <FlashMessage object={flashMessageObject} columnWrap={false} />

                                            <form className="basic-form" onSubmit={handleSubmit}>
                                                <div className="basic-form__field">
                                                    <label
                                                        htmlFor="forename"
                                                        className="basic-form__label  basic-form__label--sr-only"
                                                    >
                                                        First name:
                                                    </label>

                                                    <input
                                                        className="basic-form__input"
                                                        type="text"
                                                        name="forename"
                                                        id="forename"
                                                        data-testid="forename"
                                                        defaultValue={data.forename || ''}
                                                        onChange={handleChange}
                                                        required={true}
                                                        placeholder="First name"
                                                    />
                                                </div>

                                                <div className="basic-form__field">
                                                    <label
                                                        htmlFor="surname"
                                                        className="basic-form__label  basic-form__label--sr-only"
                                                    >
                                                        Last name:
                                                    </label>

                                                    <input
                                                        className="basic-form__input"
                                                        type="text"
                                                        name="surname"
                                                        id="surname"
                                                        data-testid="surname"
                                                        defaultValue={data.surname || ''}
                                                        onChange={handleChange}
                                                        required={true}
                                                        placeholder="Last name"
                                                    />
                                                </div>

                                                <div className="basic-form__field">
                                                    <label
                                                        htmlFor="Role"
                                                        className="basic-form__label  basic-form__label--sr-only"
                                                    >
                                                        User role:
                                                    </label>

                                                    {/* Insert dropdown for role selector here... */}
                                                    <select
                                                        className="basic-form__input"
                                                        name="selectedValue"
                                                        id="selectedValue"
                                                        data-testid="selectedValue"
                                                        defaultValue={data.selectedValue || ''}
                                                        onChange={handleChange}
                                                        required={true}
                                                    >
                                                        <option
                                                            name="roleOption1"
                                                            id="roleOption1"
                                                            data-testid="roleOption1"
                                                            value={data.roleOptionValue1 || ''}
                                                        >
                                                            {data.roleOptionName1 || ''}
                                                        </option>
                                                        <option
                                                            name="roleOption2"
                                                            id="roleOption2"
                                                            data-testid="roleOption2"
                                                            value={data.roleOptionValue2 || ''}
                                                        >
                                                            {data.roleOptionName2 || ''}
                                                        </option>
                                                    </select>
                                                </div>
                                                <br></br>
                                                <Grid>
                                                    <Cell large={6}>
                                                        <div className="basic-form__field">
                                                            <label htmlFor="isActive" className="basic-form__label">
                                                                Is Active:&nbsp;&nbsp;
                                                            </label>

                                                            <input
                                                                className="basic-form__input"
                                                                type="checkbox"
                                                                name="isActive"
                                                                id="isActive"
                                                                data-testid="isActive"
                                                                defaultChecked={data.isActive || ''}
                                                                defaultValue={data.isActive || ''}
                                                                onChange={handleCheckedChange}
                                                            />
                                                        </div>
                                                    </Cell>
                                                    <Cell large={6}>
                                                        <div className="basic-form__field">
                                                            <label htmlFor="twoFactorEnabled" className="basic-form__label">
                                                                Two Factor Enabled:&nbsp;&nbsp;
                                                            </label>

                                                            <input
                                                                className="basic-form__input"
                                                                type="checkbox"
                                                                name="twoFactorEnabled"
                                                                id="twoFactorEnabled"
                                                                data-testid="twoFactorEnabled"
                                                                defaultChecked={data.twoFactorEnabled || ''}
                                                                defaultValue={data.twoFactorEnabled || ''}
                                                                onChange={handleCheckedChange}
                                                            />
                                                        </div>
                                                    </Cell>
                                                </Grid>

                                                <br></br>


                                                <div className="basic-form__actions">
                                                    <input
                                                        value="Save User"
                                                        type="submit"
                                                        className="basic-form__button"
                                                        data-testid="registration-form-submit"
                                                        id="page-form-button--submit"
                                                        ref={(ref) => (basicFormButtons.current[0] = ref)}
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                    </Cell>

                                    <Cell small={12} large={3}></Cell>
                                </>
                            )}
                        </Grid>
                    </div>
                )
            }
        </>
    );
};

export default AdminUserEdit;
