// Backend
import React from 'react';

// Frontend
import { Tooltip } from 'react-tooltip';

// Functionality
import { Link } from 'react-router-dom';

function Sidebar({ submissionUnlocked }) {
  const onClick = (event, nodeType) => {

  };

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <>
      {submissionUnlocked && (
        <div className="reactflow-aside">
          <p className="reactflow-aside__description">Add by clicking and dragging.</p>

          <ul className="reactflow-aside-nodes-list">
            <li
              className="reactflow-aside-nodes-list__item reactflow-aside-nodes-list__item--why"
              onDragStart={(event) => onDragStart(event, 'whyNode')}
              onClick={(event) => onClick(event, 'whyNode')}
              draggable
              data-tooltip-id="why-tooltip"
              data-tooltip-content="Causes that make the risk occur"
              data-tooltip-place="top"
            >
              Why
            </li>
            <li
              className="reactflow-aside-nodes-list__item reactflow-aside-nodes-list__item--impact"
              onDragStart={(event) => onDragStart(event, 'impactNode')}
              draggable
              data-tooltip-id="impact-tooltip"
              data-tooltip-content="Consequences of the risk for the work"
              data-tooltip-place="top"
            >
              Impact
            </li>
          </ul>

          <Tooltip id="why-tooltip" />
          <Tooltip id="impact-tooltip" />
        </div>
      )}
    </>
  );
}

export default Sidebar;
