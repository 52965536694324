// Frontend
import { Grid, Cell } from 'react-foundation';
import '../stylesheets/components/FlashMessages.css';

const FlashMessage = ({ object, columnWrap = false, className = '' }) => {
  // @see https://stackoverflow.com/a/66359848
  window.history.replaceState({}, document.title);

  const flashMessageContent = function (flashMessage, classNameOverride) {
    return (
      <div
        className={
          classNameOverride
            ? classNameOverride
            : `flash-message flash-message--${flashMessage.className}`
        }
      >
        {flashMessage.message}
      </div>
    );
  };

  return (
    <>
      {object && object.hasOwnProperty('message') && (
        <>
          {!className && columnWrap && (
            <>
              <div className="grid-container">
                <Grid className="grid-padding-x align-justify">
                  <Cell small={12}>{flashMessageContent(object, className)}</Cell>
                </Grid>
              </div>
            </>
          )}

          {className && columnWrap && <>{flashMessageContent(object, className)}</>}

          {!columnWrap && flashMessageContent(object)}
        </>
      )}
    </>
  );
};

export default FlashMessage;
