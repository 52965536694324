// Backend
import React from 'react';
import axios from 'axios';
import history from '../../../helpers/history';

// Frontend
import Modal from 'react-modal';
import { Grid, Cell } from 'react-foundation';
import { XCircle, PlusCircle, FileEarmarkPlus } from 'react-bootstrap-icons';
import '../../../stylesheets/components/Categories.css';

// Functionality
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  Flashable,
  ProgressBar,
  disableFormButton,
  enableFormButton,
  ErrorRender,
  setToken,
  filteredCategories,
} from '../../../constants/SharedComponents';

import useCurrentUser from '../../../hooks/useCurrentUser';
import QuestionCategory from '../../../components/QuestionCategory';

export function ConversationsCategoriesEdit() {
  // Params
  const { conversationId } = useParams();
  const navigate = useNavigate();

  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();
  const token = setToken(currentUser);
  const headerParams = { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 };

  // Objects
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [flashMessageObject, setFlashMessageObject] = React.useState({});
  const [inputs, setInputs] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [questionResultData, setQuestionResultData] = React.useState(null);
  const [questionCategoriesData, setQuestionCategoriesData] = React.useState(null);
  const [deselectedCategories, setDeselectedCategories] = React.useState([]);
  const [selectedCategories, setSelectedCategories] = React.useState([]);
  const [currentModal, setCurrentModal] = React.useState(null);
  const [submissionUnlocked, setSubmissionUnlocked] = React.useState(false);
  const [guidedRoute, setGuidedRoute] = React.useState(null);
  const [formChanged, setFormChanged] = React.useState(false);
  const [pageNavigation, setPageNavigation] = React.useState({});
  const [questionScores, setQuestionScores] = React.useState(null);

  let basicForm = React.useRef([]);
  const basicFormButtons = React.useRef([]);
  const categoryFormButtons = React.useRef([]);

  // Functions
  function openModal(event) {
    event.preventDefault();
    setCurrentModal(event.target.getAttribute('data-modal-id'));
  }

  function afterOpenModal() {
  }

  function closeModal() {
    setCurrentModal(null);
    handleSave();
  }

  function processFormData(formInputs) {
    let categoryIds = [];
    let index = 0;
    let questionResults = [];

    for (const key of Object.keys(formInputs)) {
      let matches = key.split('--');

      if (matches[1] === undefined || matches[1] === 'null') {
        continue;
      }

      categoryIds.push(matches[1]);
    }

    categoryIds = Array.from(new Set(categoryIds));

    for (const categoryId in categoryIds) {
      const key = 'notes--' + categoryIds[categoryId];

      let notes = '';

      if (formInputs.hasOwnProperty(key)) {
        notes = formInputs[key];
      }

      let formData = {};

      formData['notes'] = notes;
      formData['isConversationTopic'] =
        formInputs['isConversationTopic--' + categoryIds[categoryId]] !== undefined
          ? formInputs['isConversationTopic--' + categoryIds[categoryId]]
          : false;
      formData['conversationId'] = conversationId;
      formData['categoryId'] = categoryIds[categoryId];
      formData['priorityRank'] = formInputs['priorityRank--' + categoryIds[categoryId]];
      formData['optionalCategoryName'] =
        formInputs['optionalCategoryName--' + categoryIds[categoryId]];
      questionResults[index] = formData;
      index++;
    }

    return questionResults;
  }

  const sortedQuestionResultData = function (categoryData) {
    let responseData = categoryData.sort(function (a, b) {
      let weight = -1;

      if (a.priorityRank > b.priorityRank) {
        weight = 1;
      } else if (a.priorityRank === b.priorityRank) {
        weight = a.categoryName > b.categoryName ? 1 : -1;
      }

      return weight;
    });

    responseData = responseData.reverse();

    responseData = responseData.sort(function (a, b) {
      return a.categoryType - b.categoryType;
    });

    return responseData;
  };

  function createSubmission(formInputs, complete) {
    const redirectTo = '/conversations/' + data.id + '/confirmations/new';
    let questionResults = processFormData(formInputs);
    let Api = new DluchApi('QuestionResultsEdit');
    let requestPayload;

    // For loop though selectedCategories, add the questionResult with the same category to a new questionResults array (modify the priorityRank)
    // Do the same after for deselected categories
    // This should get the exact order of the categories

    let newQuestionResults = []
    let newPriorityRank = 1;

    let chosenCategories = selectedCategories.map(obj => ({ ...obj }));
    let notChosenCategories = fixNotSelectedCategoryList(chosenCategories);

    for (const category of chosenCategories) {
      for (const result of questionResults) {
        if (result.categoryId === category.id) {
          let newResult = result;
          newResult.priorityRank = newPriorityRank;
          newQuestionResults.push(result);
          newPriorityRank++;
          break;
        }
      }
    }

    for (const category of notChosenCategories) {
      for (const result of questionResults) {
        if (result.categoryId === category.id) {
          let newResult = result;
          newResult.priorityRank = newPriorityRank;
          newQuestionResults.push(result);
          newPriorityRank++;
          break;
        }
      }
    }

    requestPayload = { questionResults: newQuestionResults, generalNotes: formInputs.general_notes };

    axios
      .put(Api.ApiRequestUrl(), requestPayload, headerParams)
      .then(() => {
        setFormChanged(false);

        if (complete) {
          setPageNavigation({
            to: redirectTo,
            options: {},
          });
        } else {
          setFlashMessageObject(Flashable('Changes were successfully saved.', 'notice', false));
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          setFlashMessageObject(Flashable(error.response.data.errorDesc, 'alert', false));
        } else {
          setFlashMessageObject(Flashable(error.message, 'alert', false));
        }
      });

    enableFormButton(basicFormButtons);
    setLoading(false);
  }

  const fixNotSelectedCategoryList = function (chosenCategories) {
    let notChosenCategories = deselectedCategories.map(obj => ({ ...obj }));

    for (const category of chosenCategories) {
      for (const nCategory of notChosenCategories) {
        if (category.id === nCategory.id) {
          let index = notChosenCategories.indexOf(nCategory);
          notChosenCategories.splice(index, 1);
        }
      }
    }

    return notChosenCategories;
  }

  function createQuestionResults(inputs, complete) {
    setLoading(true);

    let Api = new DluchApi('QuestionsNew');
    let questionScoresData = JSON.parse(localStorage.getItem('questionScores'));
    let requestPayload = {
      questionScores: questionScoresData['questionScores'].flat(),
    };

    axios
      .post(Api.ApiRequestUrl(), requestPayload, headerParams)
      .then(() => {
        localStorage.removeItem('questionScores');
        createSubmission(inputs, complete);
      })
      .catch((error) => {
        if (error.response?.data) {
          setFlashMessageObject(Flashable(error.response.data.errorDesc, 'alert', false));
        } else {
          setFlashMessageObject(Flashable(error.message, 'alert', false));
        }
        setLoading(false);
      });
  }

  const getQuestionScores = async () => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionScoresShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.sortedScores;
      setError(false);
      getTemperatureQuestionCategory(responseData[0]);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const getTemperatureQuestionCategory = async (questionScores) => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionsCategoriesIndex', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.categories;
      let result = filteredCategories(responseData, 3);
      getQuestionOptions(questionScores, result[0]);
      setError(false);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const getQuestionOptions = async (questionScores, temperatureQuestionCategory) => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionsIndex', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content;
      let result = responseData.sortedQuestions.find(
        (obj) => obj.categoryId === temperatureQuestionCategory.id,
      );
      questionScores.optionText = JSON.parse(result.questionOptions).find(
        (obj) => obj.OptionScore === questionScores.score,
      ).OptionText;
      setQuestionScores(questionScores);
      setError(false);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const getData = async () => {
    setLoading(true);

    try {
      let Api = new DluchApi('ConversationsShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.conversation;
      const conversationSubmissionUnlocked = responseData.employeePrepStatus !== 2;
      setData(responseData);

      setSubmissionUnlocked(conversationSubmissionUnlocked);
      getQuestionCategoriesData(conversationSubmissionUnlocked);
      setError(false);

      if (!conversationSubmissionUnlocked) {
        getQuestionScores();
      }
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const getQuestionCategoriesData = async (conversationSubmissionUnlocked) => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionsCategoriesIndex', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.categories;
      let allCategories = filteredCategories(responseData, 3, false);
      setError(false);
      getQuestionResultData(allCategories, conversationSubmissionUnlocked);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const getQuestionResultData = async (questionCategories, conversationSubmissionUnlocked) => {
    setLoading(true);

    try {
      let Api = new DluchApi('QuestionResultsShow', conversationId);
      let defaultInputs = {};
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);

      defaultInputs['general_notes'] = response.data.content.generalNotes;

      let responseData = sortedQuestionResultData(response.data.content.results);

      let chosenCategories = responseData.filter((obj) => obj.isConversationTopic === true);
      let notChosenCategories = responseData.filter((obj) => obj.isConversationTopic !== true);

      chosenCategories = chosenCategories.map((obj) => obj.categoryId);
      notChosenCategories = notChosenCategories.map((obj) => obj.categoryId);
      chosenCategories = questionCategories.filter((obj) => chosenCategories.includes(obj.id));
      notChosenCategories = questionCategories.filter((obj) =>
        notChosenCategories.includes(obj.id),
      );

      for (const conversationTopic of responseData) {
        defaultInputs['isConversationTopic--' + conversationTopic.categoryId] =
          conversationTopic.isConversationTopic;
        defaultInputs['priorityRank--' + conversationTopic.categoryId] =
          conversationTopic.priorityRank;
        defaultInputs['notes--' + conversationTopic.categoryId] = conversationTopic.notes;
        defaultInputs['optionalCategoryName--' + conversationTopic.categoryId] =
          conversationTopic.optionalCategoryName;
      }

      let guidedOptionalCategories = filteredCategories(questionCategories, 1);
      guidedOptionalCategories = guidedOptionalCategories.map((obj) => obj.id);

      let selfGuidedCategories = filteredCategories(questionCategories, 2);
      selfGuidedCategories = selfGuidedCategories.map((obj) => obj.id);

      for (let row of questionCategories) {
        row.selfGuided =
          guidedOptionalCategories.includes(row.id) || selfGuidedCategories.includes(row.id);
      }

      if (!conversationSubmissionUnlocked) {
        for (let row of questionCategories) {
          row.delete = row.selfGuided && !row.optionalCategoryName;
        }

        for (let row of notChosenCategories) {
          row.delete = row.selfGuided && !row.optionalCategoryName;
        }

        questionCategories = questionCategories.filter((obj) => obj.delete !== true);
        notChosenCategories = notChosenCategories.filter((obj) => obj.delete !== true);
      }

      let sortedChosenResults = sortChosenResults(chosenCategories, responseData);
      let sortedNotChosenResults = sortNotChosenResults(notChosenCategories, responseData);

      sortedChosenResults.sort(function (a, b) { return a.priorityRank - b.priorityRank });
      sortedNotChosenResults.sort(function (a, b) { return a.priorityRank - b.priorityRank });

      let sortedChosenCategories = sortChosenCategories(sortedChosenResults, chosenCategories);
      let sortedNotChosenCategories = sortNotChosenCategories(sortedNotChosenResults, notChosenCategories);

      setQuestionCategoriesData(questionCategories);
      setSelectedCategories(sortedChosenCategories);
      setDeselectedCategories(sortedNotChosenCategories);
      setInputs(defaultInputs);
      setQuestionResultData(responseData);
      setError(false);

      setGuidedRoute(true);
    } catch (err) {
      setSelectedCategories([]);
      setGuidedRoute(false);

      let defaultInputs = {};

      let selfGuidedCategories = filteredCategories(questionCategories, 2);
      selfGuidedCategories = selfGuidedCategories.map((obj) => obj.id);

      let guidedOptionalCategories = filteredCategories(questionCategories, 1);
      guidedOptionalCategories = guidedOptionalCategories.map((obj) => obj.id);

      for (let row of questionCategories) {
        row.selfGuided = selfGuidedCategories.includes(row.id);
        row.delete = guidedOptionalCategories.includes(row.id);
      }

      const filteredQuestionCategories = questionCategories.filter((obj) => obj.delete !== true);

      for (let i = 0; i < filteredQuestionCategories.length; ++i) {
        const category = filteredQuestionCategories[i];
        defaultInputs['isConversationTopic--' + category.id] =
          false;
        defaultInputs['priorityRank--' + category.id] =
          i;
        defaultInputs['notes--' + category.id] = "";
        defaultInputs['optionalCategoryName--' + category.id] =
          null;
      }

      setQuestionCategoriesData(filteredQuestionCategories);
      setDeselectedCategories(filteredQuestionCategories);
      setInputs(defaultInputs);
    } finally {
      setLoading(false);
    }
  };

  const sortChosenResults = function (chosenCategories, responseData) {
    let sortedChosenResults = [];

    for (const category of chosenCategories) {
      for (const result of responseData) {
        if (result.categoryId === category.id) {
          sortedChosenResults.push(result);
          break;
        }
      }
    }

    return sortedChosenResults;
  }

  const sortNotChosenResults = function (notChosenCategories, responseData) {
    let sortedNotChosenResults = [];

    for (const category of notChosenCategories) {
      for (const result of responseData) {
        if (result.categoryId === category.id) {
          sortedNotChosenResults.push(result);
          break;
        }
      }
    }

    return sortedNotChosenResults;
  }

  const sortChosenCategories = function (sortedChosenResults, chosenCategories) {
    let sortedChosenCategories = [];

    for (const result of sortedChosenResults) {
      for (const category of chosenCategories) {
        if (category.id === result.categoryId) {
          sortedChosenCategories.push(category);
          break;
        }
      }
    }

    return sortedChosenCategories;
  }

  const sortNotChosenCategories = function (sortedNotChosenResults, notChosenCategories) {
    let sortedNotChosenCategories = [];

    for (const result of sortedNotChosenResults) {
      for (const category of notChosenCategories) {
        if (category.id === result.categoryId) {
          sortedNotChosenCategories.push(category);
          break;
        }
      }
    }

    return sortedNotChosenCategories;
  }

  function retryFetch() {
    setLoading(true);
    setError(false);
    setData(null);
    getData();
  }

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (currentUser?.token) {
      retryFetch();
    }

    if (localStorage.getItem('questionScores') !== null) {
      setFormChanged(true);
    }

    if (state?.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  React.useEffect(() => {
    let unblock;
    if (formChanged) {
      unblock = history.block((tx) => {
        if (
          window.confirm(
            `You have unsaved changes. If you navigate away from this page, these changes will be lost.`,
          )
        ) {
          unblock();
          tx.retry();
        }
      });
    }

    if (!formChanged && pageNavigation) {
      navigate(pageNavigation.to, pageNavigation.options);
    }

    return () => {
      if (typeof unblock === 'function') {
        unblock();
      }
    };
  }, [formChanged, pageNavigation]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    disableFormButton(basicFormButtons);
    let complete = event.target.getAttribute('data-complete') === 'true';

    if (localStorage.getItem('questionScores') !== null) {
      createQuestionResults(inputs, complete);
    } else {
      createSubmission(inputs, complete);
    }
  };

  const handleSave = () => {
    if (submissionUnlocked) {
      setLoading(true);
      disableFormButton(basicFormButtons);
      let complete = false;

      if (localStorage.getItem('questionScores') !== null) {
        createQuestionResults(inputs, complete);
      } else {
        createSubmission(inputs, complete);
      }
    }
  };

  const pageAnchorNavigation = (event) => {
    event.preventDefault();

    const href = event.target.getAttribute('href');
    if (href.includes('#')) {
      const id = `${href.substring(href.indexOf('#') + 1)}`;
      const anchor = document.getElementById(id);
      if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    setFormChanged(true);
  };

  const selectCategory = (event) => {
    event.preventDefault();
    setFormChanged(true);
    setLoading(true);

    let category = deselectedCategories.find((obj) => {
      return obj.id === event.target.getAttribute('data-category-id');
    });

    if (category) {
      let formInputs = inputs;
      let selectCategories = selectedCategories;

      formInputs['isConversationTopic--' + category.id] = true;
      setInputs(formInputs);

      let unselectedCategories = deselectedCategories.filter((obj) => {
        return obj.id !== event.target.getAttribute('data-category-id');
      });

      selectCategories.push(category);
      setSelectedCategories(sortedQuestionResultData(selectCategories));
      setDeselectedCategories(sortedQuestionResultData(unselectedCategories));
    }

    setLoading(false);

    handleSave();
  };

  const deselectCategory = (event) => {
    event.preventDefault();
    setFormChanged(true);
    setLoading(true);

    let category = selectedCategories.find((obj) => {
      return obj.id === event.target.parentNode.getAttribute('data-category-id');
    });

    if (category) {
      let formInputs = inputs;

      formInputs['isConversationTopic--' + category.id] = false;
      setInputs(formInputs);

      let selectCategories = selectedCategories.filter((obj) => {
        return obj.id !== event.target.parentNode.getAttribute('data-category-id');
      });

      deselectedCategories.push(category);
      setSelectedCategories(sortedQuestionResultData(selectCategories));
      setDeselectedCategories(sortedQuestionResultData(deselectedCategories));
    }

    setLoading(false);

    handleSave();
  };

  const questionResultsContent = (questionCategoryId, field) => {
    if (inputs[field + '--' + questionCategoryId]) {
      return inputs[field + '--' + questionCategoryId];
    }

    if (!questionResultData) {
      return;
    }

    const category = questionResultData.find((obj) => {
      return obj.categoryId === questionCategoryId;
    });

    if (category) {
      return category[field];
    }
  };

  return (
    <>
      <ProgressBar loading={loading} />
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />
      <ErrorRender object={error} />

      {data && questionCategoriesData && (
        <div className="grid-container">
          <Grid className="grid-padding-x">
            <Cell small={12}>
              {submissionUnlocked && guidedRoute === true && (
                <>
                  <div className="page-notice">
                    <h1 className="page-notice__title">Survey complete</h1>
                    <div className="page-notice__content">
                      <p>
                        Based on your scores, we have preselected three suggested topics for your
                        conversation. These are the three areas in which your score suggested most
                        prevalent risks to your wellbeing. You can read more about how the results
                        are calculated in our{' '}
                        <Link to="/resources" target="_blank" rel="noopener noreferrer">
                          resources area
                        </Link>
                        .
                      </p>
                    </div>
                  </div>

                  <div className="page-content">
                    <h3 className="page-content__subtitle page-content__subtitle--section">
                      What you need to do
                    </h3>
                  </div>
                </>
              )}

              {submissionUnlocked && guidedRoute === false && (
                <>
                  <div className="page-notice">
                    <h1 className="page-notice__title">Self-reflection route</h1>
                    <div className="page-notice__content">
                      <p>
                        Please consider up to the three characteristics you would like to take to
                        your meeting. You can choose from the 7 characteristics listed or include
                        specific issues of your own.
                      </p>

                      <p>
                        It is really helpful to think about what specific aspects of each work
                        characteristic is important to discuss with your manager and also what are
                        the effects of this on you.
                      </p>
                    </div>
                  </div>

                  <div className="page-content__styles">
                    <h2 className="page-content__subtitle page-content__subtitle--section">
                      What you need to do
                    </h2>

                    <p>
                      Please choose up to three topics from the list below and then press submit.
                    </p>
                  </div>
                </>
              )}

              {!submissionUnlocked && (
                <div className="page-content">
                  <h1 id="selected-categories" className="page-content__title">
                    Team Member Prep - Submitted
                  </h1>
                </div>
              )}

              {!data.isUserManager && (
                <>
                  {!submissionUnlocked && (
                    <div className="grid-container">
                      <Grid className="grid-padding-x">
                        <Cell small={12}>
                          <div className="page-content">
                            <div className="page-content__styles">
                              <p>
                                Your prep information has been submitted and can no longer be
                                amended.
                              </p>

                              {questionScores && (
                                <>
                                  <h3 className="page-content__subtitle">
                                    {questionScores.questionText}
                                  </h3>

                                  <div className="page-content__styles">
                                    <p>
                                      <b>{questionScores.optionText}</b>
                                    </p>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </Cell>
                      </Grid>
                    </div>
                  )}

                  <form
                    className="basic-form"
                    ref={(ref) => (basicForm = ref)}
                    id="selected-categories"
                  >
                    <div className="question-categories-choice">
                      {submissionUnlocked && (
                        <>
                          <h2 className="question-categories-choice__title">
                            1) Review the conversation topics
                          </h2>

                          <div className="question-categories-choice__styles">
                            <p>
                              When considering the topics to take to your manager meeting, please
                              take time to explore them and reflect upon whether and how they
                              cause you stress.
                            </p>

                            <p>
                              We have a useful guide on how to complete your team member prep in
                              our resources area.{' '}
                              <Link
                                to={
                                  process.env.PUBLIC_URL + '/Team Member Preparation Guidance.pdf'
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View team member prep guide.
                              </Link>
                            </p>
                          </div>
                        </>
                      )}

                      {!submissionUnlocked && (
                        <h2 className="question-categories-choice__title">
                          Your conversation topics
                        </h2>
                      )}

                      <Grid className="grid-padding-x">
                        {selectedCategories?.map((questionCategory) => (
                          <Cell
                            small={12}
                            large={4}
                            key={'selected-category-' + questionCategory.id}
                          >
                            <QuestionCategory
                              afterOpenModal={afterOpenModal}
                              categoryFormButtons={categoryFormButtons}
                              closeModal={closeModal}
                              currentModal={currentModal}
                              deselectCategory={deselectCategory}
                              handleChange={handleChange}
                              openModal={openModal}
                              questionCategory={questionCategory}
                              questionResultsContent={questionResultsContent}
                              selectCategory={null}
                              selectedCategories={selectedCategories}
                              submissionUnlocked={submissionUnlocked}
                              showSummary={true}
                              showNotes={submissionUnlocked}
                            />
                          </Cell>
                        ))}

                        {submissionUnlocked &&
                          selectedCategories.length < 3 &&
                          Array.apply(null, Array(3 - selectedCategories.length))
                            .map(function (x, i) {
                              return i;
                            })
                            .map((questionCategory) => (
                              <Cell
                                small={12}
                                large={4}
                                key={'pending-category-' + questionCategory}
                              >
                                <div className="category-form-field-wrap">
                                  <div className="category-form-field category-form-field--empty">
                                    <HashLink
                                      to="#deselected-categories"
                                      className="category-form-field__button category-form-field__button--secondary"
                                      id="page-function-button--add"
                                      onClick={pageAnchorNavigation}
                                    >
                                      Add topic
                                      <PlusCircle
                                        size={21}
                                        className="category-form-field__button-icon category-form-field__button-icon--empty util--hide-icon"
                                      />
                                    </HashLink>
                                  </div>
                                </div>
                              </Cell>
                            ))}
                      </Grid>
                    </div>

                    {!submissionUnlocked && (
                      <div className="page-content">
                        <h3 className="page-content__title">Your notes</h3>

                        {selectedCategories?.map(
                          (questionCategory) =>
                            questionResultsContent(questionCategory.id, 'notes') && (
                              <>
                                <h3 className="page-content__subtitle">
                                  Notes for{' '}
                                  {questionResultsContent(
                                    questionCategory.id,
                                    'optionalCategoryName',
                                  )
                                    ? questionResultsContent(
                                      questionCategory.id,
                                      'optionalCategoryName',
                                    )
                                    : questionCategory.name}
                                </h3>
                                {questionResultsContent(questionCategory.id, 'notes')}
                              </>
                            ),
                        )}
                      </div>
                    )}

                    {submissionUnlocked && (
                      <>
                        <div className="question-categories-choice">
                          <h2 className="question-categories-choice__title">2) Make notes</h2>
                          <Grid className="grid-padding-x">
                            <Cell small={12} large={9}>
                              <div className="question-categories-choice__styles question-categories-choice__styles--microcopy">
                                <p>
                                  Take time to understand the topics and review the examples then
                                  make any notes that you feel will help you to ensure that you
                                  include everything you would like to communicate in the meaning.
                                </p>
                              </div>
                            </Cell>

                            <Cell small={12} large={3} className="text-right">
                              <button
                                onClick={openModal}
                                data-modal-id="category-private-notes"
                                className="basic-form__button basic-form__button--secondary basic-form__button--inline basic-form__button--with-icons-right"
                                id={`page-function-button--input-private-notes`}
                              >
                                Add private notes{' '}
                                <span className="category-form-field-notes__icons util--hide-icon">
                                  <FileEarmarkPlus
                                    onClick={openModal}
                                    data-modal-id="category-private-notes"
                                    size={18}
                                  />
                                </span>
                              </button>
                            </Cell>
                          </Grid>
                        </div>

                        <div className="question-categories-choice">
                          <h2 className="question-categories-choice__title">
                            3) Submit your prep
                          </h2>

                          <Grid className="grid-padding-x">
                            <Cell small={12} large={9}>
                              <div className="question-categories-choice__styles question-categories-choice__styles--microcopy">
                                <p>When you are happy with the topics you have chosen</p>
                              </div>
                            </Cell>
                            <Cell small={12} large={3} className="text-right">
                              {formChanged && (
                                <>
                                  {selectedCategories.length >= 1 && (
                                    <div className="basic-form__actions basic-form__actions--inline">
                                      <input
                                        value="Next Step"
                                        data-complete="true"
                                        type="submit"
                                        className="basic-form__button basic-form__button--inline"
                                        onClick={handleSubmit}
                                        ref={(ref) => (basicFormButtons.current[1] = ref)}
                                        id="page-form-button--complete"
                                      />
                                    </div>
                                  )}
                                </>
                              )}

                              {!formChanged && selectedCategories.length >= 1 && (
                                <Grid className="grid-padding-x">
                                  <Cell small={12} className="text-right">
                                    <Link
                                      to={'/conversations/' + data.id + '/confirmations/new'}
                                      className="basic-form__button basic-form__button--inline"
                                      id="page-form-button--complete"
                                    >
                                      Next Step
                                    </Link>
                                  </Cell>
                                </Grid>
                              )}
                            </Cell>
                          </Grid>
                        </div>

                        <Modal
                          isOpen={currentModal === 'category-private-notes'}
                          onAfterOpen={afterOpenModal}
                          onRequestClose={closeModal}
                          contentLabel="category-private-notes"
                          shouldCloseOnOverlayClick={true}
                        >
                          <div className="text-right">
                            <button
                              onClick={closeModal}
                              id={`page-function-button--modal-close-category-private-notes`}
                              className="category-form-modal__close-button"
                            >
                              <XCircle size={48} />
                            </button>
                          </div>
                          <div className="category-form-modal">
                            <h2>Your private notes</h2>

                            <div>
                              <label htmlFor="general_notes">General notes</label>

                              <textarea
                                id="general_notes"
                                name="general_notes"
                                className="basic-form__input category-form-modal__input"
                                placeholder="Your private notes"
                                onChange={handleChange}
                                defaultValue={inputs.general_notes}
                                rows={7}
                              ></textarea>
                            </div>

                            {selectedCategories?.map((questionCategory) => (
                              <div key={'deselected-question-' + questionCategory.id}>
                                <label htmlFor={'notes--' + questionCategory.id}>
                                  {questionResultsContent(
                                    questionCategory.id,
                                    'optionalCategoryName',
                                  )
                                    ? questionResultsContent(
                                      questionCategory.id,
                                      'optionalCategoryName',
                                    )
                                    : questionCategory.name}
                                </label>

                                <textarea
                                  id={'notes--' + questionCategory.id}
                                  name={'notes--' + questionCategory.id}
                                  onChange={handleChange}
                                  placeholder="Add any additional notes here..."
                                  defaultValue={questionResultsContent(
                                    questionCategory.id,
                                    'notes',
                                  )}
                                  className="basic-form__input category-form-modal__input"
                                ></textarea>
                              </div>
                            ))}

                            <div>
                              <button
                                onClick={closeModal}
                                id={`page-function-button--modal-save-and-close-category-private-notes`}
                                className="category-form-modal__button"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </Modal>
                      </>
                    )}

                    {!submissionUnlocked && inputs.general_notes && (
                      <>
                        <h2 className="question-categories-choice__title">Your private notes</h2>

                        <div className="question-categories-choice__styles">
                          {inputs.general_notes}
                        </div>
                      </>
                    )}

                    {submissionUnlocked && (
                      <div className="grid-container">
                        <Grid className="grid-padding-x">
                          <Cell small={12}>
                            {deselectedCategories && (
                              <>
                                <h2
                                  className="page-content__subtitle page-content__subtitle--section"
                                  id="deselected-categories"
                                >
                                  Choose your <b>three</b> conversation topics
                                </h2>
                                <Grid className="grid-padding-x">
                                  {deselectedCategories.map((questionCategory) => {
                                    return (
                                      <Cell
                                        small={12}
                                        large={6}
                                        key={'deselected-question-' + questionCategory.id}
                                      >
                                        <QuestionCategory
                                          afterOpenModal={afterOpenModal}
                                          categoryFormButtons={categoryFormButtons}
                                          closeModal={closeModal}
                                          currentModal={currentModal}
                                          deselectCategory={null}
                                          handleChange={handleChange}
                                          openModal={openModal}
                                          questionCategory={questionCategory}
                                          questionResultsContent={questionResultsContent}
                                          selectCategory={selectCategory}
                                          selectedCategories={selectedCategories}
                                          submissionUnlocked={submissionUnlocked}
                                          showSummary={true}
                                          showNotes={true}
                                        />
                                      </Cell>
                                    );
                                  })}
                                </Grid>
                              </>
                            )}
                          </Cell>
                        </Grid>
                      </div>
                    )}
                  </form>
                </>
              )}
            </Cell>
          </Grid>
        </div>
      )}
    </>
  );
}
