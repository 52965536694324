// Frontend
import { Link } from 'react-router-dom';
import { Grid, Cell } from 'react-foundation';

import futureWorkDesignLogo from '../images/layout/footer/future-work-design-logo--white.png';

import '../stylesheets/layout/footer/Primary.css';
import '../stylesheets/components/navigation/Footer.css';

export function Footer() {
  return (
    <>
      <footer className="primary-footer-wrap">
        <div className="grid-container">
          <Grid className="align-bottom">
            <Cell small={12} large={7}>
              <img
                src={futureWorkDesignLogo}
                loading="lazy"
                width="128"
                height="75"
                alt="Future Work Design"
                className="primary-footer__image"
              />
            </Cell>

            <Cell small={12} large={5}>
              <ul className="footer-primary-microcopy-list">
                <li className="footer-primary-microcopy-list__item">
                  Copyright Future Work Design {new Date().getFullYear()}
                </li>
              </ul>

              <nav className="footer-primary-navigation">
                <ul className="footer-primary-navigation-list">
                  <li className="footer-primary-navigation-list__item">
                    <Link
                      to={process.env.PUBLIC_URL + '/terms-and-conditions.pdf'}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer-primary-navigation-list__link"
                    >
                      Terms and Conditions
                    </Link>
                  </li>

                  <li className="footer-primary-navigation-list__item">
                    <Link
                      to={process.env.PUBLIC_URL + '/privacy-policy.pdf'}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer-primary-navigation-list__link"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </nav>
            </Cell>
          </Grid>
        </div>
      </footer>
    </>
  );
}
