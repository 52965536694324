// Backend
import React from 'react';
import axios from 'axios';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality
import { useParams, useLocation, Link } from 'react-router-dom';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  ProgressBar,
  ErrorRender,
  setToken,
} from '../../../constants/SharedComponents';
import useCurrentUser from '../../../hooks/useCurrentUser';

export function ConversationsFullQuestionsShow() {
  // Params
  const { conversationId } = useParams();

  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();
  const token = setToken(currentUser);
  const headerParams = { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 };

  // Objects
  const [flashMessageObject, setFlashMessageObject] = React.useState({});
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  // Functions
  function retryFetch() {
    setLoading(true);
    setError(false);
    setData(null);
    getData();
  }

  const getData = async () => {
    try {
      let Api = new DluchApi('ConversationsShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.conversation;

      setData(responseData);
      setError(false);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (currentUser && currentUser.token) {
      retryFetch();
    }

    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  return (
    <>
      <ProgressBar loading={loading} />
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />
      <ErrorRender object={error} />

      {data && (
        <>
          <div className="grid-container">
            <Grid className="grid-padding-x align-justify">
              <Cell small={12}>
                <div className="page-content">
                  <h1 className="page-content__title">
                    Thank You for Choosing the Guided Preparation Route
                  </h1>
                  <div className="page-content__styles">
                    <p>
                      You have opted to complete the following questionnaire to help you to identify
                      the main characteristics of your job to discuss with your manager, so you can
                      work together in taking positive steps towards healthier work.
                    </p>

                    <p>
                      The completion of these 35 questions is entirely voluntary and you can decide
                      to stop it at any point. If you decide to stop, your responses to the
                      questions will be not be saved - They will only be saved if you submit them at
                      the end. Should you decide to stop, you will be invited to choose the
                      'self-reflection' route.
                    </p>

                    <p>
                      There are no right or wrong answers, just respond in ways that best describes
                      how you feel.
                    </p>

                    <h2 className="page-content__subtitle">Who will see my data?</h2>

                    <p>
                      All data collected here will be securely stored and completely anonymised. To
                      ensure your anonymity, data that could identify you will not be shared with
                      your manager or employer. Your employer will only have access to anonymised
                      data which cannot be used to reidentify you.{' '}
                      <i>
                        Your anonymised data may be added to a large data set to be used to further
                        develop this tool, or in academic publications to share the learnings from
                        this work with the wider academic community.
                      </i>
                    </p>

                    <h3 className="page-content__subtitle">
                      Why does my employer have access to anonymised data?
                    </h3>
                    <p>
                      All of the respondent's anonymised data will be accessible for the Council you
                      are working for. This information will give valuable feedback to your
                      organisation about the impact of current policies and practices, and may help
                      senior management to make decisions that to support employee health and
                      wellbeing at the organisational level.
                    </p>

                    <h3 className="page-content__subtitle">Where can I find more information?</h3>
                    <p>
                      If you have any questions about data protection, please contact your local
                      Data Protection Officer.
                    </p>
                  </div>

                  <Link
                    to={'/conversations/' + data.id + '/full-questions/new'}
                    className="page-content__button"
                    id="page-navigation-button--start"
                  >
                    Start
                  </Link>
                </div>
              </Cell>
            </Grid>
          </div>
        </>
      )}
    </>
  );
}
