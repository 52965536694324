// Backend
import React from 'react';
import axios from 'axios';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality
import { useParams, useLocation, Link } from 'react-router-dom';

// Components
import {
  Header,
  DluchApi,
  FlashMessage,
  ProgressBar,
  ErrorRender,
  setToken,
} from '../../../../constants/SharedComponents';
import useCurrentUser from '../../../../hooks/useCurrentUser';

export function ConversationsScreeningHomesShow() {
  // Params
  const { conversationId } = useParams();

  // API
  const currentUser = useCurrentUser();
  const { state } = useLocation();
  const token = setToken(currentUser);
  const headerParams = { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 };

  // Objects
  const [flashMessageObject, setFlashMessageObject] = React.useState({});
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  // Functions

  function retryFetch() {
    setLoading(true);
    setError(false);
    setData(null);
    getData();
  }

  const getData = async () => {
    try {
      let Api = new DluchApi('ConversationsShow', conversationId);
      const response = await axios.get(Api.ApiRequestUrl(), headerParams);
      let responseData = response.data.content.conversation;
      setData(responseData);
      setError(false);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  // @see https://css-tricks.com/run-useeffect-only-once/#
  // The second param will ensure the useEffect only runs once.
  // eslint-disable-next-line
  React.useEffect(() => {
    if (currentUser && currentUser.token) {
      retryFetch();
    }

    if (state && state.hasOwnProperty('flash')) {
      setFlashMessageObject(state.flash);
    }
  }, [currentUser, state]);

  return (
    <>
      <ProgressBar loading={loading} />
      <Header />
      <FlashMessage object={flashMessageObject} columnWrap={true} />
      <ErrorRender object={error} />

      {data && (
        <>
          <div className="grid-container">
            <Grid className="grid-padding-x align-justify">
              <Cell small={12}>
                <div className="page-content">
                  <h1 className="page-content__title">Prepare for your meeting</h1>

                  <div className="page-content__styles">
                    <p>
                      To help you to get prepared for the coming conversation with your manager, the
                      following exploration activity is recommended. This activity is designed to
                      help you to identify particular characteristics of your job that you may find
                      difficult or challenging. You can then use this insight to guide your list of
                      key points you would like to discuss with your manager.
                    </p>

                    <p>
                      This preparation is intended to guide you through a process of carefully
                      considering what you want to discuss so that you work together to make a
                      forward plan that addresses the aspects of your work that are particularly
                      important to you.
                    </p>

                    <Link
                      to={`/conversations/${conversationId}/team-member-prep/new`}
                      className="page-content__button"
                      id="page-navigation-button--start"
                    >
                      Next Step
                    </Link>
                  </div>
                </div>
              </Cell>
            </Grid>
          </div>
        </>
      )}
    </>
  );
}
