// Backend
import React from 'react';

// Frontend
import { Grid, Cell } from 'react-foundation';

// Functionality

// Components
import { Header } from '../../constants/SharedComponents';

export function ErrorsNotFound() {
  return (
    <>
      <Header />

      <div className="grid-container">
        <Grid className="grid-padding-x align-justify">
          <Cell small={12}>
            <div className="page-content">
              <h1 className="page-content__title">Page not found</h1>

              <div className="page-content__styles">
                <p>The page you requested cannot be found. Please try again.</p>
              </div>
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  );
}
