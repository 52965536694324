import React from "react";
import { Cell, Grid } from "react-foundation";

import Header from "../shared/Header";
import { Spinner } from 'react-bootstrap';

export function Loading() {

  return (
    <>
      <Header />

      <div className="grid-container">
        <Grid className="grid-padding-x">
          <Cell small={12} style={{ display: "flex", justifyContent: "center",  paddingTop: "17%" }}>
            <Spinner animation="border" role="output" style={{ width: "4rem", height: "4rem", color: "#29a893" }}>
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Cell>
        </Grid>
      </div>
    </>
  );
}
